import React from 'react';
import * as Shapes from 'interfaces/strategyStatsShapes';
import { STRATEGY_STAT_FILTERS, StrategyStatsFilterIDs, StrategyStatsColIds } from 'constants/strategyStatsConstants';
import cn from 'classnames';
import s from '../pages/mmProfilesPage/MMProfilesPage.module.scss';
import { ACCOUNT_STAT_FILTERS, AccountStatsFilterIDs } from '../constants/accountStatsConstants';

export const createSignalsStatsRow = ({
  id,
  profile_title,
  direction,
  total_profit,
  total_loss,
  total,
  total_percent,
  total_i,
  avg_pnl_percent,
  avg_loss_percent,
  most_loss_percent,
  avg_profit_percent,
  avg_i,
  qty_loss,
  qty_profit,
  qty_total,
  w_percent,
  avg_time_per_signal,
  most_longest_duration,
  most_imp_loss,
  most_mm_imp_loss,
  qty_missed,
  status,
  profile_id
}: Shapes.StrategyStatsShape): Shapes.StrategyStatsTableRow => {
  const round = (num) => Math.round(num * 100) / 100;
  return {
    id,
    profile_title,
    direction,
    total_profit,
    total_loss,
    total,
    total_percent,
    total_i,
    avg_pnl_percent,
    avg_loss_percent,
    most_loss_percent,
    avg_profit_percent,
    avg_i,
    qty_loss,
    qty_profit,
    qty_total,
    w_percent,
    avg_time_per_signal: round(avg_time_per_signal),
    most_longest_duration,
    most_imp_loss: round(most_imp_loss),
    most_mm_imp_loss: round(most_mm_imp_loss),
    qty_missed,
    status,
    profile_id: profile_id ?? null
  };
};

export const signalsStatsRenderSwitch = (
  colId: StrategyStatsColIds,
  row: Shapes.StrategyStatsShape,
  isLimited: boolean
): JSX.Element => {
  const value = row[colId];
  switch (colId) {
    case StrategyStatsColIds.PROFILE:
      if (isLimited && row[StrategyStatsColIds.PROFILE_ID]) {
        return <>{row[StrategyStatsColIds.PROFILE_ID] ?? '-'}</>;
      }
      return <>{value ?? '-'}</>;

    case StrategyStatsColIds.STATUS:
      return (
        <span
          className={cn(s.statusInactive, {
            [s.statusActive]: row.status === Shapes.StrategyStatsStatus.ACTIVE,
            [s.statusStopping]: row.status === Shapes.StrategyStatsStatus.STOPPING,
            [s.statusDraft]: row.status === Shapes.StrategyStatsStatus.DRAFT
          })}
        >
          {value ?? '-'}
        </span>
      );
    default:
      return <>{value ?? '-'}</>;
  }
};

export const customizeFilter = (
  filters: Shapes.Profiles[],
  customFilter: typeof STRATEGY_STAT_FILTERS | typeof ACCOUNT_STAT_FILTERS
): typeof STRATEGY_STAT_FILTERS => {
  return customFilter.map((filt) => {
    if (filt.id === StrategyStatsFilterIDs.PROFILE || filt.id === AccountStatsFilterIDs.PROFILE) {
      const profileFiltersOptions = filters?.map((elem: Shapes.Profiles) => ({
        value: String(elem?.id),
        label: String(elem?.title)
      }));
      return { ...filt, options: [...filt.options, ...profileFiltersOptions] };
    }
    return filt;
  });
};
