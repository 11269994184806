import { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from 'constants/apiEndpoints';
import * as Shapes from 'interfaces/notificationShapes';
import { AxiosInstance as axios } from '../../../helpers/authHelpers';

export const getNotificationsHelper = (params: Shapes.GetNotificationsReq): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.GET_NOTIFICATIONS, { params });
};

export const removeNotificationHelper = (id: number): Promise<AxiosResponse> => {
  return axios.delete(API_ENDPOINTS.REMOVE_NOTIFICATION(id));
};
