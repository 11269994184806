import LoginPage from 'pages/login/LoginPage';
import StrategyProfileDetailsPage from 'pages/strategyProfilesDetails/StrategyProfileDetailsPage';
import StrategyProfilesAddPage from 'pages/strategyProfilesAdd/StrategyProfilesAddPage';
import MMProfilesPage from 'pages/mmProfilesPage/MMProfilesPage';
import MMProfilesManagePage from 'pages/mmProfilesAddPage/MMProfilesManagePage';
import SignalsOrdersPage from 'pages/signalsOrders/SignalsOrdersPage';
import SignalsPage from 'pages/signalsOrders/signalsPage/SignalsPage';
import OrdersPage from 'pages/signalsOrders/ordersPage/OrdersPage';
import StrategyStatsPage from 'pages/stats/strategyStats/StrategyStatsPage';
import NotificationsPage from 'pages/notificationsPage/NotificationsPage';
import LogsPage from '../pages/logs/LogsPage';
import SystemSettingsPage from '../pages/systemSettings/SystemSettingsPage';
import AccountSettingsPage from 'pages/accountSettings/AccountSettingsPage';

import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import WebIcon from '@material-ui/icons/Web';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import Access from '@material-ui/icons/Accessibility';
import TableChartIcon from '@material-ui/icons/TableChart';
import PrivilegesPage from '../pages/privileges/privilegesPage/PrivilegesPage';
import { PrivilegesAdd } from '../pages/privilegesAddPage/PrivilegesAdd';
import { PrivilegesGroupFields } from '../interfaces/privilegesShapes';
import Administration from '../pages/signalsOrders/administrationPage/Administration';
import RiskControlPage from '../pages/signalsOrders/riskControlPage/RiskControlPage';
import Privileges from '../pages/privileges/Privileges';
import UserManagementPage from '../pages/privileges/userManagementPage/UserManagementPage';
import StatsPage from '../pages/stats/StatsPage';
import AccountStatsPage from '../pages/stats/accountStats/AccountStatsPage';
import MonthsStatsPage from '../pages/stats/monthsStats/MonthsStatsPage';
import StrategyPage from '../pages/strategyProfiles/StrategyPage';
import StrategyProfilesPage from '../pages/strategyProfiles/StrategyProfilesPage/StrategyProfilesPage';
import StrategyTickers from '../pages/strategyProfiles/StrategyTickers/StrategyTickers';
import StaticsStatsPage from '../pages/stats/staticsStats/StaticsStats';

export const PAGES = {
  DEFAULT: '/',
  LOGIN: '/login',
  STRATEGY_PROFILES: '/strategy-profiles',
  STRATEGY_PROFILES_STRATEGY: '/strategy-profiles/strategy',
  STRATEGY_PROFILES_TICKERS: '/strategy-profiles/tickers',
  STRATEGY_PROFILE: '/strategy-profiles/profile=:id',
  STRATEGY_PROFILES_ADD: '/strategy-profiles/add-strategy-profile',
  MM_PROFILES: '/mm-profiles',
  MM_PROFILES_ADD: '/mm-profiles/add-mm-profile',
  MM_PROFILE: '/mm-profiles/profile=:id',
  STATS: '/stats',
  STATS_STRATEGY_STATS: '/stats/strategy-stats',
  STATS_ACCOUNT_STATS: '/stats/account-stats',
  STATS_MONTHS_STATS: '/stats/months-stats',
  STATS_CHARTS_STATS: '/stats/charts-stats',
  SIGNALS_ORDERS: '/signals-and-orders',
  SIGNALS_ORDERS_SIGNALS: '/signals-and-orders/signals',
  SIGNALS_ORDERS_ORDERS: '/signals-and-orders/orders',
  SIGNALS_ORDERS_ADMINISTRATION: '/signals-and-orders/administration',
  SIGNALS_ORDERS_RISK_CONTROL: '/signals-and-orders/risk-control-service',
  NOTIFICATIONS: '/notifications',
  LOGS: '/logs',
  SYSTEM_SETTINGS: '/system-settings',
  ACCOUNT_DETAILS: '/account-details',
  PRIVILEGES: '/privileges',
  PRIVILEGES_GROUPS: '/privileges/groups',
  PRIVILEGES_USER_MANAGEMENT: '/privileges/user-management',
  PRIVILEGES_ADD: '/privileges-add',
  NO_MATCH: '*'
};

export const DYNAMIC_PAGES = {
  STRATEGY_PROFILE: (id: number): string => `/strategy-profiles/profile=${id}`,
  MM_PROFILE: (id: number): string => `/mm-profiles/profile=${id}`
};

export const ROUTE_OPTIONS = [
  {
    path: PAGES.LOGIN,
    component: LoginPage,
    exact: true,
    isPrivate: false,
    redirectTo: null,
    privilegeId: null,
    hide: false
  },
  {
    path: PAGES.STRATEGY_PROFILES,
    component: StrategyPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: `${PrivilegesGroupFields.PROFILES}${PrivilegesGroupFields.TICKERS_CONFIGS}`,
    hide: false
  },
  {
    path: PAGES.STRATEGY_PROFILES_STRATEGY,
    component: StrategyProfilesPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.PROFILES,
    hide: false
  },
  {
    path: PAGES.STRATEGY_PROFILES_TICKERS,
    component: StrategyTickers,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.TICKERS_CONFIGS,
    hide: false
  },
  {
    path: PAGES.STRATEGY_PROFILE,
    component: StrategyProfileDetailsPage,
    exact: false,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.PROFILE_SETTINGS,
    hide: false
  },
  {
    path: PAGES.STRATEGY_PROFILES_ADD,
    component: StrategyProfilesAddPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.PROFILES,
    hide: false
  },
  {
    path: PAGES.MM_PROFILES,
    component: MMProfilesPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.MM_PROFILES,
    hide: false
  },
  {
    path: PAGES.MM_PROFILES_ADD,
    component: MMProfilesManagePage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.MM_PROFILES,
    hide: false
  },
  {
    path: PAGES.MM_PROFILE,
    component: MMProfilesManagePage,
    exact: false,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.MM_PROFILES,
    hide: false
  },
  {
    path: PAGES.SIGNALS_ORDERS,
    component: SignalsOrdersPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: `${PrivilegesGroupFields.SIGNALS}${PrivilegesGroupFields.ORDERS}${PrivilegesGroupFields.APA}${PrivilegesGroupFields.RISK_CONTROL}`,
    hide: false
  },
  {
    path: PAGES.SIGNALS_ORDERS_SIGNALS,
    component: SignalsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.SIGNALS,
    hide: false
  },
  {
    path: PAGES.SIGNALS_ORDERS_ORDERS,
    component: OrdersPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.ORDERS,
    hide: false
  },
  {
    path: PAGES.STATS,
    component: StatsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: `${PrivilegesGroupFields.PROFILES_STATS}${PrivilegesGroupFields.ACCOUNT_STATS}${PrivilegesGroupFields.STATS_BY_MONTHS}${PrivilegesGroupFields.CHARTS}`,
    hide: false
  },
  {
    path: PAGES.STATS_STRATEGY_STATS,
    component: StrategyStatsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.PROFILES_STATS,
    hide: false
  },
  {
    path: PAGES.STATS_ACCOUNT_STATS,
    component: AccountStatsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.ACCOUNT_STATS,
    hide: false
  },
  {
    path: PAGES.STATS_MONTHS_STATS,
    component: MonthsStatsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.STATS_BY_MONTHS,
    hide: false
  },
  {
    path: PAGES.STATS_CHARTS_STATS,
    component: StaticsStatsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.CHARTS,
    hide: false
  },
  {
    path: PAGES.NOTIFICATIONS,
    component: NotificationsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.NOTIFICATIONS,
    hide: false
  },
  {
    path: PAGES.LOGS,
    component: LogsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.LOGS,
    hide: false
  },
  {
    path: PAGES.SYSTEM_SETTINGS,
    component: SystemSettingsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: `${PrivilegesGroupFields.BINANCE_ACCOUNT}${PrivilegesGroupFields.PAIRS}${PrivilegesGroupFields.TG_BOT}`,
    hide: false
  },
  {
    path: PAGES.ACCOUNT_DETAILS,
    component: AccountSettingsPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: null,
    hide: false
  },
  {
    path: PAGES.PRIVILEGES,
    component: Privileges,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: `${PrivilegesGroupFields.GROUPS}${PrivilegesGroupFields.USERS}`,
    hide: false
  },
  {
    path: PAGES.PRIVILEGES_GROUPS,
    component: PrivilegesPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.GROUPS,
    hide: false
  },
  {
    path: PAGES.PRIVILEGES_USER_MANAGEMENT,
    component: UserManagementPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.USERS,
    hide: false
  },
  {
    path: PAGES.PRIVILEGES_ADD,
    component: PrivilegesAdd,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.GROUPS,
    hide: false
  },
  {
    path: PAGES.SIGNALS_ORDERS_ADMINISTRATION,
    component: Administration,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.APA,
    hide: false
  },
  {
    path: PAGES.SIGNALS_ORDERS_RISK_CONTROL,
    component: RiskControlPage,
    exact: true,
    isPrivate: true,
    redirectTo: PAGES.LOGIN,
    privilegeId: PrivilegesGroupFields.RISK_CONTROL,
    hide: false
  }
];

export const ASIDE_LINKS = [
  {
    id: 'strategy_profiles',
    label: 'Strategy profiles',
    path: PAGES.STRATEGY_PROFILES,
    exact: false,
    IconComponent: DesktopMacIcon,
    privilegeId: `${PrivilegesGroupFields.PROFILES}${PrivilegesGroupFields.TICKERS_CONFIGS}`,
    hide: false
  },
  {
    id: 'mm_profiles',
    label: 'MM profiles',
    path: PAGES.MM_PROFILES,
    exact: false,
    IconComponent: CreditCardIcon,
    privilegeId: PrivilegesGroupFields.MM_PROFILES,
    hide: false
  },
  {
    id: 'signals_orders',
    label: 'Signals & orders',
    path: PAGES.SIGNALS_ORDERS,
    exact: false,
    IconComponent: WebIcon,
    privilegeId: `${PrivilegesGroupFields.SIGNALS}${PrivilegesGroupFields.ORDERS}${PrivilegesGroupFields.APA}${PrivilegesGroupFields.RISK_CONTROL}`,
    hide: false
  },
  {
    id: 'stats',
    label: 'Strategy stats',
    path: PAGES.STATS,
    exact: true,
    IconComponent: TableChartIcon,
    privilegeId: `${PrivilegesGroupFields.PROFILES_STATS}${PrivilegesGroupFields.ACCOUNT_STATS}${PrivilegesGroupFields.STATS_BY_MONTHS}${PrivilegesGroupFields.CHARTS}`,
    hide: false
  },
  {
    id: 'notifications',
    label: 'Notifications',
    path: PAGES.NOTIFICATIONS,
    exact: true,
    IconComponent: NotificationsIcon,
    privilegeId: PrivilegesGroupFields.NOTIFICATIONS,
    hide: false
  },
  {
    id: 'logs',
    label: 'Logs',
    path: PAGES.LOGS,
    exact: true,
    IconComponent: SpeakerNotesIcon,
    privilegeId: PrivilegesGroupFields.LOGS,
    hide: false
  },
  {
    id: 'system_settings',
    label: 'System Settings',
    path: PAGES.SYSTEM_SETTINGS,
    exact: true,
    IconComponent: SettingsIcon,
    privilegeId: `${PrivilegesGroupFields.BINANCE_ACCOUNT}${PrivilegesGroupFields.PAIRS}${PrivilegesGroupFields.TG_BOT}`,
    hide: false
  },
  {
    id: 'account_settings',
    label: 'Account Settings',
    path: PAGES.ACCOUNT_DETAILS,
    exact: true,
    IconComponent: PersonIcon,
    privilegeId: null,
    hide: false
  },
  {
    id: 'privileges',
    label: 'Privileges',
    path: PAGES.PRIVILEGES,
    exact: true,
    IconComponent: Access,
    privilegeId: `${PrivilegesGroupFields.GROUPS}${PrivilegesGroupFields.USERS}`,
    hide: false
  }
];

export const SIGNALS_ORDERS_TABS = [
  {
    label: 'Signals',
    linkTo: PAGES.SIGNALS_ORDERS_SIGNALS,
    privilegeId: PrivilegesGroupFields.SIGNALS
  },
  {
    label: 'Orders',
    linkTo: PAGES.SIGNALS_ORDERS_ORDERS,
    privilegeId: PrivilegesGroupFields.ORDERS
  },
  {
    label: 'Administration',
    linkTo: PAGES.SIGNALS_ORDERS_ADMINISTRATION,
    privilegeId: PrivilegesGroupFields.APA
  },
  {
    label: 'RCS',
    linkTo: PAGES.SIGNALS_ORDERS_RISK_CONTROL,
    privilegeId: PrivilegesGroupFields.RISK_CONTROL
  }
];

export const PRIVILEGES_TABS = [
  {
    label: 'Privileges',
    linkTo: PAGES.PRIVILEGES_GROUPS,
    privilegeId: PrivilegesGroupFields.GROUPS
  },
  {
    label: 'Users',
    linkTo: PAGES.PRIVILEGES_USER_MANAGEMENT,
    privilegeId: PrivilegesGroupFields.USERS
  }
];

export const STATS_TABS = [
  {
    label: 'Strategy',
    linkTo: PAGES.STATS_STRATEGY_STATS,
    privilegeId: PrivilegesGroupFields.PROFILES_STATS
  },
  {
    label: 'Account',
    linkTo: PAGES.STATS_ACCOUNT_STATS,
    privilegeId: PrivilegesGroupFields.ACCOUNT_STATS
  },
  {
    label: 'By Months',
    linkTo: PAGES.STATS_MONTHS_STATS,
    privilegeId: PrivilegesGroupFields.STATS_BY_MONTHS
  },
  {
    label: 'Charts',
    linkTo: PAGES.STATS_CHARTS_STATS,
    privilegeId: PrivilegesGroupFields.CHARTS
  }
];

export const STRATEGY_TABS = [
  {
    label: 'Strategy',
    linkTo: PAGES.STRATEGY_PROFILES_STRATEGY,
    privilegeId: PrivilegesGroupFields.PROFILES
  },
  {
    label: 'Ticker',
    linkTo: PAGES.STRATEGY_PROFILES_TICKERS,
    privilegeId: PrivilegesGroupFields.TICKERS_CONFIGS
  }
];
