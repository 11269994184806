import { strategyProfilesTypes } from '../types';
import * as Shapes from 'interfaces/strategyProfilesShapes';

export const createProfile = (
  reqData: Shapes.CreateProfileReqData,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.CreateProfile => ({
  type: strategyProfilesTypes.CREATE_PROFILE,
  payload: { reqData, onSuccess, onError }
});

export const updateProfile = (
  id: number,
  reqData: Shapes.CreateProfileReqData,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.UpdateProfile => ({
  type: strategyProfilesTypes.UPDATE_PROFILE,
  payload: { id, reqData, onSuccess, onError }
});

export const setProfile = (profile: Shapes.StrategyProfileShape): Shapes.SetProfile => ({
  type: strategyProfilesTypes.SET_PROFILE,
  payload: profile
});

export const setProfileTemplate = (profile: Shapes.StrategyProfileShape): Shapes.SetProfile => ({
  type: strategyProfilesTypes.SET_PROFILE_TEMPLATE,
  payload: profile
});

export const getSystemStatus = (onSuccess: () => void, onError: (err) => void): Shapes.GetSystemStatus => ({
  type: strategyProfilesTypes.GET_SYSTEM_STATUS,
  payload: { onSuccess, onError }
});

export const setSystemStatus = (
  status: string,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.SetSystemStatus => ({
  type: strategyProfilesTypes.SET_SYSTEM_STATUS,
  payload: { status, onSuccess, onError }
});

export const putSystemStatus = (status: Shapes.SystemStatusShape): Shapes.PutSystemStatus => ({
  type: strategyProfilesTypes.PUT_SYSTEM_STATUS,
  payload: status
});

export const updateStrategySettings = (
  id: number,
  profileData: Partial<Shapes.UpdateStrategyData>,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.UpdateStrategyDetails => ({
  type: strategyProfilesTypes.UPDATE_STRATEGY_DETAILS,
  payload: { id, profileData, onSuccess, onError }
});

export const setStrategySettings = (settings: Shapes.UpdateStrategyResponse): Shapes.SetStrategySettings => ({
  type: strategyProfilesTypes.SET_STRATEGY_SETTINGS,
  payload: settings
});

export const getStrategyProfiles = (
  reqParams: Shapes.GetStrategyProfilesReq,
  onFinally: () => void,
  onError: (err) => void
): Shapes.GetStrategyProfiles => ({
  type: strategyProfilesTypes.GET_STRATEGY_PROFILES,
  payload: { reqParams, onFinally, onError }
});

export const putStrategyProfiles = (profilesData: Shapes.StrategyProfilesData): Shapes.PutStrategyProfiles => ({
  type: strategyProfilesTypes.PUT_STRATEGY_PROFILES,
  payload: profilesData
});

export const startStrategy = (id: number, onError: (err) => void): Shapes.StartStopStrategy => ({
  type: strategyProfilesTypes.START_STRATEGY,
  payload: { id, onError }
});

export const stopStrategy = (id: number, onError: (err) => void): Shapes.StartStopStrategy => ({
  type: strategyProfilesTypes.STOP_STRATEGY,
  payload: { id, onError }
});

export const putUpdatedStrategyInList = (data: Shapes.StrategyProfileShape): Shapes.PutUpdatedStrategyInList => ({
  type: strategyProfilesTypes.PUT_UPDATED_IN_LIST,
  payload: data
});

export const getProfile = (
  id: number,
  isTemplateRequest: boolean,
  onError: (err) => void,
  onSuccess: (data: Shapes.StrategyProfileShape) => void
): Shapes.GetProfile => ({
  type: strategyProfilesTypes.GET_PROFILE,
  payload: { id, onSuccess, onError, isTemplateRequest }
});

export const getStrategySettings = (id: number, onError: (err) => void): Shapes.GetStrategySettings => ({
  type: strategyProfilesTypes.GET_STRATEGY_SETTINGS,
  payload: { id, onError }
});

export const clearStrategyProfile = (): { type: string } => ({
  type: strategyProfilesTypes.CLEAR_STRATEGY_PROFILE
});

export const updateStrategyVisibility = (
  strategyId: number,
  is_show: boolean,
  onSuccess: () => void
): Shapes.UpdateStrategyVisibility => ({
  type: strategyProfilesTypes.UPDATE_STRATEGY_VISIBILITY,
  payload: { strategyId, reqData: { is_show }, onSuccess }
});

export const getStrategiesTickers = (onSuccess?: () => void, onError?: (err) => void): Shapes.GetStrategiesTickers => ({
  type: strategyProfilesTypes.GET_STRATEGY_TICKERS,
  payload: { onSuccess, onError }
});

export const setStrategiesTickers = (payload: Shapes.PairsShape[]): Shapes.SetStrategiesTickers => ({
  type: strategyProfilesTypes.SET_STRATEGY_TICKERS,
  payload
});

export const updateStrategiesTickers = (id: number, pairs: number[]): Shapes.UpdateStrategiesTickers => ({
  type: strategyProfilesTypes.UPDATE_STRATEGY_TICKERS,
  payload: { id, pairs }
});

export const saveStrategiesTickers = (id: number, onSuccess: () => void): Shapes.SaveRemoveStrategyTickers => ({
  type: strategyProfilesTypes.SAVE_STRATEGY_TICKERS,
  payload: { id, onSuccess }
});

export const deleteStrategiesTickers = (id: number, onSuccess: () => void): Shapes.SaveRemoveStrategyTickers => ({
  type: strategyProfilesTypes.DELETE_STRATEGY_TICKERS,
  payload: { id, onSuccess }
});

export const deleteStrategiesTickersSuccess = (id: number): Shapes.RemoveStrategyTickersSuccess => ({
  type: strategyProfilesTypes.DELETE_STRATEGY_TICKERS_SUCCESS,
  payload: { id }
});

export const addStrategiesTickers = (
  values: Shapes.PairFormValues,
  onSuccess: () => void
): Shapes.AddRemoveStrategyTickers => ({
  type: strategyProfilesTypes.ADD_STRATEGY_TICKERS,
  payload: { values, onSuccess }
});

export const addStrategiesTickersSuccess = (pair: Shapes.PairsShape): Shapes.AddRemoveStrategyTickersSuccess => ({
  type: strategyProfilesTypes.ADD_STRATEGY_TICKERS_SUCCESS,
  payload: pair
});

export const updateStatusStrategiesTickers = (
  id: number,
  status: Shapes.PairsStatusShape
): Shapes.UpdateStatusStrategiesTickers => ({
  type: strategyProfilesTypes.UPDATE_STATUS_STRATEGY_TICKERS_SUCCESS,
  payload: { id, status }
});

export const getProfileTemplates = (): { type: string } => ({
  type: strategyProfilesTypes.GET_PROFILE_TEMPLATES
});

export const setProfileTemplates = (payload: Shapes.StrategyProfilesShape[]): Shapes.SetStrategyProfilesShape => ({
  type: strategyProfilesTypes.SET_PROFILE_TEMPLATES,
  payload
});
