import React, { useState } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import cn from 'classnames';
import TextField from '@material-ui/core/TextField';
import PairedBtnBlock from 'components/buttons/pairedBtnBlock/PairedBtnBlock';
import { getErrorCutMessageHelper, getErrorMessageHelper } from 'helpers/randomHelpers';
import { BinanceAccountFormFields, BinanceAccountShape } from 'interfaces/systemSettingsShapes';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import {
  BinanceAccFieldsNames,
  BINANCE_ACC_FIELDS,
  binanceAccValidationEdit,
  binanceAccValidationAdd
} from 'constants/systemSettingsConstants';
import s from './BinanceAccountForm.module.scss';
import * as Yup from 'yup';

interface BinanceAccountFormProps {
  onCancel: () => void;
  onSubmit: (values: BinanceAccountFormFields, onError: (err) => void, accountId?: number) => void;
  isEdit: boolean;
  account: null | BinanceAccountShape;
}

const BinanceAccountForm = ({ onCancel, onSubmit, isEdit, account }: BinanceAccountFormProps): JSX.Element => {
  const [isReqErr, setReqErr] = useState<string | null>(null);
  const onClearRequestError = () => setReqErr(null);

  const binanceValidationSchema = isEdit
    ? binanceAccValidationEdit
    : { ...binanceAccValidationEdit, ...binanceAccValidationAdd };

  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const [isFieldsEdited, setFieldsEdited] = useState<boolean>(false);

  const handleEditingFields = (values, e) => {
    let isEdited = false;
    for (let i = 0; i < BINANCE_ACC_FIELDS.length; i++) {
      const id = BINANCE_ACC_FIELDS[i]['id'];
      const initialValue = id === SECRET_KEY ? '' : account[id];
      const currentValue = id === e.target.id ? e.target.value : values[id];
      if (currentValue !== initialValue) {
        isEdited = true;
        break;
      }
    }
    setFieldsEdited(isEdited);
  };

  const isEditable = account && isEdit;
  const { SECRET_KEY, API_KEY, TITLE, LEVERAGE } = BinanceAccFieldsNames;
  return (
    <div className={s.binanceAccFormWrapper}>
      <div className={s.titleWrapper}>
        <h3 className={cn(s.modalTitle, 'default-title')}>{isEdit ? 'Edit' : 'Create'}</h3>
        {isReqErr && <span className={cn(s.reqError, 'errorText')}>{isReqErr}</span>}
      </div>
      <Formik
        initialValues={{
          [TITLE]: isEditable ? account?.title : '',
          [API_KEY]: isEditable ? account?.api_key : '',
          [SECRET_KEY]: '',
          [LEVERAGE]: isEditable ? account?.leverage : 0
        }}
        validationSchema={Yup.object(binanceValidationSchema)}
        onSubmit={async (
          values: BinanceAccountFormFields,
          { setSubmitting }: FormikHelpers<BinanceAccountFormFields>
        ) => {
          const reqValues = {
            title: values.title,
            api_key: values.api_key,
            leverage: values.leverage,
            ...(values.api_secret && { api_secret: values.api_secret })
          };
          onSubmit(reqValues, onSetError, account?.id);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => {
          return (
            <Form className={s.binanceAccForm}>
              {BINANCE_ACC_FIELDS.map(({ id, label, placeholder }) => {
                const isError = (!!errors[id] && touched[id]) || !!isReqErr;
                const isValid = !!touched[id] && !errors[id] && !isReqErr;
                const value = values[id];

                return (
                  <TextField
                    autoComplete="off"
                    key={id}
                    fullWidth
                    id={id}
                    name={id}
                    label={label}
                    placeholder={placeholder}
                    variant="outlined"
                    value={value}
                    error={isError}
                    helperText={touched[id] && errors[id]}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      if (isEditable) {
                        handleEditingFields(values, e);
                      }
                    }}
                    onClick={onClearRequestError}
                    classes={{
                      root: cn(s.input, { [s.inputValid]: isValid })
                    }}
                  />
                );
              })}

              <PairedBtnBlock
                onCancel={onCancel}
                onSubmit={handleSubmit}
                labels={{ cancelLabel: 'Cancel', submitLabel: 'Save' }}
                disableSubmit={isEditable && !isFieldsEdited}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BinanceAccountForm;
