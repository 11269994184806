import React, { FC } from 'react';
import s from './Pieces.module.scss';

interface PiecesProps {
  data: { [key: string]: number };
}

const KeyMap = {
  active_p_size_usd: 'Total p-size $',
  u_pnl: 'Total U-PnL$'
};

const Pieces: FC<PiecesProps> = ({ data }) => {
  const keys = Object.keys(data);
  return (
    <div className={s.addedInfo}>
      {keys.map((key) => (
        <div className={s.dataItem} key={key}>
          <span>
            {KeyMap[key]}: {parseFloat(String(data[key])).toFixed(2)}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Pieces;
