import { statsTypes } from '../types';
import * as Shapes from 'interfaces/strategyStatsShapes';
import * as AcShapes from '../../interfaces/accountStatsShapes';
import * as MoShapes from '../../interfaces/monthsResultsShapes';
import { ChartDTO } from '../../helpers/chartsHelpers';

export const getStrategyStats = (
  queryObj: Shapes.GetStrategyStatsQuery,
  onFinally: () => void,
  onError: (err) => void
): Shapes.GetStrategyStatsShape => ({
  type: statsTypes.GET_STRATEGY_STATS,
  payload: { queryObj, onFinally, onError }
});

export const getStrategyStatsFilters = (): { type: string } => ({
  type: statsTypes.GET_STRATEGY_STATS_FILTERS
});

export const getStrategyStatsExport = (
  queryObj: Shapes.GetStrategyStatsQuery,
  onFinally: () => void
): Shapes.GetProfileStatsExportShape => ({
  type: statsTypes.GET_EXPORT_STRATEGY_STATS,
  payload: { queryObj, onFinally }
});

export const putStrategyStats = (signals: Array<Shapes.StrategyStatsShape>): Shapes.PutStrategyStatsShape => ({
  type: statsTypes.PUT_STRATEGY_STATS,
  payload: signals
});

export const putStrategyStatsFilters = (filters: Array<Shapes.StrategyStatsShape>): Shapes.PutStrategyStatsShape => ({
  type: statsTypes.PUT_STRATEGY_STATS_FILTERS,
  payload: filters
});

export const getAccountStats = (
  queryObj: Shapes.GetStrategyStatsQuery,
  onFinally: () => void,
  onError: (error) => void
): AcShapes.GetAccountStatsShape => ({
  type: statsTypes.GET_ACCOUNT_STATS,
  payload: { queryObj, onFinally, onError }
});

export const putAccountStats = (
  payload: AcShapes.AccountStatsShapes[]
): { type: string; payload: AcShapes.AccountStatsShapes[] } => ({
  type: statsTypes.PUT_ACCOUNT_STATS,
  payload
});

export const getAccountStatsFilters = (): { type: string } => ({
  type: statsTypes.GET_ACCOUNT_STATS_FILTERS
});

export const putAccountStatsFilters = (filters: Array<Shapes.StrategyStatsShape>): Shapes.PutStrategyStatsShape => ({
  type: statsTypes.PUT_ACCOUNT_STATS_FILTERS,
  payload: filters
});

export const getAccountStatsExport = (
  filters: AcShapes.GetAccountStatsQuery,
  onFinally: () => void
): AcShapes.GetAccountStatsExportShape => ({
  type: statsTypes.GET_ACCOUNT_STATS_EXPORT,
  payload: { queryObj: filters, onFinally }
});

export const getResultByMonthsStats = (
  onFinally: () => void,
  onError: (error) => void
): MoShapes.GetResultStatsByMonthsShape => ({
  type: statsTypes.GET_RESULT_BY_MONTHS,
  payload: { onFinally, onError }
});

export const putResultByMonthsStats = (payload: MoShapes.ResultByMonths): MoShapes.PutResultStatsByMonthsShape => ({
  type: statsTypes.PUT_RESULT_BY_MONTHS,
  payload
});

export const getStatsGraph = (chartData: ChartDTO): { type: string; payload: ChartDTO } => ({
  type: statsTypes.GET_STATS_GRAPH,
  payload: chartData
});

export const putStatsGraph = (payload: Shapes.ChartStats[]): { type: string; payload: Shapes.ChartStats[] } => ({
  type: statsTypes.PUT_STATS_GRAPH,
  payload
});

export const clearStatsCharts = (): { type: string } => ({
  type: statsTypes.CLEAN_CHARTS_DATA
});
