import * as Constants from 'constants/strategyStatsConstants';
import { AccountStatsShapes } from './accountStatsShapes';
import { ResultByMonths } from './monthsResultsShapes';

const { StrategyStatsColIds } = Constants;

export interface GetStrategyStatsQuery {
  profile_id__in?: string;
  created_at__lte?: string;
  created_at__gte?: string;
}

export interface StrategyStatsShape {
  profile_id: number;
  id: number;
  profile_title: string;
  direction: string;
  total_profit: number;
  total_loss: number;
  total: number;
  total_percent: number;
  total_i: number;
  avg_pnl_percent: number;
  avg_loss_percent: number;
  most_loss_percent: number;
  avg_profit_percent: number;
  avg_i: number;
  qty_loss: number;
  qty_profit: number;
  qty_total: number;
  w_percent: number;
  avg_time_per_signal: number;
  most_longest_duration: number;
  most_imp_loss: number;
  most_mm_imp_loss: number;
  qty_missed: number;
  status: string;
}

export type GetStrategyStatsResponse = Array<StrategyStatsShape>;

export interface GetStrategyStatsShape {
  type: string;
  payload: { queryObj: GetStrategyStatsQuery; onFinally: () => void; onError: (err) => void };
}

export interface GetProfileStatsExportShape {
  type: string;
  payload: { queryObj: GetStrategyStatsQuery; onFinally: () => void };
}

export interface Profiles {
  id: number;
  title: string;
}

export interface GetStrategyStatsFilters {
  profiles: Profiles[];
}

export interface ChartStats {
  data: number[];
  date: string;
  type: string;
}

export interface StatsReducer {
  accountStatsData: AccountStatsShapes[];
  strategyStatsData: GetStrategyStatsResponse | null;
  filters: GetStrategyStatsFilters | null;
  resultByMonths: ResultByMonths;
  charts: { [chart: string]: ChartStats[] };
}

export interface StrategyStatsTableRow {
  [StrategyStatsColIds.ID]: number;
  [StrategyStatsColIds.PROFILE]: string;
  [StrategyStatsColIds.PROFILE_ID]: number;
  [StrategyStatsColIds.DIRECTION]: string;
  [StrategyStatsColIds.TOTAL_PROFIT]: number;
  [StrategyStatsColIds.TOTAL_LOSS]: number;
  [StrategyStatsColIds.TOTAL]: number;
  [StrategyStatsColIds.TOTAL_PERCENT]: number;
  [StrategyStatsColIds.TOTAL_I]: number;
  [StrategyStatsColIds.AVG_PNL_PERCENT]: number;
  [StrategyStatsColIds.AVG_LOSS_PERCENT]: number;
  [StrategyStatsColIds.MOST_LOSS_PERCENT]: number;
  [StrategyStatsColIds.AVG_PROFIT_PERCENT]: number;
  [StrategyStatsColIds.AVG_I]: number;
  [StrategyStatsColIds.QTY_LOSS]: number;
  [StrategyStatsColIds.QTY_PROFIT]: number;
  [StrategyStatsColIds.QTY_TOTAL]: number;
  [StrategyStatsColIds.W_PERCENT]: number;
  [StrategyStatsColIds.AVG_TIME_PER_SIGNAL]: number;
  [StrategyStatsColIds.MOST_LONGEST_DURATION]: number;
  [StrategyStatsColIds.MOST_IMP_LOSS]: number;
  [StrategyStatsColIds.MOST_MM_IMP_LOSS]: number;
  [StrategyStatsColIds.QTY_MISSED]: number;
  [StrategyStatsColIds.STATUS]: string;
}

export interface PutStrategyStatsShape {
  type: string;
  payload: Array<StrategyStatsShape>;
}

export enum StrategyStatsStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DRAFT = 'Draft',
  STOPPING = 'Stopping'
}
