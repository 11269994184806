import { systemSettingsTypes } from '../types';
import { AnyAction } from 'redux';
import { SystemSettingsReducer } from 'interfaces/systemSettingsShapes';

const initialState = {
  binanceAccounts: [],
  tradingPairs: [],
  tgSettings: {
    id: null,
    token: '',
    chat_id: ''
  }
};

export default function systemSettingsReducer(
  state = initialState,
  { type, payload }: AnyAction
): SystemSettingsReducer {
  switch (type) {
    case systemSettingsTypes.PUT_BINANCE_ACCOUNTS:
      return { ...state, binanceAccounts: payload };

    case systemSettingsTypes.PUT_TRADING_PAIRS:
      return { ...state, tradingPairs: payload };

    case systemSettingsTypes.CHANGE_TRADING_PAIR_SUCCESS:
      return { ...state, tradingPairs: state.tradingPairs.map((item) => (item.id === payload.id ? payload : item)) };

    case systemSettingsTypes.PUT_TG_SETTINGS:
      return { ...state, tgSettings: payload };

    default:
      return state;
  }
}
