import { call, put, takeLatest, ForkEffect } from 'redux-saga/effects';
import { systemSettingsTypes } from '../types';
import * as apiHelpers from './apiHelpers/systemSettingsApiHelpers';
import * as actions from '../actions/systemSettingsActions';
import * as Shapes from 'interfaces/systemSettingsShapes';
import { callbackResolver } from 'helpers/callbackHelpers';

function* getBinanceAccountsWorker({ payload }: Shapes.GetBinanceAccounts) {
  try {
    const { data } = yield call(apiHelpers.getBinanceAccountsHelper);
    yield put(actions.putBinanceAccounts(data));
    callbackResolver(payload.onFinally);
  } catch (error) {
    payload?.onError(error);
    callbackResolver(payload.onFinally);
    console.error('getBinanceAccountsWorker', error);
  }
}

function* addBinanceAccWorker({ payload }: Shapes.AddBinanceAccShape) {
  try {
    const validateReqData = { api_key: payload.reqData.api_key, secret_key: payload.reqData.api_secret };
    yield call(apiHelpers.addBinanceAccValidateHelper, validateReqData);
    yield call(apiHelpers.addBinanceAccHelper, payload.reqData);
    yield put(actions.getBinanceAccounts());
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('addBinanceAccWorker', error);
  }
}

function* editBinanceAccWorker({ payload }: Shapes.EditBinanceAccShape) {
  try {
    yield call(apiHelpers.editBinanceAccHelper, payload.reqData, payload.accountId);
    yield put(actions.getBinanceAccounts());
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('editBinanceAccWorker', error);
  }
}

function* deleteBinanceAccWorker({ payload }: Shapes.DeleteBinanceAccShape) {
  try {
    yield call(apiHelpers.deleteBinanceAccHelper, payload.accountId);
    yield put(actions.getBinanceAccounts());
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('deleteBinanceAccWorker', error);
  }
}

function* getTelegramNotificationsWorker({ payload }: Shapes.GetTGSettings) {
  try {
    const { data } = yield call(apiHelpers.getTGNotificationsSettingsHelper);
    yield put(actions.putTGSettings(data));
    callbackResolver(payload.onFinally);
  } catch (error) {
    payload?.onError(error);
    callbackResolver(payload.onFinally);
    console.error('getTelegramNotificationsWorker', error);
  }
}

function* createTelegramNotificationsWorker({ payload }: Shapes.CreateTGSettings) {
  try {
    const { data } = yield call(apiHelpers.addTGNotificationsSettingsHelper, payload.reqData);
    yield put(actions.putTGSettings(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('createTelegramNotificationsWorker', error);
  }
}

function* editTelegramNotificationsWorker({ payload }: Shapes.EditTGSettings) {
  try {
    const { data } = yield call(apiHelpers.editTGNotificationsSettingsHelper, payload.reqData);
    yield put(actions.putTGSettings(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('editTelegramNotificationsWorker', error);
  }
}

function* getTradingPairsWorker({ payload }: Shapes.GetTradingPairs) {
  try {
    const { data } = yield call(apiHelpers.getTradingPairsHelper);
    yield put(actions.putTradingPairs(data));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    payload?.onError(error);
    console.error('getTradingPairsWorker', error);
  }
}

function* syncTradingPairsWorker({ payload }: Shapes.GetTradingPairs) {
  try {
    const { data } = yield call(apiHelpers.syncTradingPairsHelper);
    yield put(actions.putTradingPairs(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('syncTradingPairsWorker', error);
  }
}

function* changeTradingPairWorker({ payload }: Shapes.ChangeTradingPair) {
  try {
    const { data } = yield call(apiHelpers.changeTradingPairHelper, payload.reqData, payload.pairId);
    yield put(actions.changeTradingPairSuccess(data));
  } catch (error) {
    payload.onError(error);
    console.error('changeTradingPairWorker', error);
  }
}

export function* systemSettingsWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(systemSettingsTypes.GET_BINANCE_ACCOUNTS, getBinanceAccountsWorker);
  yield takeLatest(systemSettingsTypes.ADD_BINANCE_ACCOUNT_REQUEST, addBinanceAccWorker);
  yield takeLatest(systemSettingsTypes.EDIT_BINANCE_ACCOUNT_REQUEST, editBinanceAccWorker);
  yield takeLatest(systemSettingsTypes.DELETE_BINANCE_ACCOUNT_REQUEST, deleteBinanceAccWorker);
  yield takeLatest(systemSettingsTypes.GET_TG_SETTINGS, getTelegramNotificationsWorker);
  yield takeLatest(systemSettingsTypes.CREATE_TG_SETTINGS_REQUEST, createTelegramNotificationsWorker);
  yield takeLatest(systemSettingsTypes.EDIT_TG_SETTINGS_REQUEST, editTelegramNotificationsWorker);
  yield takeLatest(systemSettingsTypes.GET_TRADING_PAIRS, getTradingPairsWorker);
  yield takeLatest(systemSettingsTypes.SYNC_TRADING_PAIRS, syncTradingPairsWorker);
  yield takeLatest(systemSettingsTypes.CHANGE_TRADING_PAIR, changeTradingPairWorker);
}
