import { mmProfilesTypes } from '../types';
import { AnyAction } from 'redux';
import { MMProfilesReducer, MMExtendedShape } from 'interfaces/mmProfilesShapes';

const initialState = {
  mmProfiles: [] as Array<MMExtendedShape>
};

export default function mmProfilesReducer(state = initialState, { type, payload }: AnyAction): MMProfilesReducer {
  switch (type) {
    case mmProfilesTypes.PUT_MM_PROFILES:
      return { ...state, mmProfiles: payload };

    case mmProfilesTypes.CHANGE_MM_PROFILE_SUCCESS:
      return {
        ...state,
        mmProfiles: state.mmProfiles.map((profile) => (profile.id === payload.id ? payload : profile))
      };
    default:
      return state;
  }
}
