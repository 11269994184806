import { ColumnAlignOptions, ButtonColorTypes } from 'constants/randomConstants';
import { StrategyProfileStatuses } from 'interfaces/strategyProfilesShapes';
import { StrategyColIds as StrategyPrivilegesColIds } from './privilegesConstants';

export const DEFAULT_REQ_PARAMS = {
  page: 1,
  show_hide: false
};

export enum STRATEGY_STATUS {
  ACTIVE = 'started',
  DISABLED = 'stopped'
}

export const STRATEGY_STATUS_VALUE = {
  [STRATEGY_STATUS.ACTIVE]: 'Active',
  [STRATEGY_STATUS.DISABLED]: 'Stopped'
};

export enum FormTarget {
  CREATION = 'Creation',
  EDITING = 'Editing'
}

export const PAIRS_SHOW_IN_SUMMARY = 2;

export enum StrategyColIds {
  EXCHANGE_TITLE = 'exchange_account',
  STRATEGY_TITLE = 'title',
  PROFILE_DIRECTION = 'profile_direction',
  TIME_FRAME = 'timeframe',
  TRADING_PAIRS = 'ticker_configs_name',
  MM_PROFILE_TITLE = 'mm_profile',
  STATUS = 'status',
  VISIBILITY = 'is_show',
  ACTION = 'action'
}

export const STRATEGY_TABLE_COL_NAMES = {
  [StrategyColIds.EXCHANGE_TITLE]: 'Exchange account name',
  [StrategyColIds.STRATEGY_TITLE]: 'Strategy profile name',
  [StrategyColIds.PROFILE_DIRECTION]: 'Price direction',
  [StrategyColIds.TIME_FRAME]: 'Time frame',
  [StrategyColIds.TRADING_PAIRS]: 'Ticker list name',
  [StrategyColIds.MM_PROFILE_TITLE]: 'MM profile name',
  [StrategyColIds.STATUS]: 'Status',
  [StrategyColIds.VISIBILITY]: 'Visibility',
  [StrategyColIds.ACTION]: 'Action'
};

export const SERVER_CLIENT_COLS_MAP = {
  [StrategyColIds.EXCHANGE_TITLE]: StrategyPrivilegesColIds.EXCHANGE_TITLE,
  [StrategyColIds.STRATEGY_TITLE]: StrategyPrivilegesColIds.STRATEGY_TITLE,
  [StrategyColIds.PROFILE_DIRECTION]: StrategyPrivilegesColIds.PROFILE_DIRECTION,
  [StrategyColIds.TIME_FRAME]: StrategyPrivilegesColIds.TIME_FRAME,
  [StrategyColIds.TRADING_PAIRS]: StrategyPrivilegesColIds.TRADING_PAIRS,
  [StrategyColIds.MM_PROFILE_TITLE]: StrategyPrivilegesColIds.MM_PROFILE_TITLE,
  [StrategyColIds.STATUS]: StrategyPrivilegesColIds.STATUS,
  [StrategyColIds.VISIBILITY]: StrategyPrivilegesColIds.VISIBILITY,
  [StrategyColIds.ACTION]: StrategyPrivilegesColIds.ACTION
};

export const FORBIDDEN_RO_COLUMNS = [StrategyColIds.VISIBILITY, StrategyColIds.ACTION];

export const STRATEGY_COLUMNS = [
  {
    id: StrategyColIds.STRATEGY_TITLE,
    title: STRATEGY_TABLE_COL_NAMES[StrategyColIds.STRATEGY_TITLE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyColIds.EXCHANGE_TITLE,
    title: STRATEGY_TABLE_COL_NAMES[StrategyColIds.EXCHANGE_TITLE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyColIds.PROFILE_DIRECTION,
    title: STRATEGY_TABLE_COL_NAMES[StrategyColIds.PROFILE_DIRECTION],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyColIds.TIME_FRAME,
    title: STRATEGY_TABLE_COL_NAMES[StrategyColIds.TIME_FRAME],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyColIds.TRADING_PAIRS,
    title: STRATEGY_TABLE_COL_NAMES[StrategyColIds.TRADING_PAIRS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyColIds.MM_PROFILE_TITLE,
    title: STRATEGY_TABLE_COL_NAMES[StrategyColIds.MM_PROFILE_TITLE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyColIds.STATUS,
    title: STRATEGY_TABLE_COL_NAMES[StrategyColIds.STATUS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyColIds.VISIBILITY,
    title: STRATEGY_TABLE_COL_NAMES[StrategyColIds.VISIBILITY],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyColIds.ACTION,
    title: STRATEGY_TABLE_COL_NAMES[StrategyColIds.ACTION],
    align: ColumnAlignOptions.CENTER
  }
];

export interface StrategyTableRow {
  isCollapsible: boolean;
  id: number;
  [StrategyColIds.EXCHANGE_TITLE]: string;
  [StrategyColIds.STRATEGY_TITLE]: string;
  [StrategyColIds.PROFILE_DIRECTION]: string;
  [StrategyColIds.TIME_FRAME]: string;
  [StrategyColIds.TRADING_PAIRS]: string;
  [StrategyColIds.MM_PROFILE_TITLE]: string;
  [StrategyColIds.STATUS]: StrategyProfileStatuses;
  [StrategyColIds.VISIBILITY]: boolean;
  [StrategyColIds.ACTION]: { label: string; color: ButtonColorTypes };
}

const { ACTIVE, INACTIVE, DRAFT, STOPPING } = StrategyProfileStatuses;
const { PRIMARY, SECONDARY, DEFAULT } = ButtonColorTypes;

export const ACTION_BTN_COLORS = {
  [ACTIVE]: SECONDARY,
  [INACTIVE]: PRIMARY,
  [DRAFT]: DEFAULT,
  [STOPPING]: PRIMARY
};

export const ACTION_BTN_LABELS = {
  [ACTIVE]: 'Stop',
  [INACTIVE]: 'Start',
  [DRAFT]: 'Continue',
  [STOPPING]: 'Start'
};

export const STRATEGY_BTN_COLORS = {
  [STRATEGY_STATUS.ACTIVE]: SECONDARY,
  [STRATEGY_STATUS.DISABLED]: PRIMARY
};

export const STRATEGY_BTN_LABELS = {
  [STRATEGY_STATUS.ACTIVE]: 'Deactivate',
  [STRATEGY_STATUS.DISABLED]: 'Activate'
};

export const STRATEGY_BTN_ACTIONS = {
  [STRATEGY_STATUS.ACTIVE]: 'stop',
  [STRATEGY_STATUS.DISABLED]: 'start'
};
