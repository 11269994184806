import React, { useEffect, useMemo, useState } from 'react';
import s from './MonthsStatsTable.module.scss';
import NavTabs from '../navTabs/NavTabs';
import CustomizedTable from '../customizedTable/CustomizedTable';
import { getResultByMonthsStats } from '../../redux/actions/statsActions';
import { useDispatch, useSelector } from 'react-redux';
import { resultsByMonthsStatsSelector } from '../../redux/selectors';
import {
  createMonthsStatsRowsFlexible,
  getMonthsStatsColumns,
  monthsStatsRenderSwitch
} from '../../helpers/monthsStatsHelpers';
import { HARD_PER_PAGE } from '../../constants/randomConstants';
import {
  MONTHS_STATS_COLUMNS_END,
  MONTHS_STATS_COLUMNS_START,
  MonthsStatsTableColIds
} from '../../constants/monthsStatsConstants';
import { SingleResultRow } from '../../interfaces/monthsResultsShapes';
import { ErrorShape } from '../../interfaces/reduxRandomShapes';
import { getErrorCutMessageHelper, getErrorMessageHelper } from '../../helpers/randomHelpers';
import { StickyContext } from '../../constants/privilegesConstants';
import { usePrivileges } from '../../hooks/usePrivileges';
import { getStatsTabs, isSectionAllowed } from '../../helpers/privilegesHelpers';
import { useStyles } from './styles';
import StatsStaticGraphs from '../statsStaticGraphs/StatsStaticGraphs';
import { DEFAULT_CHART_IFRAME_PARAMS } from '../../constants/chartStatsConstants';
import { useQueryParams } from '../../hooks/useQueryParams';
import CustomTooltip from '../customTooltip/CustomTooltip';
import cn from 'classnames';

const MonthsStatsTable = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    results,
    dates,
    turn_over_at_value,
    turn_over_week_value,
    turn_over_month_value,
    turn_over_day_value,
    positive_months,
    worst_month_return,
    best_month_return,
    avg_capital_growth_rate
  } = useSelector(resultsByMonthsStatsSelector);

  const { charts } = usePrivileges();

  const [isLoading, setLoading] = useState(false);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const [reqError, setReqErr] = useState<null | string>(null);
  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  useEffect(() => {
    onToggleLoading();
    dispatch(getResultByMonthsStats(onToggleLoading, onSetError));
  }, [dispatch]);

  const receivedColumns = useMemo(() => {
    return dates.map((date, index) => getMonthsStatsColumns(date, index));
  }, [dates]);

  const rows: SingleResultRow[] = useMemo(() => {
    return results
      ?.map((stats, idx) =>
        Object.entries(stats).reduce((acc, [key, val], _, array) => {
          if (key === 'account') {
            return acc;
          }
          const resultRow = createMonthsStatsRowsFlexible(results[0]?.account ?? '', key, val, idx * array.length);
          return [...acc, resultRow];
        }, [])
      )
      .flat();
  }, [results]);

  const sortedRows = useMemo(() => {
    return rows.sort((a, b) => a.order - b.order);
  }, [rows]);

  const additionalConfig = useMemo(() => {
    const resBest = {
      value: best_month_return?.value ?? 0,
      data: best_month_return?.date ?? ''
    };
    const resWorst = {
      value: worst_month_return?.value ?? 0,
      data: worst_month_return?.date ?? ''
    };
    const resAverage = positive_months ?? 0;
    const account = results[0]?.account ?? '';

    return {
      resBest,
      resAverage,
      resWorst,
      account
    };
  }, [
    best_month_return?.date,
    best_month_return?.value,
    positive_months,
    results,
    worst_month_return?.date,
    worst_month_return?.value
  ]);

  const { tooltip } = useStyles();

  const getContent = (colId, row: SingleResultRow) => {
    return monthsStatsRenderSwitch(colId, row, tooltip);
  };

  const privileges = usePrivileges();
  const tabs = getStatsTabs(privileges);

  const columns = [...MONTHS_STATS_COLUMNS_START, ...receivedColumns, ...MONTHS_STATS_COLUMNS_END];

  useQueryParams(DEFAULT_CHART_IFRAME_PARAMS);

  return (
    <div className={s.tableWrapper}>
      <div className={s.headerWrapper}>
        <h2 className="default-title">Stats by months</h2>
        <h2>
          <CustomTooltip title={<div style={{ fontSize: 72 }}>Account name</div>}>
            <span>{additionalConfig.account}</span>
          </CustomTooltip>
        </h2>
        <NavTabs tabs={tabs} classes={{ wrapperClass: s.navTabWrapper }} />
      </div>
      <div className={s.infoContainer}>
        <div className={s.turnoverBlock}>
          <div className={s.turnover}>
            <span>Turnover at all: </span>
            <span>{turn_over_at_value.toFixed(0)}$</span>
          </div>
          <div className={s.turnover}>
            <span>Turnover at month: </span>
            <span>{turn_over_month_value.toFixed(0)}$</span>
          </div>
          <div className={s.turnover}>
            <span>Turnover at week: </span>
            <span>{turn_over_week_value.toFixed(0)}$</span>
          </div>
          <div className={s.turnover}>
            <span>Turnover at day: </span>
            <span>{turn_over_day_value.toFixed(0)}$</span>
          </div>
        </div>
        <div className={cn({ [s.infoBlock]: isSectionAllowed(charts), [s.infoBlockFull]: !isSectionAllowed(charts) })}>
          <div className={s.info}>
            <span>Best Month Return: </span>
            <span>{additionalConfig.resBest.value}% </span>
            <span>({additionalConfig.resBest.data})</span>
          </div>
          <div className={s.info}>
            <span>Positive Months: </span>
            <span>{additionalConfig.resAverage.toFixed(0)}%</span>
          </div>
          <div className={s.info}>
            <span>Worst Month Return: </span>
            <span>{additionalConfig.resWorst.value}% </span>
            <span>({additionalConfig.resWorst.data})</span>
          </div>
          <div className={s.info}>
            <span>ACGR: </span>
            <span>{avg_capital_growth_rate.toFixed(2)}%</span>
          </div>
        </div>
      </div>
      {isSectionAllowed(charts) && <StatsStaticGraphs isIframe />}
      <StickyContext.Provider
        value={{
          leftSticky: MonthsStatsTableColIds.ACCOUNT,
          rightSticky: MonthsStatsTableColIds.AVERAGE,
          rightStickySecond: MonthsStatsTableColIds.TOTAL
        }}
      >
        <CustomizedTable
          columns={columns}
          rows={sortedRows}
          isLoading={isLoading}
          error={reqError}
          emptyRowsMsg="There are no results yet..."
          getContent={getContent}
          count={sortedRows.length}
          rowsPerPageOptions={[HARD_PER_PAGE]}
          customPerPage={HARD_PER_PAGE}
          getCollapsibleContent={() => null}
          hidePagination
          classes={{
            tableWrapper: cn({
              [s.tableContainer]: isSectionAllowed(charts),
              [s.tableContainerFull]: !isSectionAllowed(charts)
            }),
            wrapperClass: s.monthsContent,
            tHeadClasses: {
              tHeadCellClass: s.tHeadCellClass
            },
            tBodyClasses: {
              tBodyCellClass: s.tBodyCellClass
            }
          }}
        />
      </StickyContext.Provider>
    </div>
  );
};

export default MonthsStatsTable;
