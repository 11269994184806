import { BinanceAccountShape } from 'interfaces/systemSettingsShapes';
import { changeDepositMMProfile } from '../redux/actions/mmProfilesActions';

export interface AddMMProfileReqShape {
  title: string;
  status?: MMStatuses;
  all_signals_quota: string;
  max_signals_amount: number;
  exchange_account: number;
}

interface MMProfileShape {
  id: number;
  title: string;
  status?: MMStatuses;
  created_at: Date;
  updated_at: Date;
  settings: number;
  mm_profile: number;
}

export interface MMExtendedShape {
  id: number;
  profiles: Array<MMProfileShape>;
  exchange_account: BinanceAccountShape;
  title: string;
  status: MMStatuses;
  all_signals_quota: string;
  max_signals_amount: number;
  max_factor_amount: number;
  created_at: Date;
  updated_at: Date;
  is_show: boolean;
}

export interface ChangedDepositFormDTO {
  operation_type: 'increase' | 'decrease';
  size: number;
}

export interface FormValues {
  operation_type: string;
  size: string;
}

export enum MMStatuses {
  INACTIVE = 'Inactive',
  ACTIVE = 'Active',
  STOPPING = 'Stopping'
}

export interface MMProfilesReducer {
  mmProfiles: Array<MMExtendedShape> | [];
}

export interface AddMMProfileShape {
  type: string;
  payload: { reqData: AddMMProfileReqShape; onSuccess: () => void; onError: (err) => void };
}

export interface GetMMProfilesShape {
  type: string;
  payload: { reqData: boolean; onSuccess: () => void; onError: (err) => void };
}

export interface PutMMProfiles {
  type: string;
  payload: Array<MMExtendedShape>;
}

export interface ChangeMMProfileReqShape {
  title?: string;
  status?: MMStatuses;
  all_signals_quota?: string;
  max_signals_amount?: number;
  exchange_account?: number;
  max_factor_amount?: number;
  is_show?: boolean;
}

export interface ChangeMMProfile {
  type: string;
  payload: {
    profileId: number;
    reqData: ChangeMMProfileReqShape;
    onError: (err) => void;
    onSuccess?: () => void;
  };
}

export interface ChangeMMProfileSuccess {
  type: string;
  payload: MMExtendedShape;
}

export interface GetMMProfile {
  type: string;
  payload: {
    id: number;
    onError: () => void;
  };
}

export interface ChangeMMProfileVisibility {
  type: string;
  payload: {
    profileId: number;
    reqData: ChangeMMProfileReqShape;
    onSuccess: () => void;
  };
}

export interface ChangeDepositMMProfile {
  type: string;
  payload: {
    reqData: ChangedDepositFormDTO;
    onSuccess: () => void;
    onError: (err) => void;
  };
}
