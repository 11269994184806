import React from 'react';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import s from './PairedBtnBlock.module.scss';

interface PairedBtnBlockProps {
  onCancel: () => void;
  onSubmit: () => void;
  labels?: {
    cancelLabel?: string;
    submitLabel?: string;
  };
  btnTypeCancel?: 'submit' | 'reset' | 'button';
  btnTypeSubmit?: 'submit' | 'reset' | 'button';
  disableSubmit?: boolean;
  disableCancel?: boolean;
  colors?: {
    cancelColor?: 'default' | 'inherit' | 'primary' | 'secondary';
    submitColor?: 'default' | 'inherit' | 'primary' | 'secondary';
  };
  classes?: { btnBlockWrapper?: string };
}

const PairedBtnBlock = ({
  onCancel,
  onSubmit,
  labels,
  btnTypeCancel,
  btnTypeSubmit,
  disableCancel,
  disableSubmit,
  colors,
  classes
}: PairedBtnBlockProps): JSX.Element => {
  return (
    <div className={cn(s.btnBlockWrapper, { [classes?.btnBlockWrapper]: classes?.btnBlockWrapper })}>
      <Button
        fullWidth
        variant="outlined"
        size="medium"
        type={btnTypeCancel ?? 'button'}
        color={colors?.cancelColor ?? 'secondary'}
        classes={{ root: s.btnRoot, text: s.btnText }}
        disabled={disableCancel}
        onClick={onCancel}
      >
        {labels.cancelLabel ?? 'Cancel'}
      </Button>
      <Button
        fullWidth
        variant="outlined"
        size="medium"
        type={btnTypeSubmit ?? 'submit'}
        color={colors?.submitColor ?? 'primary'}
        onClick={onSubmit}
        disabled={disableSubmit}
        classes={{ root: s.btnRoot, outlinedPrimary: s.btnSavePrimary }}
      >
        {labels.submitLabel ?? 'Submit'}
      </Button>
    </div>
  );
};

export default PairedBtnBlock;
