import * as Yup from 'yup';

export enum BinanceAccFieldsNames {
  TITLE = 'title',
  API_KEY = 'api_key',
  SECRET_KEY = 'api_secret',
  LEVERAGE = 'leverage'
}

export const BINANCE_ACC_FIELDS = [
  {
    id: BinanceAccFieldsNames.TITLE,
    label: 'Name',
    placeholder: 'Account Name'
  },
  {
    id: BinanceAccFieldsNames.API_KEY,
    label: 'API Key',
    placeholder: 'Account API Key'
  },
  {
    id: BinanceAccFieldsNames.LEVERAGE,
    label: 'Leverage',
    placeholder: '0-100'
  },
  {
    id: BinanceAccFieldsNames.SECRET_KEY,
    label: 'Secret Key',
    placeholder: 'Account Secret Key'
  }
];

export const binanceAccValidationEdit = {
  [BinanceAccFieldsNames.TITLE]: Yup.string()
    .required('Name can’t be blank')
    .max(64, 'Name could be maximum 64 symbols'),
  [BinanceAccFieldsNames.API_KEY]: Yup.string()
    .required('API key can’t be blank')
    .max(64, 'API key could be maximum 64 symbols'),
  [BinanceAccFieldsNames.LEVERAGE]: Yup.number()
    .required('Leverage can’t be blank')
    .min(0, 'Leverage could be maximum only 0-100')
    .max(100, 'Leverage could be maximum only 0-100')
};

export const binanceAccValidationAdd = {
  [BinanceAccFieldsNames.SECRET_KEY]: Yup.string()
    .required('Secret key can’t be blank')
    .max(64, 'Secret key could be maximum 64 symbols')
};

export enum TG_NotificationFields {
  ID = 'id',
  BOT_TOKEN = 'token',
  CHAT_ID = 'chat_id'
}

export const TG_NOTIFICATION_FIELDS = [
  {
    id: TG_NotificationFields.BOT_TOKEN,
    label: 'Bot API Token',
    placeholder: 'Bot API Token'
  },
  {
    id: TG_NotificationFields.CHAT_ID,
    label: 'Chat ID',
    placeholder: 'Chat ID'
  }
];

export const tgNotificationValidationSchema = Yup.object({
  [TG_NotificationFields.BOT_TOKEN]: Yup.string()
    .required('Bot API Token can’t be blank')
    .max(512, 'Bot API Token could be maximum 512 symbols'),
  [TG_NotificationFields.CHAT_ID]: Yup.string()
    .required('Chat ID can’t be blank')
    .max(16, 'Chat ID could be maximum 16 symbols')
});
