import React from 'react';
import MainLayout from 'components/mainLayout/MainLayout';
import BinanceAccounts from './components/binanceAccounts/BinanceAccounts';
import TelegramNotifications from './components/telegramNotifications/TelegramNotifications';
import TradingPairs from './components/tradingPairs/TradingPairs';
import s from './SystemSettingsPage.module.scss';
import { usePrivileges } from '../../hooks/usePrivileges';
import { isSectionAllowed } from '../../helpers/privilegesHelpers';

const SystemSettingsPage = (): JSX.Element => {
  const { pairs, binance_accounts, tg_bot } = usePrivileges();

  return (
    <MainLayout>
      <div className={s.contentWrapper}>
        <div className={s.column}>
          {isSectionAllowed(binance_accounts) && <BinanceAccounts />}
          {isSectionAllowed(tg_bot) && <TelegramNotifications />}
        </div>
        <div className={s.column}>{isSectionAllowed(pairs) && <TradingPairs />}</div>
      </div>
    </MainLayout>
  );
};

export default SystemSettingsPage;
