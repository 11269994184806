import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { DEFAULT_CHART_PARAMS } from '../constants/chartStatsConstants';

export interface QueryParams {
  queryParams: typeof DEFAULT_CHART_PARAMS | null;
  setNewQueryParams: (value: typeof DEFAULT_CHART_PARAMS) => void;
}

export const useQueryParams = (defaultParams: typeof DEFAULT_CHART_PARAMS): QueryParams => {
  const history = useHistory();
  const queryParams = qs.parse(window.location.search);
  const [newQueryParams, setNewQueryParams] = useState(defaultParams);

  useEffect(() => {
    const reqParams = qs.stringify(newQueryParams);

    history.push({
      pathname: window.location.pathname,
      search: `?${reqParams}`
    });
  }, [history, newQueryParams]);

  return {
    queryParams,
    setNewQueryParams
  } as QueryParams;
};
