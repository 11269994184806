import { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from 'constants/apiEndpoints';
import * as Shapes from 'interfaces/systemSettingsShapes';
import { AxiosInstance as axios } from '../../../helpers/authHelpers';

export const getBinanceAccountsHelper = (): Promise<AxiosResponse<Array<Shapes.BinanceAccountShape> | []>> => {
  return axios.get(API_ENDPOINTS.EXCHANGE_ACCOUNTS);
};

export const addBinanceAccValidateHelper = (reqData: Shapes.BinanceAccValidateShape): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.VALIDATE_EXCHANGE_ACCOUNT, reqData);
};

export const addBinanceAccHelper = (reqData: Shapes.BinanceAccountFormFields): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.EXCHANGE_ACCOUNTS, reqData);
};

export const editBinanceAccHelper = (
  reqData: Shapes.BinanceAccountFormFields,
  accountId: number
): Promise<AxiosResponse> => {
  return axios.put(API_ENDPOINTS.EDIT_EXCHANGE_ACCOUNT(accountId), reqData);
};

export const deleteBinanceAccHelper = (accountId: number): Promise<AxiosResponse> => {
  return axios.delete(API_ENDPOINTS.DELETE_EXCHANGE_ACCOUNT(accountId));
};

export const getTGNotificationsSettingsHelper = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.TG_NOTIFICATIONS);
};

export const addTGNotificationsSettingsHelper = (reqData: Shapes.TG_NotificationValues): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.TG_NOTIFICATIONS, reqData);
};

export const editTGNotificationsSettingsHelper = (reqData: Shapes.TG_NotificationValues): Promise<AxiosResponse> => {
  return axios.put(API_ENDPOINTS.EDIT_TG_NOTIFICATIONS, reqData);
};

export const getTradingPairsHelper = (): Promise<AxiosResponse<Array<Shapes.TradingPairShape>>> => {
  return axios.get(API_ENDPOINTS.TRADING_PAIRS);
};

export const syncTradingPairsHelper = (): Promise<AxiosResponse<Array<Shapes.TradingPairShape>>> => {
  return axios.post(API_ENDPOINTS.TRADING_PAIRS, {});
};

export const changeTradingPairHelper = (
  reqData: { ticker: string; is_active: boolean },
  pairId: number
): Promise<AxiosResponse<Shapes.TradingPairShape>> => {
  return axios.put(API_ENDPOINTS.TRADING_PAIR(pairId), reqData);
};
