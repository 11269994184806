import { ColumnAlignOptions } from './randomConstants';

export enum StrategyTickersIds {
  ID = 'id',
  TITLE = 'title',
  PAIRS = 'pairs',
  STATUS = 'status',
  DELETE = 'delete',
  SAVE = 'save'
}

export const StrategyTickersColsNames = {
  [StrategyTickersIds.TITLE]: 'Config Name',
  [StrategyTickersIds.PAIRS]: 'Tickers List',
  [StrategyTickersIds.STATUS]: 'Status',
  [StrategyTickersIds.DELETE]: 'Removal',
  [StrategyTickersIds.SAVE]: 'Save'
};

export const STRATEGY_TICKERS_COLS = [
  {
    id: StrategyTickersIds.TITLE,
    title: StrategyTickersColsNames[StrategyTickersIds.TITLE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyTickersIds.PAIRS,
    title: StrategyTickersColsNames[StrategyTickersIds.PAIRS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyTickersIds.STATUS,
    title: StrategyTickersColsNames[StrategyTickersIds.STATUS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyTickersIds.DELETE,
    title: StrategyTickersColsNames[StrategyTickersIds.DELETE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StrategyTickersIds.SAVE,
    title: StrategyTickersColsNames[StrategyTickersIds.SAVE],
    align: ColumnAlignOptions.CENTER
  }
];
