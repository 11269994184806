import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStrategyStats, getStrategyStatsExport, getStrategyStatsFilters } from 'redux/actions/statsActions';
import { getComponentsFieldsSelector, profileStateFilters, signalsStatsSelector } from 'redux/selectors';
import CustomizedTable from 'components/customizedTable/CustomizedTable';
import { createSignalsStatsRow, signalsStatsRenderSwitch } from 'helpers/signalsStatsHelpers';
import { getErrorCutMessageHelper, getErrorMessageHelper } from 'helpers/randomHelpers';
import { callbackResolver } from 'helpers/callbackHelpers';
import { HARD_PER_PAGE, ZERO_BASED_PAGE_SHIFT } from 'constants/randomConstants';
import {
  DEFAULT_STRATEGY_STATS_PARAMS,
  initialStrategyStatsValues,
  InitialStrategyStatsValuesType,
  SERVER_CLIENT_COLS_MAP,
  SORTING_MAP,
  StickyProfileStrategyStats,
  STRATEGY_STATS_COLUMNS
} from 'constants/strategyStatsConstants';
import { StrategyStatsShape, StrategyStatsTableRow } from 'interfaces/strategyStatsShapes';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import s from './StrategyStatsTable.module.scss';
import { removeEmptyStatsParams } from 'helpers/emptyParamsRemoverHelpers';
import StrategyStatsFilters from './StrategyStatsFilters';
import { usePrivileges } from '../../hooks/usePrivileges';
import { getStatsTabs, isSectionFull, isSectionLimited } from '../../helpers/privilegesHelpers';
import { PrivilegesGroupFields } from '../../interfaces/privilegesShapes';
import Button from '@material-ui/core/Button';
import NavTabs from '../navTabs/NavTabs';
import { FIELD_DEPENDENCIES_MAP, PrivilegesColIds, StickyContext } from '../../constants/privilegesConstants';

const StrategyStatsTable: FC = () => {
  const dispatch = useDispatch();
  const strategyStatsData = useSelector(signalsStatsSelector);
  const { profiles } = useSelector(profileStateFilters);
  const privileges = usePrivileges();
  const { profiles_stats } = privileges;
  const { fields, filtering, sorting } = useSelector(getComponentsFieldsSelector(PrivilegesGroupFields.PROFILES_STATS));
  const tabs = getStatsTabs(privileges);

  const [reqParams, setReqParams] = useState(DEFAULT_STRATEGY_STATS_PARAMS);
  const [reqError, setReqErr] = useState<null | string>(null);

  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const [isLoading, setLoading] = useState(false);
  const [isLoadingExport, setLoadingExport] = useState(false);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const onChangePage = useCallback((newPage: number) => {
    setReqParams((prev) => ({ ...prev, page: newPage + ZERO_BASED_PAGE_SHIFT }));
  }, []);

  const onGetSignalsStats = useCallback(
    (callback?: () => void) => {
      onToggleLoading();
      dispatch(
        getStrategyStats(
          removeEmptyStatsParams(reqParams),
          () => {
            onToggleLoading();
            callbackResolver(callback);
          },
          onSetError
        )
      );
    },
    [dispatch, reqParams]
  );

  const getContent = useCallback(
    (colId, row: StrategyStatsTableRow) => signalsStatsRenderSwitch(colId, row, isSectionLimited(profiles_stats)),
    [profiles_stats]
  );

  const rows = useMemo(
    () => strategyStatsData.map((item: StrategyStatsShape) => createSignalsStatsRow(item)),
    [strategyStatsData]
  );

  useEffect(onGetSignalsStats, [onGetSignalsStats]);
  useEffect(() => {
    dispatch(getStrategyStatsFilters());
  }, [dispatch]);

  const [filterParams, setFilterParams] = useState<InitialStrategyStatsValuesType>(initialStrategyStatsValues);
  const [disabledFilter, setDisabledFilter] = useState(true);

  const fieldsDeps = FIELD_DEPENDENCIES_MAP[PrivilegesColIds.PROFILES_STATS];

  const filteredColumns = useMemo(() => {
    return isSectionLimited(profiles_stats)
      ? STRATEGY_STATS_COLUMNS.filter((col) => {
          return fields.some((field: string) => {
            if (fieldsDeps.indexOf(col.id) <= 0) {
              return field === SERVER_CLIENT_COLS_MAP[col.id];
            } else {
              return fieldsDeps.some((dep) => fields.indexOf(dep) < 0);
            }
          });
        })
      : STRATEGY_STATS_COLUMNS;
  }, [profiles_stats, fields, fieldsDeps]);

  const onSortClick = (col: string, withMinusSign: boolean) => {
    const params = {
      ...filterParams,
      page: 1,
      ordering: `${withMinusSign ? '-' : ''}${SORTING_MAP[col]}`
    };
    setReqParams(params);
    setDisabledFilter(true);
  };

  const onExportClick = () => {
    setLoadingExport(true);
    dispatch(getStrategyStatsExport(removeEmptyStatsParams(reqParams), () => setLoadingExport(false)));
  };

  const isExportDisabled = useMemo(() => {
    return isSectionFull(privileges.profiles_stats_export) ? isLoadingExport : true;
  }, [isLoadingExport, privileges.profiles_stats_export]);

  return (
    <div className={s.tableWrapper}>
      <div className={s.headerWrapper}>
        <h2 className="default-title">Strategy stats</h2>
        <Button
          variant="outlined"
          size="medium"
          type="button"
          classes={{ root: s.btnRoot }}
          onClick={onExportClick}
          disabled={isExportDisabled}
        >
          Export
        </Button>
        <NavTabs tabs={tabs} classes={{ wrapperClass: s.navTabWrapper }} />
      </div>
      <div>
        {filtering && (
          <StrategyStatsFilters
            filterParams={filterParams}
            disabledFilter={disabledFilter}
            setDisabledFilter={setDisabledFilter}
            setReqParams={setReqParams}
            setFilterParams={setFilterParams}
            reqParams={reqParams}
            profiles={profiles}
          />
        )}
        <StickyContext.Provider
          value={{ bottomSticky: { key: StickyProfileStrategyStats.key, val: StickyProfileStrategyStats.val } }}
        >
          <CustomizedTable
            count={filteredColumns.length ? rows.length : 0}
            columns={filteredColumns}
            rows={filteredColumns.length ? rows : []}
            isLoading={isLoading}
            error={reqError}
            emptyRowsMsg="There are no strategy stats yet..."
            getContent={getContent}
            getCollapsibleContent={() => null}
            rowsPerPageOptions={[HARD_PER_PAGE]}
            customPerPage={HARD_PER_PAGE}
            customPage={0}
            onCustomChangePage={onChangePage}
            offPerPage
            sortHandler={onSortClick}
            hasSortingOption={sorting}
            classes={{
              tableWrapper: s.tableWrapper,
              wrapperClass: s.signalsContent,
              tHeadClasses: {
                tHeadCellClass: s.tHeadCellClass
              },
              tBodyClasses: {
                tBodyCellClass: s.tBodyCellClass
              }
            }}
          />
        </StickyContext.Provider>
      </div>
    </div>
  );
};

export default StrategyStatsTable;
