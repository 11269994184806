import React, { ReactNode } from 'react';
import isEmpty from 'lodash/isEmpty';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import TableBody from '@material-ui/core/TableBody';
import FakeRow from 'components/customizedTable/rows/fakeRow/FakeRow';
import CollapsibleRow from 'components/customizedTable/rows/collapsibleRow/CollapsibleRow';
import { DEFAULT_TRANSITION_TIMEOUT } from 'constants/randomConstants';
import { TableColumn, TBodyCssClasses } from 'interfaces/randomShapes';

import Fade from 'components/reactTransitions/FadeTransition.module.css';
import s from './TBody.module.scss';

interface TBodyProps {
  isLoading: boolean;
  columns: Array<TableColumn>;
  rows: Array<{ id: number; isCollapsible?: boolean; isLostPosition?: boolean }>;
  error: null | string;
  emptyRowsMsg: string;
  getContent: (colId: string, row, collapseCallback?: () => void, isCollapseOpen?: boolean) => ReactNode;
  getCollapsibleContent?: (colId, row: Record<string, unknown>) => ReactNode;
  classes?: TBodyCssClasses;
}

const TBody = ({
  isLoading,
  columns,
  rows,
  error,
  emptyRowsMsg,
  getContent,
  getCollapsibleContent,
  classes
}: TBodyProps): JSX.Element => {
  return (
    <TableBody classes={{ root: s.tableBodyRoot }}>
      <SwitchTransition mode="out-in">
        <CSSTransition key={isLoading ? 'loader' : 'collection'} timeout={DEFAULT_TRANSITION_TIMEOUT} classNames={Fade}>
          {isLoading ? (
            <FakeRow isLoader />
          ) : (
            <React.Fragment>
              {error ? (
                <FakeRow isError msg={error} />
              ) : isEmpty(rows) ? (
                <FakeRow msg={emptyRowsMsg} />
              ) : (
                rows.map((item) => (
                  <CollapsibleRow
                    key={item.id}
                    row={item}
                    columns={columns}
                    getContent={getContent}
                    getCollapsibleContent={getCollapsibleContent}
                    classes={classes}
                  />
                ))
              )}
            </React.Fragment>
          )}
        </CSSTransition>
      </SwitchTransition>
    </TableBody>
  );
};

export default TBody;
