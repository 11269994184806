import { notificationTypes } from '../types';
import { AnyAction } from 'redux';
import { NotificationsReducer, NotificationShape } from 'interfaces/notificationShapes';

const initialState: NotificationsReducer = {
  results: [] as Array<NotificationShape>,
  count: 0,
  next: null,
  previous: null
};

export default function notificationsReducer(state = initialState, { type, payload }: AnyAction): NotificationsReducer {
  switch (type) {
    case notificationTypes.PUT_NOTIFICATIONS:
      return { ...state, results: [...state.results, ...payload.results] };

    case notificationTypes.REMOVE_NOTIFICATION_LOCALLY:
      return { ...state, results: state.results.filter(({ id }) => id !== payload) };

    default:
      return state;
  }
}
