import React, { useContext, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SwapVert } from '@material-ui/icons';
import { TableColumn, THeadCssClasses } from 'interfaces/randomShapes';
import cn from 'classnames';
import s from '../CustomizedTable.module.scss';
import { makeStyles } from '@material-ui/core';
import { StickyContext } from '../../../constants/privilegesConstants';

interface THeadProps {
  hasSortingOption: boolean;
  columns: TableColumn[];
  classes?: THeadCssClasses;
  sortHandler: (columnName?: string, withMinusSign?: boolean) => void;
  getCustomTitle: () => JSX.Element;
}

const useStyles = makeStyles({
  leftSticky: {
    position: 'sticky',
    left: 0,
    zIndex: 13
  },
  rightSingleSticky: {
    position: 'sticky',
    right: 0,
    zIndex: 13
  },
  rightSticky: {
    position: 'sticky',
    right: '47px',
    zIndex: 13
  },
  rightStickySecond: {
    position: 'sticky',
    right: 0,
    zIndex: 13
  }
});

const THead: React.FC<THeadProps> = ({ columns, classes, sortHandler, hasSortingOption, getCustomTitle }) => {
  const [sortUp, setSortUp] = useState(false);
  const sortingHandler = (title: string) => {
    sortHandler(title, sortUp);
    setSortUp((prev) => !prev);
  };
  const { leftSticky, rightSticky, rightStickySecond } = useContext(StickyContext);
  const muiClasses = useStyles();
  return (
    <TableHead>
      <TableRow>
        {columns.map((column, idx, arr) => {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ width: column.width ?? `calc(100% / ${arr.length})` }}
              className={cn(s.tHeadCell, {
                [classes?.tHeadCellClass]: classes?.tHeadCellClass,
                [muiClasses.leftSticky]: column.id === leftSticky,
                [muiClasses.rightSingleSticky]: rightStickySecond ? false : column.id === rightSticky,
                [muiClasses.rightSticky]: rightStickySecond ? column.id === rightSticky : false,
                [muiClasses.rightStickySecond]: column.id === rightStickySecond
              })}
            >
              <div className={s.sortWrapper}>
                {column.title}
                {hasSortingOption && column.sortable && <SwapVert onClick={() => sortingHandler(column.title)} />}
                {column?.kind === 'custom' && getCustomTitle()}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default THead;
