import React, { useReducer, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import PuffLoader from 'react-spinners/PuffLoader';
import Button from '@material-ui/core/Button';
import Modal from 'components/modal/MyModal';
import CustomizableList from 'components/customizableList/CustomizableList';
import BinanceAccountForm from './binanceAccountsForm/BinanceAccountForm';
import DeleteAccountContent from './deleteAccountContent/DeleteAccountContent';
import {
  getBinanceAccounts,
  addBinanceAccountRequest,
  editBinanceAccountRequest,
  deleteBinanceAccountRequest
} from 'redux/actions/systemSettingsActions';
import { systemSettingsSelector } from 'redux/selectors';
import { getErrorMessageHelper } from 'helpers/randomHelpers';
import { BinanceAccountShape, BinanceAccountFormFields } from 'interfaces/systemSettingsShapes';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import { DEFAULT_TRANSITION_TIMEOUT } from 'constants/randomConstants';
import Fade from 'components/reactTransitions/FadeTransition.module.css';
import { binanceAccountsTypes, binanceAccountsInitialState, binanceAccountsReducer } from './binanceAccountsConstants';
import s from './BinanceAccounts.module.scss';
import { usePrivileges } from '../../../../hooks/usePrivileges';
import { isSectionReadOnly } from '../../../../helpers/privilegesHelpers';

const LOADER_SIZE = 50;

const BinanceAccounts = (): JSX.Element => {
  const [{ isOpen, isEdit, isDelete, isLoading, currentAccount, deleteError }, localDispatch] = useReducer(
    binanceAccountsReducer,
    binanceAccountsInitialState
  );
  const { binanceAccounts } = useSelector(systemSettingsSelector);
  const dispatch = useDispatch();
  const { binance_accounts } = usePrivileges();
  const isReadOnly = isSectionReadOnly(binance_accounts);

  const handleAddAccount = () => {
    localDispatch({
      type: binanceAccountsTypes.CREATE_INIT
    });
  };

  const handleSubmitAdd = (values: BinanceAccountFormFields, onError: (err) => void) => {
    dispatch(addBinanceAccountRequest(values, onCloseModal, onError));
  };

  const handleEditAcc = (account: BinanceAccountShape) => {
    localDispatch({
      type: binanceAccountsTypes.EDIT_INIT,
      payload: account
    });
  };

  const handleSubmitEdit = (values: BinanceAccountFormFields, onError: (err) => void, accountId: number) => {
    dispatch(editBinanceAccountRequest(values, accountId, onCloseModal, onError));
  };

  const handleDeleteAcc = (account: BinanceAccountShape) => {
    localDispatch({
      type: binanceAccountsTypes.DELETE_INIT,
      payload: account
    });
  };

  const onDeleteAccError = (error: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(error);

    localDispatch({
      type: binanceAccountsTypes.DELETE_ERROR,
      payload: errorMsg
    });
  };

  const handleSubmitDelete = () => {
    dispatch(deleteBinanceAccountRequest(currentAccount?.id, onCloseModal, onDeleteAccError));
  };

  const onCloseModal = () => {
    localDispatch({
      type: binanceAccountsTypes.CLOSE_MODAL
    });
  };

  const onStopLoading = () => {
    localDispatch({
      type: binanceAccountsTypes.STOP_LOADING
    });
  };

  const onGetAccountsList = useCallback(() => {
    dispatch(getBinanceAccounts(onStopLoading));
  }, [dispatch]);

  useEffect(() => {
    onGetAccountsList();
  }, [onGetAccountsList]);

  return (
    <div className={s.wrapper}>
      <h2 className={cn(s.title, 'default-title')}>Binance accounts</h2>

      <div className={s.listWrapper}>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={isLoading ? 'loader' : 'collection'}
            timeout={DEFAULT_TRANSITION_TIMEOUT}
            classNames={{ ...Fade }}
          >
            {isLoading ? (
              <div className={s.loaderWrapper}>
                <PuffLoader size={LOADER_SIZE} loading={isLoading} />
              </div>
            ) : isEmpty(binanceAccounts) ? (
              <p className="default-text">No accounts yet</p>
            ) : (
              <CustomizableList
                isReadOnly={isReadOnly}
                isEditable
                list={binanceAccounts}
                onEdit={handleEditAcc}
                onDelete={handleDeleteAcc}
              />
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>

      {!isReadOnly && (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          classes={{ root: s.btnRoot, text: s.btnText }}
          onClick={handleAddAccount}
        >
          Add
        </Button>
      )}

      <Modal
        isOpen={isOpen}
        onToggle={onCloseModal}
        stylesProps={{
          paper: { padding: '40px 50px 50px', top: '43%' }
        }}
      >
        {isDelete ? (
          <DeleteAccountContent
            onCancel={onCloseModal}
            onSubmit={handleSubmitDelete}
            deleteError={deleteError}
            accountLabel={currentAccount?.title}
          />
        ) : (
          <BinanceAccountForm
            onCancel={onCloseModal}
            onSubmit={isEdit ? handleSubmitEdit : handleSubmitAdd}
            isEdit={isEdit}
            account={currentAccount}
          />
        )}
      </Modal>
    </div>
  );
};

export default BinanceAccounts;
