export interface PrivilegesReducer {
  groups: Array<GroupsShape>;
  users: Array<UsersShape>;
}

export interface UsersShape {
  email: string;
  group: GroupsShape | null;
  id: number;
  is_active: boolean;
}

export interface GroupsShape {
  id: number;
  name: string;
  perms: Array<Permission>;
}

export enum Status {
  NP = 'NP',
  FULL = 'Full',
  LIMITED = 'Limited',
  READ_ONLY = 'ReadOnly'
}

export enum PrivilegesGroupFields {
  NAME = 'name',
  BINANCE_ACCOUNT = 'binance_accounts',
  PAIRS = 'pairs',
  TG_BOT = 'tg_bot',
  LOGS = 'logs',
  NOTIFICATIONS = 'notifications',
  SIGNALS = 'signals',
  APA = 'position_administration',
  RISK_CONTROL = 'risk_control',
  SIGNALS_EXPORT = 'signals_export',
  ORDERS = 'orders',
  PROFILES_STATS = 'profiles_stats',
  PROFILES_STATS_EXPORT = 'profiles_stats_export',
  MM_PROFILES = 'mm_profiles',
  PROFILES = 'profiles',
  PROFILE_SETTINGS = 'profile_settings',
  BOT_STATUS = 'bot_status',
  GROUPS = 'groups',
  USERS = 'users',
  ACCOUNT_STATS = 'account_stats',
  ACCOUNT_STATS_EXPORT = 'account_stats_export',
  STATS_BY_MONTHS = 'stat_by_months',
  TICKERS_CONFIGS = 'tickers_configs',
  CHARTS = 'charts'
}

export interface Permission {
  section_name: PrivilegesGroupFields;
  status: Status;
  filtering?: boolean;
  sorting?: boolean;
  is_only_finished?: boolean;
  signals_count?: number;
  fields?: string[];
}

export interface GroupPermission {
  perms: Array<Permission>;
  name: string;
}

export interface UserRow {
  email: string;
  group: { id: number; name: string };
  id: number;
  is_active: boolean;
  index: number;
  action: string[];
}

export interface CreateUser {
  email: string;
  password: string;
  group: number;
}

export interface UpdateGroupPrivilegesWorker {
  type: string;
  payload: { id: number; onSuccess: () => void };
}

export interface DeleteGroupPrivilegesWorker {
  type: string;
  payload: { id: number; onSuccess: () => void; onError: (error) => void };
}

export interface RemoveGroupPrivilegesWorker {
  type: string;
  payload: { id: number };
}

export interface CreatePrivilegesGroupWorker {
  type: string;
  payload: { data: GroupPermission; onSuccess: () => void };
}

export interface GetGroupsShape {
  type: string;
  payload: { onFinally: () => void };
}

export interface SetGroups {
  type: string;
  payload: Array<GroupsShape>;
}

export interface UpdateStatus {
  type: string;
  payload: { name: string; status: string; id: number };
}

export interface UpdateGroupPrivileges {
  type: string;
  payload: { id: number; onSuccess: () => void };
}

export interface UpdateFieldsPrivileges {
  type: string;
  payload: { id: number; name: string; fields: string[] };
}

export interface UpdateFilteringFieldsPrivileges {
  type: string;
  payload: { id: number; name: string; filtering: boolean };
}

export interface UpdateSortingFieldsPrivileges {
  type: string;
  payload: { id: number; name: string; sorting: boolean };
}

export interface UpdateFinishedOnlyFieldsPrivileges {
  type: string;
  payload: { id: number; name: string; finishedOnly: boolean };
}

export interface UpdateSignalsCountFieldsPrivileges {
  type: string;
  payload: { id: number; name: string; signalsCount: number };
}

export interface GetUsers {
  type: string;
  payload: { onFinally: () => void };
}

export interface CreateGroup {
  type: string;
  payload: { data: GroupPermission; onSuccess: () => void };
}

export interface ChangeGroupShape {
  type: string;
  payload: { id: number; groupName: string; groupId: number };
}

export interface SaveUserShape {
  type: string;
  payload: { id: number; onSuccess: () => void; onError: (err) => void };
}

export interface SaveUserSuccessShape {
  type: string;
  payload: { id: number };
}

export interface CreateUserShape {
  type: string;
  payload: { email: string; password: string; group: number; onSuccess: () => void; onError: (err) => void };
}
