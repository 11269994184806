import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import cn from 'classnames';
import MainLayout from 'components/mainLayout/MainLayout';
import Modal from 'components/modal/MyModal';
import MMProfilesAddForm from './MMProfilesAddForm';
import PairedBtnBlock from 'components/buttons/pairedBtnBlock/PairedBtnBlock';
import { layoutRootSelector, mmProfilesRootSelector } from 'redux/selectors';
import { getBinanceAccounts } from 'redux/actions/systemSettingsActions';
import { addMMProfile, changeMMProfile, getMMProfile } from 'redux/actions/mmProfilesActions';
import { MMProfilesAddFields, MMProfilesAddFormValues, validationSchema } from 'constants/mmProfilesConstants';
import { PAGES } from 'constants/routes';
import { getErrorCutMessageHelper, getErrorMessageHelper, getModalContentIndent } from 'helpers/randomHelpers';
import { safeParseInt } from 'helpers/numberHelpers';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import s from './MMProfilesAddPage.module.scss';
import { MMStatuses } from 'interfaces/mmProfilesShapes';

const MMProfilesManagePage = (): JSX.Element => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: string }>();
  const { mmProfiles } = useSelector(mmProfilesRootSelector);
  const isEditPage = useMemo(() => Boolean(id), [id]);
  const profile = useMemo(() => mmProfiles.find((prof) => prof.id === +id), [id, mmProfiles]);

  useEffect(() => {
    dispatch(getBinanceAccounts());
  }, [dispatch]);

  useEffect(() => {
    if (isEditPage && !mmProfiles.length) {
      dispatch(getMMProfile(+id, () => onBackToProfiles()));
    }
  }, [id]);

  const onBackToProfiles = () => push(PAGES.MM_PROFILES);

  const [reqErr, setReqErr] = useState<string | null>(null);
  const onClearRequestError = () => setReqErr(null);

  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const onAddProfileSuccess = (callback) => {
    onToggleModal();
    callback();
  };

  const onUpdateProfileSuccess = (callback) => {
    callback();
    onBackToProfiles();
  };

  const { isAsideOpen } = useSelector(layoutRootSelector);
  const [isModalOpen, setModalOpen] = useState(false);
  const onToggleModal = () => setModalOpen((prev) => !prev);
  const modalContentIndent = getModalContentIndent(isAsideOpen);

  if (isEditPage && profile?.status !== MMStatuses.INACTIVE && mmProfiles.length) {
    onBackToProfiles();
  }

  return (
    <MainLayout>
      <div className={s.content}>
        <div className={s.formWrapper}>
          <div className={s.titleWrapper}>
            <h2 className="default-title">
              {isEditPage ? 'Update Money Management profile' : 'Add new Money Management profile'}
            </h2>
            {reqErr && <span className={cn(s.error, 'errorText')}>{reqErr}</span>}
          </div>

          <Formik
            enableReinitialize
            initialValues={{
              [MMProfilesAddFields.TITLE]: isEditPage ? profile?.title || '' : '',
              [MMProfilesAddFields.QUOTA]: isEditPage ? profile?.all_signals_quota || '' : '',
              [MMProfilesAddFields.MAX_AMOUNT]: isEditPage ? String(profile?.max_signals_amount) || '' : '',
              [MMProfilesAddFields.EXCHANGE_ACC]: isEditPage ? String(profile?.exchange_account?.id) || '' : '',
              [MMProfilesAddFields.MAX_FACTOR]: isEditPage ? String(profile?.max_factor_amount) || '' : ''
            }}
            validationSchema={validationSchema}
            onSubmit={(
              { exchange_account, max_signals_amount, max_factor_amount, ...rest }: MMProfilesAddFormValues,
              { setSubmitting, resetForm }: FormikHelpers<MMProfilesAddFormValues>
            ) => {
              const reqData = {
                ...rest,
                exchange_account: safeParseInt(exchange_account),
                max_signals_amount: safeParseInt(max_signals_amount),
                max_factor_amount: safeParseInt(max_factor_amount)
              };
              isEditPage
                ? dispatch(changeMMProfile(+id, reqData, onSetError, () => onUpdateProfileSuccess(resetForm)))
                : dispatch(addMMProfile(reqData, () => onAddProfileSuccess(resetForm), onSetError));
              setSubmitting(false);
            }}
          >
            <MMProfilesAddForm
              onClearRequestError={onClearRequestError}
              reqErr={reqErr}
              onBackToProfiles={onBackToProfiles}
            />
          </Formik>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onToggle={onToggleModal}
        paperCustomClass={s.modalContent}
        stylesProps={{
          paper: {
            left: `calc(50% + ${modalContentIndent}px)`,
            padding: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }
        }}
      >
        <h2 className={cn(s.modalTitle, 'default-title')}>Money Management profile was successfully created.</h2>
        <PairedBtnBlock
          colors={{ cancelColor: 'default', submitColor: 'default' }}
          onCancel={onBackToProfiles}
          onSubmit={onToggleModal}
          labels={{ cancelLabel: 'Back to profiles', submitLabel: 'Stay on the page' }}
          btnTypeSubmit="button"
        />
      </Modal>
    </MainLayout>
  );
};

export default MMProfilesManagePage;
