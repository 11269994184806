import { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from 'constants/apiEndpoints';
import * as Shapes from 'interfaces/mmProfilesShapes';
import { AxiosInstance as axios } from '../../../helpers/authHelpers';

export const addMMProfileHelper = (reqData: Shapes.AddMMProfileReqShape): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.MM_PROFILES, reqData);
};

export const getMMProfilesHelper = (show_hide: boolean): Promise<AxiosResponse<Array<Shapes.MMExtendedShape>>> => {
  return axios.get(API_ENDPOINTS.MM_PROFILES, { params: { show_hide } });
};

export const getMMProfileHelper = (profileId: number): Promise<AxiosResponse<Array<Shapes.MMExtendedShape>>> => {
  return axios.get(API_ENDPOINTS.MM_PROFILE(profileId));
};

export const changeMMProfileHelper = (
  profileId: number,
  reqData: Shapes.ChangeMMProfileReqShape
): Promise<AxiosResponse> => {
  return axios.put(API_ENDPOINTS.MM_PROFILE(profileId), reqData);
};

export const changeDepositMMProfileHelper = (reqData: Shapes.ChangedDepositFormDTO): Promise<AxiosResponse> => {
  return axios.post(API_ENDPOINTS.MM_PROFILES_DEPOSIT, reqData);
};
