import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelSignal, exportSignals, getFilters, getSignals } from 'redux/actions/signalsOrdersActions';
import { filterSelector, getComponentsFieldsSelector, signalsSelector } from 'redux/selectors';
import CustomizedTable from 'components/customizedTable/CustomizedTable';
import { createSignalsRow, hideColumnsStrategy, signalsRenderSwitch } from 'helpers/signalOrdersHelpers';
import { getErrorCutMessageHelper, getErrorMessageHelper } from 'helpers/randomHelpers';
import { callbackResolver } from 'helpers/callbackHelpers';
import { HARD_PER_PAGE, PaginationContext, ZERO_BASED_PAGE_SHIFT } from 'constants/randomConstants';
import {
  DEFAULT_SIGNALS_PARAMS,
  FilterPerPageLabels,
  HIDE_SIGNAL_COLUMNS_FOR_STRATEGY,
  initialSignalValues,
  InitialSignalValuesType,
  READ_ONLY_FORBIDDEN_FIELDS,
  SERVER_CLIENT_COLS_MAP,
  SIGNALS_COLUMNS,
  SIGNALS_TABLE_COL_NAMES,
  SignalsColIds,
  SortingSignalValues
} from 'constants/signalsOrdersConstants';
import { SignalShape, SignalTableRow } from 'interfaces/signalsOrdersShapes';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import s from './SignalsTable.module.scss';
import { removeEmptyParams } from 'helpers/emptyParamsRemoverHelpers';
import SignalsFilters from './SignalsFilters';
import { usePrivileges } from '../../hooks/usePrivileges';
import { isSectionAllowed, isSectionFull, isSectionLimited, isSectionReadOnly } from '../../helpers/privilegesHelpers';
import { PrivilegesGroupFields } from '../../interfaces/privilegesShapes';
import Button from '@material-ui/core/Button';
import PuffLoader from 'react-spinners/PuffLoader';
import { FIELD_DEPENDENCIES_MAP, PrivilegesColIds } from '../../constants/privilegesConstants';
import cn from 'classnames';
import { TableColumn } from '../../interfaces/randomShapes';

interface SignalsTableProps {
  profileId?: string;
}

const SignalsTable: React.FC<SignalsTableProps> = ({ profileId }) => {
  const dispatch = useDispatch();
  const { count, results } = useSelector(signalsSelector);
  const { profiles, pairs } = useSelector(filterSelector);
  const { signals, signals_export, logs } = usePrivileges();
  const { fields, filtering, sorting, is_only_finished } = useSelector(
    getComponentsFieldsSelector(PrivilegesGroupFields.SIGNALS)
  );
  const [reqParams, setReqParams] = useState(
    profileId ? { ...DEFAULT_SIGNALS_PARAMS, profile: profileId } : DEFAULT_SIGNALS_PARAMS
  );

  const [reqError, setReqErr] = useState<null | string>(null);

  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const [isLoading, setLoading] = useState(false);
  const [isLoadingExport, setLoadingExport] = useState(false);

  const [tablePerPage, setTablePerPage] = useState(HARD_PER_PAGE);

  const onToggleLoading = () => setLoading((prev) => !prev);
  const onToggleLoadingExport = () => setLoadingExport((prev) => !prev);

  const onChangePage = useCallback((newPage: number) => {
    setReqParams((prev) => ({ ...prev, page: newPage + ZERO_BASED_PAGE_SHIFT }));
  }, []);

  const onGetFilters = useCallback(() => {
    dispatch(getFilters(() => null, onSetError));
  }, [dispatch]);

  useEffect(onGetFilters, [onGetFilters]);

  const onGetSignals = useCallback(
    (callback?: () => void) => {
      onToggleLoading();
      dispatch(
        getSignals(
          removeEmptyParams(reqParams),
          () => {
            setTablePerPage(+reqParams?.page_size || HARD_PER_PAGE);
            onToggleLoading();
            callbackResolver(callback);
          },
          onSetError
        )
      );
    },
    [dispatch, reqParams]
  );

  const onCancel = useCallback(
    (id: number) => {
      onToggleLoading();
      dispatch(
        cancelSignal(
          id,
          () => {
            onToggleLoading();
            onGetSignals();
          },
          (err) => {
            onSetError(err);
            onToggleLoading();
          }
        )
      );
    },
    [dispatch, onGetSignals]
  );

  const onAction = useCallback(
    (colId: SignalsColIds, id: number) => {
      switch (colId) {
        case SignalsColIds.CANCEL:
          onCancel(id);
          break;

        default:
          return null;
      }
    },
    [onCancel]
  );

  const getContent = useCallback(
    (colId, row: SignalTableRow) =>
      signalsRenderSwitch(colId, row, isSectionLimited(signals), onAction, { logPage: isSectionAllowed(logs) }),
    [logs, onAction, signals]
  );

  const rows = useMemo(() => results.map((item: SignalShape) => createSignalsRow(item)), [results]);
  const columns = useMemo(
    () =>
      profileId
        ? hideColumnsStrategy(SIGNALS_COLUMNS(Boolean(profileId)), HIDE_SIGNAL_COLUMNS_FOR_STRATEGY)
        : SIGNALS_COLUMNS(Boolean(profileId)),
    [profileId]
  );
  useEffect(onGetSignals, [onGetSignals]);

  const [filterParams, setFilterParams] = useState<InitialSignalValuesType>(initialSignalValues);
  const [disabledFilter, setDisabledFilter] = useState(true);

  const onMakeSortingOrdering = (value: string, hasMinusSign: boolean) => {
    const currentSortingPick = hasMinusSign ? `-${SortingSignalValues[value]}` : SortingSignalValues[value];
    const isNewFirst = value === SIGNALS_TABLE_COL_NAMES[SignalsColIds.DATE] ? '' : ',-created_at';
    const isActiveFirst = value === SIGNALS_TABLE_COL_NAMES[SignalsColIds.STRATEGY_PROFILE] ? 'status,' : '';
    return `${isActiveFirst}${currentSortingPick}${isNewFirst}`;
  };

  const sortHandler = useCallback(
    (value: string, withMinusSign: boolean) => {
      const params = {
        ...filterParams,
        ...(profileId && { profile: profileId }),
        page: 1,
        ordering: onMakeSortingOrdering(value, withMinusSign)
      };
      setReqParams(params);
      setDisabledFilter(true);
    },
    [filterParams, profileId]
  );

  const fieldsDeps = FIELD_DEPENDENCIES_MAP[PrivilegesColIds.SIGNALS];

  const filteredColumns = useMemo(() => {
    if (isSectionLimited(signals)) {
      return columns.filter((col: TableColumn) => {
        return fields.some((field: string) => {
          if (fieldsDeps.indexOf(col.id) <= 0) {
            return field === SERVER_CLIENT_COLS_MAP[col.id];
          } else {
            return fieldsDeps.some((dep) => fields.indexOf(dep) < 0);
          }
        });
      });
    }
    if (!isSectionLimited(signals) && isSectionReadOnly(signals)) {
      return columns.filter((col) => READ_ONLY_FORBIDDEN_FIELDS.indexOf(SERVER_CLIENT_COLS_MAP[col.id]) < 0);
    }
    return columns;
  }, [signals, columns, fields, fieldsDeps]);

  const isHideFilters = useMemo(() => {
    return isSectionLimited(signals) && !filtering;
  }, [filtering, signals]);

  const handleExport = () => {
    onToggleLoadingExport();
    dispatch(exportSignals(onToggleLoadingExport, onSetError));
  };

  const handleFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof filterParams;
    const updatedFilterParams = {
      ...filterParams,
      ...(profileId && { profile: profileId }),
      page: 1,
      [name]: event.target.value
    };
    setFilterParams(updatedFilterParams);
    setReqParams(updatedFilterParams);
  };

  return (
    <div className={cn(s.tableWrapper, { [s.frameSpacer]: !!profileId })}>
      <div className={s.menu}>
        {!isHideFilters && (
          <SignalsFilters
            filterParams={filterParams}
            disabledFilter={disabledFilter}
            setDisabledFilter={setDisabledFilter}
            setReqParams={setReqParams}
            setFilterParams={setFilterParams}
            reqParams={reqParams}
            profiles={profiles}
            pairs={pairs}
            profileId={profileId}
          />
        )}
        {isSectionFull(signals_export) && (
          <div className={s.btnContainer}>
            <Button
              variant="outlined"
              size="medium"
              type="button"
              classes={{ root: s.btn }}
              onClick={handleExport}
              disabled={isLoading}
            >
              {isLoadingExport ? <PuffLoader size={24} /> : 'Export'}
            </Button>
          </div>
        )}
      </div>
      <PaginationContext.Provider
        value={{
          value: filterParams.page_size,
          options: Object.values(FilterPerPageLabels),
          onChange: handleFilterChange,
          isLoading
        }}
      >
        <CustomizedTable
          count={count}
          columns={filteredColumns}
          rows={filteredColumns.length ? rows : []}
          isLoading={isLoading}
          error={reqError}
          emptyRowsMsg="There are no signals yet..."
          getContent={getContent}
          getCollapsibleContent={() => null}
          rowsPerPageOptions={[tablePerPage]}
          customPerPage={tablePerPage}
          customPage={reqParams.page - ZERO_BASED_PAGE_SHIFT}
          onCustomChangePage={onChangePage}
          sortHandler={sortHandler}
          hasSortingOption={sorting}
          hidePagination={is_only_finished}
          offPerPage
          isPaginationPerPage={!isHideFilters}
          classes={{
            tableWrapper: cn({
              [s.tableContainer]: !is_only_finished,
              [s.tableContainerWithoutPagination]: is_only_finished
            }),
            wrapperClass: s.signalsContent,
            tHeadClasses: {
              tHeadCellClass: s.tHeadCellClass
            },
            tBodyClasses: {
              tBodyCellClass: s.tBodyCellClass
            }
          }}
        />
      </PaginationContext.Provider>
    </div>
  );
};

export default SignalsTable;
