import React, { FC, useMemo } from 'react';
import s from '../../../riskControlTable/profileMultiselect/ProfileMultiselect.module.scss';
import { Select, Checkbox, FormControl, ListItemText, MenuItem } from '@material-ui/core';
import { AllHideEnum } from '../../../../interfaces/strategyProfilesShapes';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

interface TradingPairsSelectProps {
  values: number[] | string[];
  options: { value; label: string }[];
  handleUpdateValue: (values: number[] | string[]) => void;
}

const TradingPairsSelect: FC<TradingPairsSelectProps> = ({ values, options, handleUpdateValue }) => {
  const onSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const isAll = (event.target.value as unknown[]).some((pair) => pair === AllHideEnum.ALL);
    const isHideAll = (event.target.value as unknown[]).some((pair) => pair === AllHideEnum.HIDE);
    if (isAll) {
      handleUpdateValue(options.map((pair) => pair.value));
    }
    if (isHideAll) {
      handleUpdateValue([]);
    }
    if (!isAll && !isHideAll) {
      handleUpdateValue(event.target.value as number[]);
    }
  };

  const flippedSelectedPairs = useMemo(() => {
    return options.reduce((acc, { value, label }) => {
      return { ...acc, [value]: label };
    }, {});
  }, [options]);

  const onRenderValue = (selected: string[]) => {
    return selected.map((el) => flippedSelectedPairs[el]).join(', ');
  };

  return (
    <FormControl variant="outlined">
      <Select
        labelId="pairs"
        id="pairs"
        name="pairs"
        value={values}
        onChange={onSelectChange}
        MenuProps={MenuProps}
        multiple
        renderValue={(selected) => onRenderValue(selected as string[])}
        className={s.select}
        placeholder="Select pairs"
      >
        <MenuItem value={values.length === options.length ? AllHideEnum.HIDE : AllHideEnum.ALL}>
          <Checkbox checked={values.length === options.length} />
          <ListItemText primary={values.length === options.length ? AllHideEnum.HIDE : AllHideEnum.ALL} />
        </MenuItem>
        {options.map((pair) => (
          <MenuItem key={pair.value} value={pair.value}>
            <Checkbox checked={values.indexOf(pair.value as never) >= 0} />
            <ListItemText primary={pair.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TradingPairsSelect;
