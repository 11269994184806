import React from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import { formatISODate } from 'helpers/randomHelpers';
import { NotificationShape } from 'interfaces/notificationShapes';
import PuffLoader from 'react-spinners/PuffLoader';
import s from './NotificationsList.module.scss';

export interface ListCssClasses {
  listWrapper?: string;
  listItem?: string;
  listClass?: string;
}

interface NotificationsListProps {
  list: Array<NotificationShape>;
  onDelete: (id: number) => void;
  isBottomLoader: boolean;
  error: null | string;
  classes?: ListCssClasses;
}

const SCROLL_LOADER = 60;

const NotificationsList = ({
  list,
  isBottomLoader,
  error,
  onDelete,
  classes
}: NotificationsListProps): React.ReactElement => {
  return (
    <div className={cn(s.rootWrapper, { [classes?.listWrapper]: classes?.listWrapper })}>
      {!isEmpty(list) ? (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} className={s.listInnerWrapper}>
            <List
              classes={{
                root: cn({
                  [classes?.listClass]: classes?.listClass
                })
              }}
            >
              {list.map(({ id, title, text_body, created_at }) => (
                <ListItem key={id} classes={{ root: cn(s.listItemRoot, { [classes?.listItem]: classes?.listItem }) }}>
                  <div className={s.listItemInfoWrapper}>
                    <h3 className={cn('default-title', s.title)}>{title}</h3>
                    <p className={cn('default-text', s.text)}>{text_body}</p>
                    <span className={cn('default-title', s.time)}>{formatISODate(created_at)}</span>
                  </div>
                  {/* COMMENTED REMOVE BTN - 14.12.2022 - delete flow if no need for a while */}
                  {/*<Button*/}
                  {/*  fullWidth*/}
                  {/*  variant="outlined"*/}
                  {/*  size="medium"*/}
                  {/*  type="button"*/}
                  {/*  color="secondary"*/}
                  {/*  classes={{ root: s.btnRoot }}*/}
                  {/*  onClick={() => onDelete(id)}*/}
                  {/*>*/}
                  {/*  Remove*/}
                  {/*</Button>*/}
                </ListItem>
              ))}
            </List>
            {!error && isBottomLoader && (
              <div className={s.loaderWrapper}>
                <PuffLoader size={SCROLL_LOADER} />
              </div>
            )}
            {error && <span className={cn('errorText', s.error)}>{error}</span>}
          </Grid>
        </Grid>
      ) : (
        <h2 className="default-title">No notifications yet...</h2>
      )}
    </div>
  );
};

export default NotificationsList;
