import React, { FC, useEffect, useRef } from 'react';
import { Alert } from '@material-ui/lab';
import s from './Message.module.scss';
import { hideMessage } from '../../redux/actions/utilsActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { messageSelector } from '../../redux/selectors';

interface MessageProps {
  isError?: boolean;
  message?: string;
}
const TIMER = 3000;

const Message: FC<MessageProps> = () => {
  const dispatch = useDispatch();
  const timer = useRef<NodeJS.Timer | null>(null);
  const { messageText, type, messageId } = useSelector(messageSelector, shallowEqual);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      dispatch(hideMessage());
    }, TIMER);
    return () => {
      clearTimeout(timer.current);
    };
  }, [dispatch, messageId]);

  return (
    <Alert severity={type} className={s.message}>
      {messageText}
    </Alert>
  );
};

export default Message;
