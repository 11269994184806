import { call, put, takeLeading, ForkEffect } from 'redux-saga/effects';
import { notificationTypes } from '../types';
import * as apiHelpers from './apiHelpers/notificationsApiHelpers';
import * as actions from '../actions/notificationActions';
import * as Shapes from 'interfaces/notificationShapes';
import { callbackResolver } from 'helpers/callbackHelpers';

function* getNotificationsWorker({ payload }: Shapes.GetNotifications) {
  try {
    const { data } = yield call(apiHelpers.getNotificationsHelper, payload.params);
    yield put(actions.putNotifications(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
  }
}

function* removeNotificationWorker({ payload }: Shapes.RemoveNotification) {
  try {
    yield call(apiHelpers.removeNotificationHelper, payload.id);
    yield put(actions.removeNotificationLocally(payload.id));
  } catch (error) {
    payload.onError(error);
  }
}

export function* notificationsWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLeading(notificationTypes.GET_NOTIFICATIONS, getNotificationsWorker);
  yield takeLeading(notificationTypes.REMOVE_NOTIFICATION, removeNotificationWorker);
}
