export interface StrategyDetailsState {
  isLoading: boolean;
  errors: { profileError: null | string };
  currentTab: number;
}

export enum StrategyDetailsTabs {
  STATISTICS = 0,
  ORDERS = 1,
  SIGNALS = 2,
  SETTINGS = 3
}
