import React from 'react';
import cn from 'classnames';
import PairedBtnBlock from 'components/buttons/pairedBtnBlock/PairedBtnBlock';
import s from './DeleteAccountContent.module.scss';

interface DeleteAccountContentProps {
  onCancel: () => void;
  onSubmit: () => void;
  deleteError: null | string;
  accountLabel: string;
}

const DeleteAccountContent = ({
  onCancel,
  onSubmit,
  deleteError,
  accountLabel
}: DeleteAccountContentProps): JSX.Element => {
  return (
    <div className={s.contentWrapper}>
      <div className={s.titleWrapper}>
        <h2 className={cn(s.title, 'default-text')}>Are you sure you want to delete?</h2>
        <p className={cn(s.label, 'default-title')}>Binance account:&nbsp;{accountLabel}</p>
        {deleteError && <span className={cn(s.error, 'errorText')}>{deleteError}</span>}
      </div>
      <PairedBtnBlock
        onCancel={onCancel}
        onSubmit={onSubmit}
        labels={{ cancelLabel: 'Cancel', submitLabel: 'Delete' }}
      />
    </div>
  );
};

export default DeleteAccountContent;
