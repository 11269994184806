import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeDepositMMProfile,
  changeMMProfile,
  changeMMProfileVisibility,
  getMMProfiles
} from 'redux/actions/mmProfilesActions';
import MainLayout from 'components/mainLayout/MainLayout';
import CustomizedTable from 'components/customizedTable/CustomizedTable';
import Button from '@material-ui/core/Button';
import { getComponentsFieldsSelector, mmProfilesRootSelector } from 'redux/selectors';
import { ChangedDepositFormDTO, MMExtendedShape, MMStatuses } from 'interfaces/mmProfilesShapes';
import {
  MM_PROFILES_COLUMNS,
  MMTableColIds,
  MMTableRow,
  READ_ONLY_FORBIDDEN_FIELDS,
  SERVER_CLIENT_COLS_MAP
} from 'constants/mmProfilesConstants';
import { HARD_PER_PAGE } from 'constants/randomConstants';
import { getErrorCutMessageHelper, getErrorMessageHelper } from 'helpers/randomHelpers';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import { createRow, profileRenderSwitch } from 'helpers/mmProfilesHelpers';
import { PAGES } from 'constants/routes';
import s from './MMProfilesPage.module.scss';
import { usePrivileges } from '../../hooks/usePrivileges';
import { isSectionLimited, isSectionFull, isSectionReadOnly } from '../../helpers/privilegesHelpers';
import { PrivilegesGroupFields } from '../../interfaces/privilegesShapes';
import { Tooltip } from '@material-ui/core';
import MyModal from '../../components/modal/MyModal';
import ChangeDepositForm from './changeDepositeForm/ChangeDepositeForm';

const MMProfilesPage = (): JSX.Element => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { mmProfiles } = useSelector(mmProfilesRootSelector);

  const { mm_profiles } = usePrivileges();
  const { fields } = useSelector(getComponentsFieldsSelector(PrivilegesGroupFields.MM_PROFILES));

  const showChangeDeposit = useMemo(() => {
    return isSectionLimited(mm_profiles) ? fields.some((el) => el === 'change_deposit') : true;
  }, [fields, mm_profiles]);

  const [isModalOpen, setModalOpen] = useState(false);
  const onToggleModal = () => setModalOpen((prev) => !prev);

  const hasVisibilityField = useMemo(() => {
    return (
      (isSectionLimited(mm_profiles) && fields.indexOf(SERVER_CLIENT_COLS_MAP[MMTableColIds.VISIBILITY]) >= 0) ||
      isSectionFull(mm_profiles)
    );
  }, [mm_profiles, fields]);

  const [isLoading, setLoading] = useState(false);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const [reqError, setReqErr] = useState<null | string>(null);

  const onClearRequestError = () => setReqErr(null);

  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const [showHidden, setShowHidden] = useState(false);

  const onGetMMProfile = useCallback(() => {
    onToggleLoading();
    dispatch(
      getMMProfiles(showHidden, onToggleLoading, (err) => {
        onSetError(err);
        onToggleLoading();
      })
    );
  }, [dispatch, showHidden]);

  useEffect(onGetMMProfile, [onGetMMProfile]);

  const onChangeProfile = useCallback(
    (rawData: MMExtendedShape) => {
      const { title, status, all_signals_quota, max_signals_amount, exchange_account, is_show } = rawData;

      const reqData = {
        title,
        status: status === MMStatuses.INACTIVE ? MMStatuses.ACTIVE : MMStatuses.STOPPING,
        all_signals_quota,
        max_signals_amount,
        exchange_account: exchange_account?.id ?? null,
        is_show
      };

      dispatch(changeMMProfile(rawData.id, reqData, onSetError));
    },
    [dispatch]
  );

  const rows = useMemo(() => mmProfiles.map((item: MMExtendedShape) => createRow(item)), [mmProfiles]);

  const getCollapsibleContent = (colId: string, { profiles }: MMTableRow) => {
    if (colId !== MMTableColIds.PROFILES) return <></>;

    const shouldRender = !isEmpty(profiles?.profilesAdditional);
    return (
      shouldRender && (
        <div className={s.profileAddsWrapper}>
          {profiles.profilesAdditional.map(({ title, id }, idx, arr) => {
            const isLast = arr.length - 1 === idx;
            return (
              <span key={id} style={{ whiteSpace: 'nowrap' }}>
                {`${title}${!isLast ? ',' : ''}`}&nbsp;
              </span>
            );
          })}
        </div>
      )
    );
  };

  const onVisibilityClick = useCallback(
    (id: number, isShow: boolean) => {
      dispatch(changeMMProfileVisibility(id, isShow, onGetMMProfile));
    },
    [dispatch, onGetMMProfile]
  );

  const onHideItems = () => {
    setShowHidden((prev) => !prev);
  };

  const getContent = useCallback(
    (
      colId: string,
      row: MMTableRow,
      collapseCallback?: () => void,
      isCollapseOpen?: boolean,
      isCollapsible?: boolean
    ) =>
      profileRenderSwitch(
        colId,
        row,
        onChangeProfile,
        onVisibilityClick,
        isSectionReadOnly(mm_profiles),
        collapseCallback,
        isCollapseOpen,
        isCollapsible
      ),
    [mm_profiles, onChangeProfile, onVisibilityClick]
  );

  const filteredColumns = useMemo(() => {
    if (isSectionLimited(mm_profiles)) {
      return MM_PROFILES_COLUMNS.filter((col) => fields.indexOf(SERVER_CLIENT_COLS_MAP[col.id]) >= 0);
    }
    if (!isSectionLimited(mm_profiles) && isSectionReadOnly(mm_profiles)) {
      return MM_PROFILES_COLUMNS.filter(
        (col) => READ_ONLY_FORBIDDEN_FIELDS.indexOf(SERVER_CLIENT_COLS_MAP[col.id]) < 0
      );
    }
    return MM_PROFILES_COLUMNS;
  }, [mm_profiles, fields]);

  const handleChangeDeposit = useCallback(
    (reqData: ChangedDepositFormDTO, onError: (err: ErrorShape) => void) => {
      dispatch(
        changeDepositMMProfile(
          reqData,
          () => {
            onGetMMProfile();
            onToggleModal();
          },
          onError
        )
      );
    },
    [dispatch, onGetMMProfile]
  );

  return (
    <MainLayout contentCustomClass={s.contentWrapper}>
      <div className={s.titleWrapper}>
        <h2 className="default-title">Money management profiles list</h2>
        {!isSectionReadOnly(mm_profiles) && (
          <div className={s.btnsContainer}>
            {hasVisibilityField && (
              <Button
                fullWidth
                variant="outlined"
                size="medium"
                type="button"
                classes={{ root: s.btnRoot }}
                onClick={onHideItems}
              >
                {showHidden ? 'Hide Unpicked' : 'Show All'}
              </Button>
            )}
            <Button
              variant="outlined"
              size="medium"
              type="button"
              classes={{ root: s.btnRoot }}
              onClick={() => push(PAGES.MM_PROFILES_ADD)}
            >
              Add
            </Button>
            {showChangeDeposit && (
              <Tooltip
                title={
                  <div style={{ fontSize: '16px', padding: '4px', margin: '4px 0', lineHeight: '1.1em' }}>
                    Обновить all signals quota для всех активных ММ профилей
                  </div>
                }
              >
                <Button
                  variant="outlined"
                  size="medium"
                  type="button"
                  classes={{ root: s.changeDeposit }}
                  onClick={onToggleModal}
                >
                  Change Deposit
                </Button>
              </Tooltip>
            )}
          </div>
        )}
      </div>
      <div className={s.textHelper}>
        *Выключать ММ профиль при inactive Strategy profile или после выключения и включения ММ профиля, заново
        активировать Strategy profile
      </div>
      <CustomizedTable
        columns={filteredColumns}
        rows={rows}
        isLoading={isLoading}
        error={reqError}
        emptyRowsMsg="There are no money management profiles yet..."
        getContent={getContent}
        getCollapsibleContent={getCollapsibleContent}
        count={rows.length}
        customPerPage={HARD_PER_PAGE}
        rowsPerPageOptions={[HARD_PER_PAGE]}
        offPerPage
      />
      <MyModal isOpen={isModalOpen} onToggle={onToggleModal}>
        <ChangeDepositForm onCancel={onToggleModal} onSubmit={handleChangeDeposit} />
      </MyModal>
    </MainLayout>
  );
};

export default MMProfilesPage;
