import { RootShape } from 'interfaces/reduxRootShape';
import { AuthReducer } from 'interfaces/authShapes';
import { LayoutReducer } from 'interfaces/reduxRandomShapes';
import { SystemSettingsReducer } from 'interfaces/systemSettingsShapes';
import { MMProfilesReducer } from 'interfaces/mmProfilesShapes';
import {
  GetSignalsResponse,
  GetOrdersResponse,
  GetSignalsFilters,
  AdminShape,
  RiskControlShape,
  AdminTableData
} from 'interfaces/signalsOrdersShapes';
import { ChartStats, GetStrategyStatsFilters, GetStrategyStatsResponse } from 'interfaces/strategyStatsShapes';
import {
  StrategyProfilesReducer,
  StrategyProfilesData,
  PairsShape,
  StrategyProfilesShape
} from 'interfaces/strategyProfilesShapes';
import { NotificationsReducer } from 'interfaces/notificationShapes';
import { LogsReducer } from '../interfaces/logsShapes';
import { GroupsShape, Permission, PrivilegesGroupFields, UsersShape } from '../interfaces/privilegesShapes';
import { Message } from '../interfaces/utilsShapes';
import { AccountStatsShapes } from '../interfaces/accountStatsShapes';
import { ResultByMonths } from '../interfaces/monthsResultsShapes';

export const authSelector = (store: RootShape): AuthReducer => store?.auth;
export const authPermissions = (store: RootShape): Array<Permission> => store?.auth?.user?.group?.perms ?? [];
export const authAccessTokenSelector = (store: RootShape): string => store?.auth.accessToken;
export const authRefreshTokenSelector = (store: RootShape): string => store?.auth.refreshToken;
export const layoutRootSelector = (store: RootShape): LayoutReducer => store?.layout;
export const systemSettingsSelector = (store: RootShape): SystemSettingsReducer => store?.systemSettings;
export const mmProfilesRootSelector = (store: RootShape): MMProfilesReducer => store?.mmProfilesRoot;
export const signalsSelector = (store: RootShape): GetSignalsResponse => store?.signalsOrders?.signalsData;
export const ordersSelector = (store: RootShape): GetOrdersResponse => store?.signalsOrders?.ordersData;
export const administrationSelector = (store: RootShape): AdminShape[] => store?.signalsOrders?.adminData;
export const administrationListSelector =
  (id: number) =>
  (store: RootShape): AdminShape =>
    store?.signalsOrders?.adminData.find((el) => el.id === id);
export const administrationDataSelector = (store: RootShape): AdminTableData => store?.signalsOrders?.adminTableData;
export const riskControlSelector = (store: RootShape): RiskControlShape[] => store?.signalsOrders?.riskControl;
export const riskControlObjectSelector =
  (triggerId: number | null) =>
  (store: RootShape): RiskControlShape | null =>
    store?.signalsOrders?.riskControl.find((rc) => rc.id === triggerId) || null;

export const filterSelector = (store: RootShape): GetSignalsFilters => store?.signalsOrders?.filters;
export const groupsSelector = (store: RootShape): GroupsShape[] => store?.privileges.groups;
export const groupSelector = (store: RootShape, id: number): GroupsShape =>
  store?.privileges.groups.find((group) => group.id === id);
export const usersSelector = (store: RootShape): UsersShape[] => store?.privileges.users;
export const userGroupSelector =
  (id: number) =>
  (store: RootShape): GroupsShape =>
    store?.privileges.users.find((user) => user.id === id).group;

export const getFieldsSelector =
  (id: number, name: string) =>
  (store: RootShape): Permission => {
    const emptyValues = {
      fields: [],
      sorting: false,
      filtering: false
    } as Permission;
    if (!id || !name) {
      return emptyValues;
    }
    return (
      store?.privileges?.groups?.find((group) => group.id === id)?.perms?.find((perm) => perm.section_name === name) ||
      emptyValues
    );
  };

export const getComponentsFieldsSelector =
  (sectionName: PrivilegesGroupFields) =>
  (store: RootShape): Permission =>
    authPermissions(store)?.find((perm) => perm.section_name === sectionName) ||
    ({
      fields: [],
      filtering: false,
      sorting: false,
      is_only_finished: false
    } as Permission);

export const signalsStatsSelector = (store: RootShape): GetStrategyStatsResponse => store?.stats?.strategyStatsData;
export const accountStatsSelector = (store: RootShape): AccountStatsShapes[] => store?.stats?.accountStatsData;
export const resultsByMonthsStatsSelector = (store: RootShape): ResultByMonths => store?.stats?.resultByMonths;
export const logsSelector = (store: RootShape): LogsReducer => store?.logs;
export const strategyRootSelector = (store: RootShape): StrategyProfilesReducer => store?.strategyProfiles;
export const strategyListSelector = (store: RootShape): StrategyProfilesData => {
  return store?.strategyProfiles?.strategyProfilesData;
};
export const strategyPairsSelector = (store: RootShape): PairsShape[] => store?.strategyProfiles?.pairs;
export const strategyProfilesSelector = (store: RootShape): StrategyProfilesShape[] =>
  store?.strategyProfiles?.profiles;
export const strategyPairSelector =
  (id: number) =>
  (store: RootShape): PairsShape =>
    store?.strategyProfiles?.pairs.find((pair) => pair.id === id);
export const profileStateFilters = (store: RootShape): GetStrategyStatsFilters => store.stats.filters;
export const statsChartsSelector = (store: RootShape): { [chart: string]: ChartStats[] } => store.stats.charts;
export const statsChartsHasLoadedDataSelector = (store: RootShape): boolean =>
  Object.keys(store.stats.charts).length === 2;
export const notificationsRoot = (store: RootShape): NotificationsReducer => store?.notifications;
export const messageSelector = (store: RootShape): Message => store?.utils.message;
export const messageVisibilitySelector = (store: RootShape): boolean => store?.utils.message.showMessage;
