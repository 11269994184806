import { mmProfilesTypes } from '../types';
import * as Shapes from 'interfaces/mmProfilesShapes';
import { ChangedDepositFormDTO, ChangeDepositMMProfile } from 'interfaces/mmProfilesShapes';

export const addMMProfile = (
  reqData: Shapes.AddMMProfileReqShape,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.AddMMProfileShape => ({
  type: mmProfilesTypes.ADD_MM_PROFILE,
  payload: { reqData, onSuccess, onError }
});

export const getMMProfiles = (
  is_show: boolean,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.GetMMProfilesShape => ({
  type: mmProfilesTypes.GET_MM_PROFILES,
  payload: { reqData: is_show, onSuccess, onError }
});

export const putMMProfiles = (mmProfiles: Array<Shapes.MMExtendedShape>): Shapes.PutMMProfiles => ({
  type: mmProfilesTypes.PUT_MM_PROFILES,
  payload: mmProfiles
});

export const changeMMProfile = (
  profileId: number,
  reqData: Shapes.ChangeMMProfileReqShape,
  onError: (err) => void,
  onSuccess?: () => void
): Shapes.ChangeMMProfile => ({
  type: mmProfilesTypes.CHANGE_MM_PROFILE,
  payload: { profileId, reqData, onError, onSuccess }
});

export const changeMMProfileVisibility = (
  profileId: number,
  is_show: boolean,
  onSuccess: () => void
): Shapes.ChangeMMProfileVisibility => ({
  type: mmProfilesTypes.CHANGE_MM_PROFILE_VISIBILITY,
  payload: { profileId, reqData: { is_show }, onSuccess }
});

export const changeMMProfileSuccess = (updatedProfile: Shapes.MMExtendedShape): Shapes.ChangeMMProfileSuccess => ({
  type: mmProfilesTypes.CHANGE_MM_PROFILE_SUCCESS,
  payload: updatedProfile
});

export const getMMProfile = (id: number, onError: () => void): Shapes.GetMMProfile => ({
  type: mmProfilesTypes.GET_MM_PROFILE,
  payload: { id, onError }
});

export const changeDepositMMProfile = (
  reqData: ChangedDepositFormDTO,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.ChangeDepositMMProfile => ({
  type: mmProfilesTypes.CHANGE_DEPOSIT_MM_PROFILE,
  payload: { reqData, onSuccess, onError }
});
