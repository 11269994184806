import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import { Form, Formik } from 'formik';
import { Checkbox, FormControl, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import PairedBtnBlock from '../../../buttons/pairedBtnBlock/PairedBtnBlock';
import s from './AddPairListForm.module.scss';
import { PairFormValues, PairsListFormValues, PairsStatusShape } from '../../../../interfaces/strategyProfilesShapes';
import * as Yup from 'yup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

interface AddPairListFormProps {
  onSubmit: (values: PairFormValues) => void;
  onCancel: () => void;
  tradingPairsOptions: Array<{ value; label: string }>;
}

const AddPairListForm: FC<AddPairListFormProps> = ({ tradingPairsOptions, onSubmit, onCancel }) => {
  const flippedSelectedPairs = useMemo(() => {
    return tradingPairsOptions.reduce((acc, { value, label }) => {
      return { ...acc, [value]: label };
    }, {});
  }, [tradingPairsOptions]);

  const onRenderValue = (selected: string[]) => {
    return selected.map((el) => flippedSelectedPairs[el]).join(', ');
  };

  return (
    <div className={s.modal}>
      <h2 className={cn('default-title', s.modal__title)}>Add Pair List Form</h2>
      <Formik
        initialValues={{
          [PairsListFormValues.TITLE]: '',
          [PairsListFormValues.STATUS]: PairsStatusShape.INACTIVE,
          [PairsListFormValues.PAIRS]: []
        }}
        validationSchema={Yup.object({
          [PairsListFormValues.TITLE]: Yup.string().required('Type title for you list'),
          [PairsListFormValues.STATUS]: Yup.mixed()
            .oneOf([PairsStatusShape.ACTIVE, PairsStatusShape.INACTIVE])
            .required('This field is required'),
          [PairsListFormValues.PAIRS]: Yup.array()
        })}
        onSubmit={(values: PairFormValues) => {
          onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue }) => {
          return (
            <Form className={s.modal__form}>
              <TextField
                fullWidth
                id={PairsListFormValues.TITLE}
                name={PairsListFormValues.TITLE}
                label="Title"
                placeholder="Type title..."
                variant="outlined"
                value={values[PairsListFormValues.TITLE]}
                error={!!errors[PairsListFormValues.TITLE] && touched[PairsListFormValues.TITLE]}
                helperText={touched[PairsListFormValues.TITLE] && errors[PairsListFormValues.TITLE]}
                onBlur={handleBlur}
                onChange={handleChange}
                classes={{ root: s.input }}
              />
              <FormControl variant="outlined" className={s.modal__form__container}>
                <Select
                  labelId={PairsListFormValues.PAIRS}
                  id={PairsListFormValues.PAIRS}
                  name={PairsListFormValues.PAIRS}
                  value={values[PairsListFormValues.PAIRS]}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  multiple
                  renderValue={(selected) => onRenderValue(selected as string[])}
                  className={s.modal__form__select}
                  placeholder="Select pairs"
                  error={!!errors[PairsListFormValues.PAIRS] && touched[PairsListFormValues.PAIRS]}
                >
                  {tradingPairsOptions.map((pair) => (
                    <MenuItem key={pair.value} value={pair.value}>
                      <Checkbox checked={values[PairsListFormValues.PAIRS].indexOf(pair.value) >= 0} />
                      <ListItemText primary={pair.label} />
                    </MenuItem>
                  ))}
                </Select>
                {!!errors[PairsListFormValues.PAIRS] && touched[PairsListFormValues.PAIRS] && (
                  <FormHelperText error={true}>
                    {touched[PairsListFormValues.PAIRS] && errors[PairsListFormValues.PAIRS]}
                  </FormHelperText>
                )}
                <Button
                  fullWidth
                  variant="outlined"
                  size="medium"
                  type="button"
                  color="primary"
                  classes={{ root: s.btnRoot }}
                  onClick={() => {
                    setFieldValue(
                      PairsListFormValues.PAIRS,
                      values[PairsListFormValues.PAIRS].length === tradingPairsOptions.length
                        ? []
                        : tradingPairsOptions.map((pair) => pair.value)
                    );
                  }}
                >
                  {values[PairsListFormValues.PAIRS].length !== tradingPairsOptions.length ? 'All' : 'Hide'}
                </Button>
              </FormControl>
              <FormControl variant="outlined">
                <Select
                  labelId={PairsListFormValues.STATUS}
                  id={PairsListFormValues.STATUS}
                  name={PairsListFormValues.STATUS}
                  value={values[PairsListFormValues.STATUS]}
                  onChange={handleChange}
                  className={s.modal__form__select}
                  placeholder="Select pairs"
                >
                  <MenuItem value={PairsStatusShape.ACTIVE}>{PairsStatusShape.ACTIVE}</MenuItem>
                  <MenuItem value={PairsStatusShape.INACTIVE}>{PairsStatusShape.INACTIVE}</MenuItem>
                </Select>
              </FormControl>
              <PairedBtnBlock
                onCancel={onCancel}
                onSubmit={handleSubmit}
                labels={{ submitLabel: 'Save' }}
                btnTypeCancel="button"
                btnTypeSubmit="submit"
                disableCancel={isSubmitting}
                disableSubmit={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddPairListForm;
