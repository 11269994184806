import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import omitBy from 'lodash/omitBy';
import { getComponentsFieldsSelector, logsSelector } from 'redux/selectors';
import CustomizedTable from 'components/customizedTable/CustomizedTable';
import { getErrorCutMessageHelper, getErrorMessageHelper } from 'helpers/randomHelpers';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import { PaginationContext, ZERO_BASED_PAGE_SHIFT } from 'constants/randomConstants';
import { getLogs, getLogsFilters, getLogsCount } from '../../redux/actions/logsActions';
import {
  filterIDs,
  filterPerPageLabels,
  initialFilterValues,
  LOGS_COLUMNS,
  LOGS_FILTER,
  SERVER_CLIENT_COLS_MAP
} from '../../constants/logsConstants';
import { LogsTableRow } from '../../interfaces/logsShapes';
import { createLogsRow, logsPageRenderSwitch, createFiltersHelper } from '../../helpers/logsHelpers';
import s from './LogsTable.module.scss';
import { usePrivileges } from '../../hooks/usePrivileges';
import { LogsFilter } from './LogsFilter/LogsFilter';
import { isSectionLimited } from '../../helpers/privilegesHelpers';
import { PrivilegesGroupFields } from '../../interfaces/privilegesShapes';
import { useLocation } from 'react-router-dom';

const LogsTable = (): ReactElement => {
  const dispatch = useDispatch();
  const { count, results, filters } = useSelector(logsSelector);
  const { logs } = usePrivileges();
  const { state } = useLocation<{ signal?: string }>();
  const { filtering, fields } = useSelector(getComponentsFieldsSelector(PrivilegesGroupFields.LOGS));

  const areLimitedColumns = useMemo(() => isSectionLimited(logs), [logs]);

  const [reqParams, setReqParams] = useState(() => {
    return state?.signal ? { ...initialFilterValues, [filterIDs.SIGNAL]: state.signal } : initialFilterValues;
  });
  const [logsFilter, setLogsFilter] = useState(LOGS_FILTER);
  const [filterParams, setFilterParams] = useState(() => {
    return state?.signal ? { ...initialFilterValues, [filterIDs.SIGNAL]: state.signal } : initialFilterValues;
  });
  const [tablePerPage, setTablePerPage] = useState<number>(Number(initialFilterValues.page_size));
  const [disabledFilter, setDisabledFilter] = useState(true);

  const onChangePage = (newPage: number) => {
    setReqParams((prev) => ({ ...prev, page: newPage + ZERO_BASED_PAGE_SHIFT }));
  };

  const [isLoading, setLoading] = useState(false);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const [reqError, setReqErr] = useState<null | string>(null);
  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const onGetLogs = useCallback(() => {
    setTablePerPage(Number(reqParams.page_size));
    const reqData = omitBy(reqParams, (value) => !value);
    onToggleLoading();
    dispatch(
      getLogs(
        reqData,
        () => {
          onToggleLoading();
          dispatch(getLogsCount(reqData));
        },
        (err) => {
          onSetError(err);
          onToggleLoading();
        }
      )
    );
  }, [dispatch, reqParams]);

  const onGetFilters = useCallback(() => {
    dispatch(getLogsFilters(onSetError));
  }, [dispatch]);

  const createFilters = useCallback(() => {
    const customFilter = JSON.parse(JSON.stringify(LOGS_FILTER));
    if (filters) {
      createFiltersHelper(filters, customFilter);
      setLogsFilter(customFilter);
    }
  }, [filters]);

  useEffect(onGetLogs, [onGetLogs]);
  useEffect(onGetFilters, [onGetFilters]);
  useEffect(createFilters, [createFilters]);

  const getContent = (colId, row: LogsTableRow) => {
    return logsPageRenderSwitch(colId, row);
  };

  const rows = useMemo(
    () =>
      results.map((item) => {
        const logsRowData = {
          id: item.id,
          date: item.created_at,
          type: `${item?.type} : ${item?.event_type}`,
          pair_ticker: `${item?.pair_id} : ${item?.pair_name}`,
          profile_name: `${item?.profile_title} : ${item?.profile_id}`,
          signal_id: item.signal_id,
          order_id: item?.order_id,
          log_data: item?.log_data
        };
        return createLogsRow(logsRowData);
      }),
    [results]
  );

  const filterHandler = (withApplyAction: boolean) => (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof filterParams;
    const updatedFilterParams = {
      ...filterParams,
      page: 1,
      [name]: event.target.value
    };
    setFilterParams(updatedFilterParams);
    if (withApplyAction) {
      setDisabledFilter(JSON.stringify(updatedFilterParams) === JSON.stringify(reqParams));
    } else {
      setReqParams(updatedFilterParams);
    }
  };

  const handleFilterChange = filterHandler(true);
  const handleFilterChangeAndCall = filterHandler(false);

  const filteredColumns = useMemo(() => {
    return areLimitedColumns
      ? LOGS_COLUMNS.filter((col) => fields.indexOf(SERVER_CLIENT_COLS_MAP[col.id]) >= 0)
      : LOGS_COLUMNS;
  }, [areLimitedColumns, fields]);

  const isHideFilters = useMemo(() => isSectionLimited(logs) && !filtering, [filtering, logs]);

  return (
    <div className={s.tableWrapper}>
      {!isHideFilters && (
        <LogsFilter
          logsFilter={logsFilter}
          disabledFilter={disabledFilter}
          handleFilterChange={handleFilterChange}
          filterParams={filterParams}
          setDisabledFilter={setDisabledFilter}
          setReqParams={setReqParams}
        />
      )}
      <PaginationContext.Provider
        value={{
          value: filterParams.page_size,
          options: Object.values(filterPerPageLabels),
          onChange: handleFilterChangeAndCall,
          isLoading
        }}
      >
        <CustomizedTable
          count={count}
          columns={filteredColumns}
          rows={rows}
          isLoading={isLoading}
          error={reqError}
          emptyRowsMsg="There are no logs yet..."
          getContent={getContent}
          getCollapsibleContent={() => null}
          rowsPerPageOptions={[tablePerPage]}
          customPerPage={tablePerPage}
          customPage={reqParams.page - ZERO_BASED_PAGE_SHIFT}
          onCustomChangePage={onChangePage}
          offPerPage
          isPaginationPerPage={!isHideFilters}
          classes={{
            tableWrapper: s.tableWrapper,
            tHeadClasses: {
              tHeadCellClass: s.tHeadCellClass
            },
            tBodyClasses: {
              tBodyCellClass: s.tBodyCellClass
            }
          }}
        />
      </PaginationContext.Provider>
    </div>
  );
};

export default LogsTable;
