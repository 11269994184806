import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import throttle from 'lodash/throttle';
import PuffLoader from 'react-spinners/PuffLoader';
import { getNotifications, removeNotification } from 'redux/actions/notificationActions';
import { notificationsRoot } from 'redux/selectors';
import MainLayout from 'components/mainLayout/MainLayout';
import { getErrorMessageHelper } from 'helpers/randomHelpers';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import { DEFAULT_TRANSITION_TIMEOUT } from 'constants/randomConstants';
import Fade from 'components/reactTransitions/FadeTransition.module.css';
import NotificationsList from './NotificationsList';
import s from './NotificationsPage.module.scss';

const DEFAULT_PARAMS = {
  page: 1
};

const LOADER_SIZE = 100;
const THROTTLE_TIMEOUT = 700;

const getShouldShowMore = (): boolean => {
  const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
  const body = document.body;
  const html = document.documentElement;
  const docHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  const windowBottom = Math.round(windowHeight + window.pageYOffset);

  return windowBottom >= docHeight;
};

const NotificationsPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const { results } = useSelector(notificationsRoot);

  const [reqParams, setReqParams] = useState(DEFAULT_PARAMS);

  const [isLoading, setLoading] = useState(true);
  const [isScrollLoader, setScrollLoader] = useState(false);
  const offLoaders = () => {
    setLoading(false);
    setScrollLoader(false);
  };

  const [reqErr, setReqErr] = useState<string | null>(null);
  const onSetReqError = (err: ErrorShape) => {
    const errMsg = getErrorMessageHelper(err);
    setReqErr(errMsg);
  };

  const onRemoveNotification = (id: number) => {
    dispatch(removeNotification(id, onSetReqError));
  };

  const onShowMore = useCallback(() => {
    setScrollLoader(true);
    setReqParams((prev) => ({ page: prev.page + 1 }));
  }, []);

  const onScroll = useCallback(
    () =>
      throttle(() => {
        if (reqErr) return;

        const shouldShowMore = getShouldShowMore();
        if (shouldShowMore) onShowMore();
      }, THROTTLE_TIMEOUT)(),
    [onShowMore, reqErr]
  );

  useEffect(() => {
    dispatch(
      getNotifications(reqParams, offLoaders, (err) => {
        onSetReqError(err);
        offLoaders();
      })
    );
  }, [dispatch, reqParams]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return (
    <MainLayout>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={isLoading ? 'loader' : 'collection'}
          timeout={DEFAULT_TRANSITION_TIMEOUT}
          classNames={{ ...Fade }}
        >
          {isLoading ? (
            <div className={s.loaderWrapper}>
              <PuffLoader size={LOADER_SIZE} loading={isLoading} />
            </div>
          ) : (
            <NotificationsList
              list={results}
              onDelete={onRemoveNotification}
              isBottomLoader={isScrollLoader}
              error={reqErr}
            />
          )}
        </CSSTransition>
      </SwitchTransition>
    </MainLayout>
  );
};

export default NotificationsPage;
