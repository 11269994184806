import { AxiosResponse } from 'axios';
import { API_ENDPOINTS } from 'constants/apiEndpoints';
import * as Shapes from 'interfaces/strategyStatsShapes';
import { saveAs } from 'file-saver';
import { callbackResolver } from '../../../helpers/callbackHelpers';
import { formatISODate } from '../../../helpers/randomHelpers';
import * as AcShapes from '../../../interfaces/accountStatsShapes';
import { ChartDTO } from '../../../helpers/chartsHelpers';
import { AxiosInstance as axios } from '../../../helpers/authHelpers';

export const getSignalsStatsHelper = (
  queryObj: Shapes.GetStrategyStatsQuery
): Promise<AxiosResponse<Shapes.GetStrategyStatsResponse>> => {
  return axios.get(API_ENDPOINTS.SIGNALS_STATS, { params: queryObj });
};

export const getSignalsStatsFiltersHelper = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.PROFILE_STATS_FILTERS);
};

export const exportSignalsStatsHelper = (
  queryObj: Shapes.GetStrategyStatsQuery
): Promise<AxiosResponse<Shapes.GetStrategyStatsResponse>> => {
  return axios.get(API_ENDPOINTS.PROFILES_STATS_EXPORT, { params: queryObj });
};

export const exportSignalStatsPolling = (taskId: string): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.PROFILES_STATS_EXPORT_POLLING(taskId));
};

export const exportAccountStatsPolling = (taskId: string): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.ACCOUNT_STATS_EXPORT_POLLING(taskId));
};

const TASK_SUCCESS = 'SUCCESS';
const POLLING_INTERVAL = 3000;

export const pollingSignalStats = (
  taskId: string,
  onSuccess: () => void,
  params: Shapes.GetStrategyStatsQuery
): void => {
  const intervalId = setInterval(async () => {
    const { data } = await exportSignalStatsPolling(taskId);
    if (data?.task_status === TASK_SUCCESS && data?.filename) {
      const fileName = getFileName('Profile', params);
      saveAs(data.filename, fileName);
      clearInterval(intervalId);
      callbackResolver(onSuccess);
    }
  }, POLLING_INTERVAL);
};

const getFileName = (name: string, params: Shapes.GetStrategyStatsQuery): string => {
  const hasFromProperty = !!params.created_at__gte;
  const getItsDate = (date) => formatISODate(date, 'dd-MM-yyyy');
  const reportTime = hasFromProperty
    ? `${getItsDate(params.created_at__gte)}_${getItsDate(params.created_at__lte)}`
    : getItsDate(params.created_at__lte);
  return `${name} stats report ${reportTime}.xlsx`;
};

export const getAccountStatsHelper = (queryObj: Shapes.GetStrategyStatsQuery): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.ACCOUNT_STATS, { params: queryObj });
};

export const getAccountStatsFiltersHelper = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.ACCOUNT_STATS_FILTERS);
};

export const exportAccountsStatsHelper = (queryObj: AcShapes.GetAccountStatsQuery): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.ACCOUNT_STATS_EXPORT, { params: queryObj });
};

export const getResultByMonthsStatsHelper = (): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.MONTHS_RESULT_STATS);
};

export const getStatsGraphHelper = (queryObj: ChartDTO): Promise<AxiosResponse> => {
  return axios.get(API_ENDPOINTS.CHARTS, { params: queryObj });
};

export const pollingAccountStats = (
  taskId: string,
  onSuccess: () => void,
  params: Shapes.GetStrategyStatsQuery
): void => {
  const intervalId = setInterval(async () => {
    const { data } = await exportAccountStatsPolling(taskId);
    if (data?.task_status === TASK_SUCCESS && data?.filename) {
      const fileName = getFileName('Account', params);
      saveAs(data.filename, fileName);
      clearInterval(intervalId);
      callbackResolver(onSuccess);
    }
  }, POLLING_INTERVAL);
};
