import { ColumnAlignOptions } from 'constants/randomConstants';
import { GetOrdersQuery, GetSignalsQuery } from 'interfaces/signalsOrdersShapes';
import { formatISODate } from '../helpers/randomHelpers';
import { APAColIds, SIGNALS_COLS } from './privilegesConstants';

export enum SignalStateTypes {
  CANCELED_MMSP = 'canceled_by_mmsp',
  CANCELED_ATR = 'canceled_by_atr',
  ACTIVE_NP = 'active_np',
  ACTIVE_PP = 'active_pp',
  ACTIVE_FP = 'active_fp',
  ACTIVE_PP_PF = 'active_pp_pf',
  ACTIVE_FP_PF = 'active_fp_pf',
  CANCELED_ROC = 'canceled_by_roc',
  FINISHED = 'finished',
  CANCELED_USER = 'canceled_by_user',
  MISSED = 'missed',
  EMPTY = '-'
}

export enum SignalStatuses {
  ACTIVE = 'active',
  IN_QUEUE = 'in_queue',
  FINISHED = 'finished',
  MISSED = 'missed',
  CANCELED = 'canceled'
}

export enum SignalDirections {
  LONG = 'long',
  SHORT = 'short'
}

export enum SignalTimeframes {
  HOUR = '1h',
  FOUR_HOURS = '4h',
  DAY = '1d',
  WEEK = '1w',
  MONTH = '1M'
}

export enum SignalActionStates {
  NONE = 'NONE',
  CANCELLING = 'CANCELLING',
  CANCELLED = 'CANCELLED',
  CLOSING = 'CLOSING',
  CLOSED = 'CLOSED',
  CHANGING = 'CHANGING',
  CHANGED = 'CHANGED'
}

export enum OrderStatuses {
  PLACING = 'PLACING',
  PLACED = 'PLACED',
  FILLED = 'FILLED',
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  CANCELING = 'CANCELING',
  CANCELED = 'CANCELED',
  PLACEMENT_FAILED = 'PLACEMENT_FAILED'
}

export enum OrderStates {
  PLACING = 'PLACING',
  PLACED = 'PLACED',
  FILLED = 'FILLED',
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  CANCELING = 'CANCELING',
  CANCELED_MANUAL = 'CANCELED_MANUAL',
  CANCELED_BY_TIMER = 'CANCELED_BY_TIMER',
  CANCELED_BY_MOVEMENT = 'CANCELED_BY_MOVEMENT',
  PLACEMENT_FAILED = 'PLACEMENT_FAILED'
}

export enum OrderDirections {
  ENTER = 'enter',
  EXIT = 'exit'
}

export enum OrderSides {
  SELL = 'sell',
  BUY = 'buy'
}

export enum OrderExecutionTypes {
  NEW = 'NEW',
  CANCELLED = 'CANCELED',
  CALCULATED = 'CALCULATED',
  EXPIRED = 'EXPIRED',
  TRADE = 'TRADE'
}

export enum OrderTimesToForce {
  GTC = 'GTC',
  IOC = 'IOC',
  FOK = 'FOK',
  GTX = 'GTX'
}

export const ACTIVE_ORDER_STATUSES = [OrderStatuses.PLACED, OrderStatuses.PLACING];

export enum OrdersColIds {
  DATE = 'created_at',
  SIGNAL_ID = 'signal',
  STRATEGY_PROFILE = 'profile',
  TICKER_PAIR = 'pair',
  ORDER_DIRECTION = 'direction',
  ORDER_ID = 'exchange_order_id',
  ORDER_TYPE = 'order_type',
  E_PRICE = 'price',
  ORDER_SIZE = 'o_size',
  ORDER_SIZE_COINS = 'quantity',
  ORDER_STATE = 'status',
  ACTION = 'action',
  ID = 'id'
}

export const ORDERS_TABLE_COL_NAMES = {
  [OrdersColIds.DATE]: 'Date',
  [OrdersColIds.SIGNAL_ID]: 'Signal ID',
  [OrdersColIds.STRATEGY_PROFILE]: 'Strategy profile',
  [OrdersColIds.TICKER_PAIR]: 'Ticker/pair',
  [OrdersColIds.ORDER_DIRECTION]: 'O-Direction',
  [OrdersColIds.ORDER_ID]: 'Order ID',
  [OrdersColIds.ORDER_TYPE]: 'Order type',
  [OrdersColIds.E_PRICE]: 'E-price',
  [OrdersColIds.ORDER_SIZE]: 'O-size $',
  [OrdersColIds.ORDER_SIZE_COINS]: 'O-size coins',
  [OrdersColIds.ORDER_STATE]: 'Order state',
  [OrdersColIds.ACTION]: 'Action'
};

export const SERVER_CLIENT_ORDER_COLS_MAP = {
  [OrdersColIds.DATE]: OrdersColIds.DATE,
  [OrdersColIds.SIGNAL_ID]: OrdersColIds.SIGNAL_ID,
  [OrdersColIds.STRATEGY_PROFILE]: OrdersColIds.STRATEGY_PROFILE,
  [OrdersColIds.TICKER_PAIR]: OrdersColIds.TICKER_PAIR,
  [OrdersColIds.ORDER_DIRECTION]: OrdersColIds.ORDER_DIRECTION,
  [OrdersColIds.ORDER_ID]: OrdersColIds.ORDER_ID,
  [OrdersColIds.ORDER_TYPE]: OrdersColIds.ORDER_TYPE,
  [OrdersColIds.E_PRICE]: OrdersColIds.E_PRICE,
  [OrdersColIds.ORDER_SIZE]: OrdersColIds.ORDER_SIZE,
  [OrdersColIds.ORDER_SIZE_COINS]: OrdersColIds.ORDER_SIZE_COINS,
  [OrdersColIds.ORDER_STATE]: OrdersColIds.ORDER_STATE,
  [OrdersColIds.ACTION]: OrdersColIds.ACTION
};

export const ORDERS_COLUMNS = [
  {
    id: OrdersColIds.DATE,
    title: ORDERS_TABLE_COL_NAMES[OrdersColIds.DATE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: OrdersColIds.STRATEGY_PROFILE,
    title: ORDERS_TABLE_COL_NAMES[OrdersColIds.STRATEGY_PROFILE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: OrdersColIds.SIGNAL_ID,
    title: ORDERS_TABLE_COL_NAMES[OrdersColIds.SIGNAL_ID],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: OrdersColIds.TICKER_PAIR,
    title: ORDERS_TABLE_COL_NAMES[OrdersColIds.TICKER_PAIR],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: OrdersColIds.ORDER_DIRECTION,
    title: ORDERS_TABLE_COL_NAMES[OrdersColIds.ORDER_DIRECTION],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: OrdersColIds.ORDER_ID,
    title: ORDERS_TABLE_COL_NAMES[OrdersColIds.ORDER_ID],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: OrdersColIds.ORDER_TYPE,
    title: ORDERS_TABLE_COL_NAMES[OrdersColIds.ORDER_TYPE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: OrdersColIds.E_PRICE,
    title: ORDERS_TABLE_COL_NAMES[OrdersColIds.E_PRICE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: OrdersColIds.ORDER_SIZE,
    title: ORDERS_TABLE_COL_NAMES[OrdersColIds.ORDER_SIZE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: OrdersColIds.ORDER_SIZE_COINS,
    title: ORDERS_TABLE_COL_NAMES[OrdersColIds.ORDER_SIZE_COINS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: OrdersColIds.ORDER_STATE,
    title: ORDERS_TABLE_COL_NAMES[OrdersColIds.ORDER_STATE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: OrdersColIds.ACTION,
    title: ORDERS_TABLE_COL_NAMES[OrdersColIds.ACTION],
    align: ColumnAlignOptions.CENTER
  }
];

export enum SignalsColIds {
  DATE = 'created_at',
  STRATEGY_PROFILE = 'profile',
  STRATEGY_PROFILE_ID = 'profile_id',
  TIME_FRAME = 'timeframe',
  SIGNAL_ID = 'id',
  TICKER_PAIR = 'pair',
  SIGNAL_DIRECTION = 'direction',
  EXECUTION = 'execution',
  POSITION_SIZE = 'size_currency',
  COINS_SIZE = 'size_coins',
  E_PRICE = 'avg_e_price',
  EXIT_PLACE = 'exit_place',
  CURRENT_PRICE = 'current_price',
  PNL_IN_USD = 'pnl_in_usd',
  PNL_IN_PERCENT = 'pnl_in_percent',
  TIME_PER_SIGNAL = 'time_per_signal',
  TR_IN_ATR = 'tr_in_atr',
  INC_VOLA = 'inc_vola',
  ATR_I_1 = 'atr_minus_1',
  A_PERIOD = 'minute_activated_at',
  STATUS = 'status',
  STATE = 'state',
  CANCEL = 'cancel',
  ACTION_STATE = 'action_state',
  ATR_T_PERCENT = 'atr_t_percent',
  PROFILE_ID = 'profile_id'
}

export const SIGNALS_TABLE_COL_NAMES = {
  [SignalsColIds.DATE]: 'Date',
  [SignalsColIds.STRATEGY_PROFILE]: 'Strategy profile',
  [SignalsColIds.TIME_FRAME]: 'TF',
  [SignalsColIds.SIGNAL_ID]: 'Signal ID',
  [SignalsColIds.TICKER_PAIR]: 'Ticker',
  [SignalsColIds.SIGNAL_DIRECTION]: 'S-Dir',
  [SignalsColIds.EXECUTION]: 'Exec',
  [SignalsColIds.POSITION_SIZE]: 'Position-s-$',
  [SignalsColIds.COINS_SIZE]: 'Position-s-c',
  [SignalsColIds.E_PRICE]: 'Avg. e-price',
  [SignalsColIds.EXIT_PLACE]: 'Exit price',
  [SignalsColIds.CURRENT_PRICE]: 'Current price',
  [SignalsColIds.PNL_IN_USD]: 'PNL ($)',
  [SignalsColIds.PNL_IN_PERCENT]: 'PNL (%)',
  [SignalsColIds.TR_IN_ATR]: 'TRinATR',
  [SignalsColIds.INC_VOLA]: 'Inc.Vola',
  [SignalsColIds.ATR_I_1]: 'ATR(i-1)%',
  [SignalsColIds.A_PERIOD]: 'A-Period',
  [SignalsColIds.STATUS]: 'Status',
  [SignalsColIds.TIME_PER_SIGNAL]: 'Time per signal',
  [SignalsColIds.STATE]: 'State',
  [SignalsColIds.CANCEL]: 'Cancel',
  [SignalsColIds.ACTION_STATE]: 'Action State',
  [SignalsColIds.ATR_T_PERCENT]: 'ATR-T(%)'
};

export const SERVER_CLIENT_COLS_MAP = {
  [SignalsColIds.DATE]: SIGNALS_COLS.CREATED_AT,
  [SignalsColIds.STRATEGY_PROFILE]: SIGNALS_COLS.PROFILE,
  [SignalsColIds.TIME_FRAME]: SIGNALS_COLS.TIMEFRAME,
  [SignalsColIds.SIGNAL_ID]: SIGNALS_COLS.ID,
  [SignalsColIds.TICKER_PAIR]: SIGNALS_COLS.PAIR,
  [SignalsColIds.SIGNAL_DIRECTION]: SIGNALS_COLS.DIRECTION,
  [SignalsColIds.EXECUTION]: SIGNALS_COLS.EXECUTION,
  [SignalsColIds.POSITION_SIZE]: SIGNALS_COLS.SIZE_CURRENCY,
  [SignalsColIds.COINS_SIZE]: SIGNALS_COLS.SIZE_COINS,
  [SignalsColIds.E_PRICE]: SIGNALS_COLS.AVG_E_PRICE,
  [SignalsColIds.EXIT_PLACE]: SIGNALS_COLS.EXIT_PLACE,
  [SignalsColIds.CURRENT_PRICE]: SIGNALS_COLS.CURRENT_PRICE,
  [SignalsColIds.PNL_IN_USD]: SIGNALS_COLS.PNL_IN_USD,
  [SignalsColIds.PNL_IN_PERCENT]: SIGNALS_COLS.PNL_IN_PERCENT,
  [SignalsColIds.TR_IN_ATR]: SIGNALS_COLS.TR_IN_ATR,
  [SignalsColIds.INC_VOLA]: SIGNALS_COLS.INC_VOLA,
  [SignalsColIds.ATR_I_1]: SIGNALS_COLS.ATR_MINUS_1,
  [SignalsColIds.A_PERIOD]: SIGNALS_COLS.MINUTE_ACTIVATED_AT,
  [SignalsColIds.STATUS]: SIGNALS_COLS.STATUS,
  [SignalsColIds.TIME_PER_SIGNAL]: SIGNALS_COLS.TIME_PER_SIGNAL,
  [SignalsColIds.STATE]: SIGNALS_COLS.STATE,
  [SignalsColIds.CANCEL]: SIGNALS_COLS.CANCEL,
  [SignalsColIds.ACTION_STATE]: SIGNALS_COLS.ACTION_STATE,
  [SignalsColIds.ATR_T_PERCENT]: SIGNALS_COLS.ATR_T_PERCENT,
  [SignalsColIds.PROFILE_ID]: SIGNALS_COLS.PROFILE_ID
};

export const READ_ONLY_FORBIDDEN_FIELDS = [
  SIGNALS_COLS.CANCEL,
  SIGNALS_COLS.CLOSE_MARKET,
  SIGNALS_COLS.CLOSE_LIMIT,
  SIGNALS_COLS.ACTION_STATE,
  SIGNALS_COLS.STATE
];

export const SortingSignalValues = {
  [SIGNALS_TABLE_COL_NAMES[SignalsColIds.DATE]]: SignalsColIds.DATE,
  [SIGNALS_TABLE_COL_NAMES[SignalsColIds.STRATEGY_PROFILE]]: SignalsColIds.STRATEGY_PROFILE,
  [SIGNALS_TABLE_COL_NAMES[SignalsColIds.STATUS]]: SignalsColIds.STATUS
};

export const SIGNALS_COLUMNS = (isStrategyPage: boolean): typeof SIGNALS_COLUMNS => [
  {
    id: SignalsColIds.DATE,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.DATE],
    align: ColumnAlignOptions.CENTER,
    sortable: true
  },
  {
    id: SignalsColIds.STRATEGY_PROFILE,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.STRATEGY_PROFILE],
    align: ColumnAlignOptions.CENTER,
    sortable: !isStrategyPage
  },
  {
    id: SignalsColIds.TIME_FRAME,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.TIME_FRAME],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.SIGNAL_ID,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.SIGNAL_ID],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.TICKER_PAIR,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.TICKER_PAIR],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.SIGNAL_DIRECTION,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.SIGNAL_DIRECTION],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.EXECUTION,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.EXECUTION],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.POSITION_SIZE,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.POSITION_SIZE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.COINS_SIZE,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.COINS_SIZE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.E_PRICE,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.E_PRICE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.EXIT_PLACE,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.EXIT_PLACE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.CURRENT_PRICE,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.CURRENT_PRICE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.PNL_IN_USD,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.PNL_IN_USD],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.PNL_IN_PERCENT,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.PNL_IN_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.TR_IN_ATR,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.TR_IN_ATR],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.INC_VOLA,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.INC_VOLA],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.ATR_I_1,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.ATR_I_1],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.ATR_T_PERCENT,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.ATR_T_PERCENT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.A_PERIOD,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.A_PERIOD],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.STATUS,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.STATUS],
    align: ColumnAlignOptions.CENTER,
    sortable: true
  },
  {
    id: SignalsColIds.TIME_PER_SIGNAL,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.TIME_PER_SIGNAL],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.STATE,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.STATE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.CANCEL,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.CANCEL],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SignalsColIds.ACTION_STATE,
    title: SIGNALS_TABLE_COL_NAMES[SignalsColIds.ACTION_STATE],
    align: ColumnAlignOptions.CENTER
  }
];

export const HIDE_SIGNAL_COLUMNS_FOR_STRATEGY = [
  SignalsColIds.STRATEGY_PROFILE,
  SignalsColIds.TIME_FRAME,
  SignalsColIds.SIGNAL_DIRECTION
];

export const HIDE_ORDER_COLUMNS_FOR_STRATEGY = [OrdersColIds.STRATEGY_PROFILE];

export enum SignalsFilterIDs {
  PAGE = 'page',
  PER_PAGE = 'page_size',
  PROFILE = 'profile',
  STATUS = 'status',
  PAIRS = 'pair',
  BEFORE_CREATING = 'created_at_before',
  AFTER_CREATING = 'created_at_after',
  SHOW_MISSED = 'show_missed',
  SHOW_UNFILLED = 'show_unfilled'
}

export const FilterBeforeUntilValues = {
  before: formatISODate(new Date().toISOString(), 'yyyy-MM-dd'),
  until: ''
};

enum FilterProfileValues {
  initValue = ''
}

const FilterProfileLabels = {
  [FilterProfileValues.initValue]: 'All'
};

enum FilterPairsValues {
  initValue = ''
}

const FilterPairsLabels = {
  [FilterProfileValues.initValue]: 'All'
};

export const SignalLabels = {
  [SignalsFilterIDs.STATUS]: 'Status',
  [SignalsFilterIDs.PROFILE]: 'Profile',
  [SignalsFilterIDs.PAIRS]: 'Pairs',
  [SignalsFilterIDs.PER_PAGE]: 'Per page',
  [SignalsFilterIDs.AFTER_CREATING]: 'From',
  [SignalsFilterIDs.BEFORE_CREATING]: 'Until'
};

export enum FilterPerPageValues {
  PP_10 = '10',
  PP_20 = '20',
  PP_50 = '50',
  PP_100 = '100',
  PP_200 = '200',
  PP_500 = '500'
}

export const FilterPerPageLabels = {
  [FilterPerPageValues.PP_10]: '10',
  [FilterPerPageValues.PP_20]: '20',
  [FilterPerPageValues.PP_50]: '50',
  [FilterPerPageValues.PP_100]: '100',
  [FilterPerPageValues.PP_200]: '200',
  [FilterPerPageValues.PP_500]: '500'
};

enum StatusValues {
  ALL = '',
  ACTIVE = 'active',
  IN_QUEUE = 'in_queue',
  FINISHED = 'finished',
  MISSED = 'missed',
  CANCELED = 'canceled'
}

const StatusLabels = {
  [StatusValues.ALL]: 'All',
  [StatusValues.ACTIVE]: 'Active',
  [StatusValues.IN_QUEUE]: 'In queue',
  [StatusValues.FINISHED]: 'Finished',
  [StatusValues.MISSED]: 'Missed',
  [StatusValues.CANCELED]: 'Canceled'
};

export enum MissedValues {
  MISSED = 'true',
  NOT_MISSED = 'false'
}

export enum UnfilledValues {
  UNFILLED = 'true',
  NOT_UNFILLED = 'false'
}

export const SIGNAL_FILTERS = (showProfileFilter = false): typeof SIGNAL_FILTERS => [
  {
    id: SignalsFilterIDs.PROFILE,
    label: SignalLabels[SignalsFilterIDs.PROFILE],
    invisible: showProfileFilter,
    options: [
      { value: FilterProfileValues.initValue as string, label: FilterProfileLabels[FilterProfileValues.initValue] }
    ]
  },
  {
    id: SignalsFilterIDs.PAIRS,
    label: SignalLabels[SignalsFilterIDs.PAIRS],
    invisible: showProfileFilter,
    options: [{ value: FilterPairsValues.initValue as string, label: FilterPairsLabels[FilterProfileValues.initValue] }]
  },
  {
    id: SignalsFilterIDs.STATUS,
    label: SignalLabels[SignalsFilterIDs.STATUS],
    invisible: false,
    options: [
      { value: StatusValues.ALL, label: StatusLabels[StatusValues.ALL] },
      { value: StatusValues.ACTIVE, label: StatusLabels[StatusValues.ACTIVE] },
      { value: StatusValues.IN_QUEUE, label: StatusLabels[StatusValues.IN_QUEUE] },
      { value: StatusValues.FINISHED, label: StatusLabels[StatusValues.FINISHED] },
      { value: StatusValues.MISSED, label: StatusLabels[StatusValues.MISSED] },
      { value: StatusValues.CANCELED, label: StatusLabels[StatusValues.CANCELED] }
    ]
  }
];

export const SIGNAL_DATE = [
  {
    id: SignalsFilterIDs.AFTER_CREATING,
    label: SignalLabels[SignalsFilterIDs.AFTER_CREATING],
    fieldName: SignalsFilterIDs.AFTER_CREATING,
    value: ''
  },
  {
    id: SignalsFilterIDs.BEFORE_CREATING,
    label: SignalLabels[SignalsFilterIDs.BEFORE_CREATING],
    fieldName: SignalsFilterIDs.BEFORE_CREATING,
    value: SignalsFilterIDs.BEFORE_CREATING
  }
];

export const initialSignalValues = {
  [SignalsFilterIDs.PAGE]: 1,
  [SignalsFilterIDs.PER_PAGE]: FilterPerPageValues.PP_200,
  [SignalsFilterIDs.PROFILE]: FilterProfileValues.initValue as string,
  [SignalsFilterIDs.PAIRS]: FilterPairsValues.initValue as string,
  [SignalsFilterIDs.BEFORE_CREATING]: FilterBeforeUntilValues.before,
  [SignalsFilterIDs.AFTER_CREATING]: FilterBeforeUntilValues.until,
  [SignalsFilterIDs.STATUS]: StatusValues.ALL,
  [SignalsFilterIDs.SHOW_MISSED]: MissedValues.NOT_MISSED,
  [SignalsFilterIDs.SHOW_UNFILLED]: UnfilledValues.NOT_UNFILLED
};

export type InitialSignalValuesType = typeof initialSignalValues;

export const DEFAULT_SIGNALS_PARAMS: GetSignalsQuery = {
  [SignalsFilterIDs.PAGE]: initialSignalValues[SignalsFilterIDs.PAGE],
  [SignalsFilterIDs.PER_PAGE]: initialSignalValues[SignalsFilterIDs.PER_PAGE],
  [SignalsFilterIDs.PROFILE]: initialSignalValues[SignalsFilterIDs.PROFILE],
  [SignalsFilterIDs.PAIRS]: initialSignalValues[SignalsFilterIDs.PAIRS],
  [SignalsFilterIDs.BEFORE_CREATING]: initialSignalValues[SignalsFilterIDs.BEFORE_CREATING],
  [SignalsFilterIDs.AFTER_CREATING]: initialSignalValues[SignalsFilterIDs.AFTER_CREATING],
  [SignalsFilterIDs.STATUS]: initialSignalValues[SignalsFilterIDs.STATUS],
  [SignalsFilterIDs.SHOW_MISSED]: initialSignalValues[SignalsFilterIDs.SHOW_MISSED],
  [SignalsFilterIDs.SHOW_UNFILLED]: initialSignalValues[SignalsFilterIDs.SHOW_UNFILLED]
};

export const DEFAULT_ORDER_PARAMS: GetOrdersQuery = {
  [SignalsFilterIDs.PAGE]: 1
};

export enum AdministrationColIds {
  PICKER = 'picker',
  DATE = 'created_at',
  STRATEGY_NAME = 'profile',
  SIGNAL_ID = 'id',
  TICKER = 'ticker',
  ACTIVE_P_SIZE = 'active_p_size_usd',
  ACTIVE_P_COINS = 'active_p_coins',
  R_PLN = 'r_pnl',
  U_PLN = 'u_pnl',
  AVG_E_PRICE = 'avg_e_price',
  K_EXIT = 'k_exit',
  K_EXIT_COEFF = 'k_exit_coeff',
  ID = 'id',
  REDUCE_P = 'reduce_p',
  KE_RECOVERY = 'k_e_recovery',
  CURRENT_PRICE = 'current_price',
  MARK_PRICE = 'mark_price',
  CLOSE_MARKET = 'close_market',
  CLOSE_LIMIT = 'close_limit'
}

export const ADMIN_TABLE_COL_NAMES = {
  [AdministrationColIds.PICKER]: '',
  [AdministrationColIds.DATE]: 'Date',
  [AdministrationColIds.STRATEGY_NAME]: 'Strategy name',
  [AdministrationColIds.SIGNAL_ID]: 'Signal ID',
  [AdministrationColIds.TICKER]: 'Ticker',
  [AdministrationColIds.ACTIVE_P_SIZE]: 'Active p-size $',
  [AdministrationColIds.ACTIVE_P_COINS]: 'Active p-coins',
  [AdministrationColIds.R_PLN]: 'R-Pnl$',
  [AdministrationColIds.U_PLN]: 'U-Pnl$',
  [AdministrationColIds.AVG_E_PRICE]: 'Avg.E.Price',
  [AdministrationColIds.K_EXIT]: 'kExit',
  [AdministrationColIds.K_EXIT_COEFF]: 'kExit coeff',
  [AdministrationColIds.REDUCE_P]: 'Reduce-P',
  [AdministrationColIds.KE_RECOVERY]: 'kE.Recovery',
  [AdministrationColIds.CURRENT_PRICE]: 'Curr. price',
  [AdministrationColIds.MARK_PRICE]: 'Mark price',
  [AdministrationColIds.CLOSE_MARKET]: 'Close (market)',
  [AdministrationColIds.CLOSE_LIMIT]: 'Close (limit)'
};

export const SERVER_CLIENT_APA_COLS_MAP = {
  [AdministrationColIds.DATE]: APAColIds.CREATED_AT,
  [AdministrationColIds.STRATEGY_NAME]: APAColIds.PROFILE,
  [AdministrationColIds.SIGNAL_ID]: APAColIds.ID,
  [AdministrationColIds.TICKER]: APAColIds.TICKER,
  [AdministrationColIds.ACTIVE_P_SIZE]: APAColIds.ACTIVE_P_SIZE_USD,
  [AdministrationColIds.ACTIVE_P_COINS]: APAColIds.ACTIVE_P_COINS,
  [AdministrationColIds.R_PLN]: APAColIds.R_PLN,
  [AdministrationColIds.U_PLN]: APAColIds.U_PLN,
  [AdministrationColIds.AVG_E_PRICE]: APAColIds.AVG_E_PRICE,
  [AdministrationColIds.K_EXIT]: APAColIds.K_EXIT,
  [AdministrationColIds.K_EXIT_COEFF]: APAColIds.K_EXIT_COEFF,
  [AdministrationColIds.CURRENT_PRICE]: APAColIds.CURRENT_PRICE,
  [AdministrationColIds.MARK_PRICE]: APAColIds.MARK_PRICE,
  [AdministrationColIds.REDUCE_P]: APAColIds.REDUCE_P,
  [AdministrationColIds.KE_RECOVERY]: APAColIds.KE_RECOVERY,
  [AdministrationColIds.CLOSE_MARKET]: APAColIds.CLOSE_MARKET,
  [AdministrationColIds.CLOSE_LIMIT]: APAColIds.CLOSE_LIMIT
};

const SORTING_VALUES = {
  [AdministrationColIds.STRATEGY_NAME]: 'profile__title',
  [AdministrationColIds.TICKER]: 'pair__ticker'
};

export const SortingAdministrationValues = {
  [ADMIN_TABLE_COL_NAMES[AdministrationColIds.STRATEGY_NAME]]: SORTING_VALUES[AdministrationColIds.STRATEGY_NAME],
  [ADMIN_TABLE_COL_NAMES[AdministrationColIds.TICKER]]: SORTING_VALUES[AdministrationColIds.TICKER]
};

export const ADMINISTRATION_DEFAULTS = { ordering: '' };

export const ADMINISTRATION_COLUMNS = [
  {
    id: AdministrationColIds.PICKER,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.PICKER],
    align: ColumnAlignOptions.CENTER,
    width: '10px',
    kind: 'custom'
  },
  {
    id: AdministrationColIds.DATE,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.DATE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.STRATEGY_NAME,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.STRATEGY_NAME],
    align: ColumnAlignOptions.CENTER,
    sortable: true
  },
  {
    id: AdministrationColIds.SIGNAL_ID,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.SIGNAL_ID],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.TICKER,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.TICKER],
    align: ColumnAlignOptions.CENTER,
    sortable: true
  },
  {
    id: AdministrationColIds.ACTIVE_P_SIZE,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.ACTIVE_P_SIZE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.ACTIVE_P_COINS,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.ACTIVE_P_COINS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.R_PLN,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.R_PLN],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.U_PLN,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.U_PLN],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.AVG_E_PRICE,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.AVG_E_PRICE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.K_EXIT,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.K_EXIT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.K_EXIT_COEFF,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.K_EXIT_COEFF],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.CURRENT_PRICE,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.CURRENT_PRICE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.MARK_PRICE,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.MARK_PRICE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.REDUCE_P,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.REDUCE_P],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.KE_RECOVERY,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.KE_RECOVERY],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.CLOSE_MARKET,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.CLOSE_MARKET],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: AdministrationColIds.CLOSE_LIMIT,
    title: ADMIN_TABLE_COL_NAMES[AdministrationColIds.CLOSE_LIMIT],
    align: ColumnAlignOptions.CENTER
  }
];

export enum RiskControlColIds {
  ACCOUNT = 'account',
  ALERT = 'alert',
  GROUP_PROFILES = 'group_profiles',
  ID = 'id',
  RULES = 'rules',
  TIMER = 'timer',
  TRIGGER_BLOCKER = 'trigger_blocker',
  TRIGGER_BLOCKER_ACTIVATE_PROFILE = 'trigger_blocker_active_profile',
  TRIGGER_NAME = 'trigger_name',
  TRIGGER_TYPE = 'trigger_type',
  TRIGGER_UNBLOCKER = 'trigger_unblocker',
  ACTIVE = 'is_active',
  VISIBILITY = 'is_show',
  ACTION = 'action'
}

export const RISK_CONTROL_TABLE_COL_NAMES = {
  [RiskControlColIds.ID]: '#',
  [RiskControlColIds.TRIGGER_NAME]: 'Name of Trigger',
  [RiskControlColIds.TRIGGER_BLOCKER]: 'Trigger(event) blocker',
  [RiskControlColIds.TRIGGER_UNBLOCKER]: 'Trigger-Unblocker',
  [RiskControlColIds.RULES]: 'Risk control rules',
  [RiskControlColIds.TIMER]: 'Timer(min)',
  [RiskControlColIds.ALERT]: 'Alert',
  [RiskControlColIds.GROUP_PROFILES]: 'Group',
  [RiskControlColIds.ACTIVE]: 'Active',
  [RiskControlColIds.VISIBILITY]: 'Visibility',
  [RiskControlColIds.ACTION]: 'Action'
};

export const RISK_CONTROL_COLUMNS = [
  {
    id: RiskControlColIds.ID,
    title: RISK_CONTROL_TABLE_COL_NAMES[RiskControlColIds.ID],
    align: ColumnAlignOptions.CENTER,
    width: '3%'
  },
  {
    id: RiskControlColIds.TRIGGER_NAME,
    title: RISK_CONTROL_TABLE_COL_NAMES[RiskControlColIds.TRIGGER_NAME],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: RiskControlColIds.TRIGGER_BLOCKER,
    title: RISK_CONTROL_TABLE_COL_NAMES[RiskControlColIds.TRIGGER_BLOCKER],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: RiskControlColIds.TRIGGER_UNBLOCKER,
    title: RISK_CONTROL_TABLE_COL_NAMES[RiskControlColIds.TRIGGER_UNBLOCKER],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: RiskControlColIds.RULES,
    title: RISK_CONTROL_TABLE_COL_NAMES[RiskControlColIds.RULES],
    align: ColumnAlignOptions.LEFT
  },
  {
    id: RiskControlColIds.TIMER,
    title: RISK_CONTROL_TABLE_COL_NAMES[RiskControlColIds.TIMER],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: RiskControlColIds.ALERT,
    title: RISK_CONTROL_TABLE_COL_NAMES[RiskControlColIds.ALERT],
    align: ColumnAlignOptions.CENTER,
    width: '3%'
  },
  {
    id: RiskControlColIds.GROUP_PROFILES,
    title: RISK_CONTROL_TABLE_COL_NAMES[RiskControlColIds.GROUP_PROFILES],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: RiskControlColIds.ACTIVE,
    title: RISK_CONTROL_TABLE_COL_NAMES[RiskControlColIds.ACTIVE],
    align: ColumnAlignOptions.CENTER,
    width: '3%'
  },
  {
    id: RiskControlColIds.VISIBILITY,
    title: RISK_CONTROL_TABLE_COL_NAMES[RiskControlColIds.VISIBILITY],
    align: ColumnAlignOptions.CENTER,
    width: '3%'
  },
  {
    id: RiskControlColIds.ACTION,
    title: RISK_CONTROL_TABLE_COL_NAMES[RiskControlColIds.ACTION],
    align: ColumnAlignOptions.CENTER
  }
];

export enum TriggerEnum {
  IMP_LOSS = 'imp_loss',
  VAR = 'var',
  NAV = 'nav',
  OPPOSITE_DIR = 'opposite_dir',
  NAV_REDUCING = 'nav_reducing',
  TIMER = 'timer',
  NAV_ALERTING = 'nav_alerting'
}

export enum ChangeableTriggerProperty {
  TR_BLOCK = 'trigger_blocker',
  TR_UNBLOCK = 'trigger_unblocker',
  SP = 'trigger_blocker_opp_dir_sp',
  ATR = 'trigger_blocker_opp_dir_atr',
  TR_BLOCK_ACTIVATE_PROFILE = 'trigger_blocker_active_profile',
  GROUP_PROFILES = 'group_profiles',
  ACTIVE = 'is_active',
  VISIBILITY = 'is_show',
  POSITION_REDUCE = 'position_reduce',
  X_PERCENT_DEPO = 'x_percent_depo',
  Y_PERCENT_DEPO = 'y_percent_depo',
  TIMER = 'timer'
}

export const VALIDATION_BLOCKER_MAP = {
  [TriggerEnum.NAV]: { min: 0, max: 100, description: 'The value can be only in interval of 0 - 100' },
  [TriggerEnum.VAR]: { min: 0, max: 500, description: 'The value can be only in interval of 0 - 500' },
  [TriggerEnum.IMP_LOSS]: { min: -100, max: 0, description: 'The value can be only in interval of -100 - 0' },
  [TriggerEnum.OPPOSITE_DIR]: {
    min: 0,
    max: 100,
    description: 'The value can be only in interval of 0 - 100'
  },
  [TriggerEnum.NAV_REDUCING]: { min: 0, max: 100, description: 'The value can be only in interval of 0 - 100' },
  [TriggerEnum.TIMER]: { min: 0, max: 3000, description: 'The value can be only in interval of 0 - 3000' },
  [TriggerEnum.NAV_ALERTING]: { min: 0, max: 100, description: 'The value can be only in interval of 0 - 100' }
};

export const MODAL_TITLE_MAP = {
  trigger_blocker: 'Trigger(event) blocker %',
  trigger_unblocker: 'Trigger-Unblocker %',
  trigger_blocker_opp_dir_sp: 'Trigger(event) blocker (SP)%',
  trigger_blocker_opp_dir_atr: 'Trigger(event) blocker (ATR-T%)%',
  position_reduce: 'Trigger(event) blocker (Position reduce)',
  x_percent_depo: 'Trigger(event) blocker (X percent depo)',
  y_percent_depo: 'Trigger(event) blocker (Y percent depo)',
  timer: 'Timer'
};

export enum DepositTypes {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
  REINVEST = 'reinvest'
}

export const DepositOptions = [
  { value: DepositTypes.DEPOSIT, label: 'Deposit' },
  { value: DepositTypes.WITHDRAWAL, label: 'Withdrawal' },
  { value: DepositTypes.REINVEST, label: 'Reinvest' }
];
