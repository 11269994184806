import React from 'react';
import { StrategyTickersIds } from '../constants/strategyTickersConstants';
import s from '../pages/privilegesAddPage/PrivilegesAdd.module.scss';
import Button from '@material-ui/core/Button';
import { ButtonColorTypes } from '../constants/randomConstants';
import TradingPairsSelect from '../components/tables/strategyTickers/tradingPairsSelect/TradingPairsSelect';
import { PairsListFormValues, PairsShape, PairsStatusShape } from '../interfaces/strategyProfilesShapes';
import { FormControl, MenuItem, Select } from '@material-ui/core';

export const strategyTickersRenderSwitch = (
  colId: StrategyTickersIds,
  row: PairsShape,
  tradingPairsOptions: Array<{ value; label: string }>,
  isReadOnly: boolean,
  handleSave: (id: number) => void,
  handleDelete: (id: number) => void,
  onUpdateStatus: (id: number, status: PairsStatusShape) => void,
  onUpdatePairs: (id: number, pairsIds: number[]) => void
): JSX.Element => {
  const value = row[colId];
  switch (colId) {
    case StrategyTickersIds.PAIRS:
      return (
        <TradingPairsSelect
          values={value}
          options={tradingPairsOptions}
          handleUpdateValue={(values) => onUpdatePairs(row?.id, values as number[])}
        />
      );
    case StrategyTickersIds.SAVE:
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={ButtonColorTypes.PRIMARY}
          onClick={() => handleSave(row?.id)}
          classes={{ root: s.actionBtn, outlinedPrimary: s.btnPrimary }}
          disabled={isReadOnly}
        >
          SAVE
        </Button>
      );
    case StrategyTickersIds.DELETE:
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={ButtonColorTypes.SECONDARY}
          onClick={() => handleDelete(row?.id)}
          classes={{ root: s.actionBtn, outlinedPrimary: s.btnPrimary }}
          disabled={isReadOnly}
        >
          DELETE
        </Button>
      );
    case StrategyTickersIds.STATUS:
      return (
        <FormControl variant="outlined">
          <Select
            labelId={PairsListFormValues.STATUS}
            id={PairsListFormValues.STATUS}
            name={PairsListFormValues.STATUS}
            value={value}
            onChange={({ target }) => onUpdateStatus(row?.id, target.value as PairsStatusShape)}
            placeholder="Select pairs"
          >
            <MenuItem value={PairsStatusShape.ACTIVE}>{PairsStatusShape.ACTIVE}</MenuItem>
            <MenuItem value={PairsStatusShape.INACTIVE}>{PairsStatusShape.INACTIVE}</MenuItem>
          </Select>
        </FormControl>
      );
    default:
      return <>{value ?? '-'}</>;
  }
};
