import React, { useCallback, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { layoutRootSelector, strategyRootSelector } from 'redux/selectors';
import { getProfile, updateProfile, updateStrategySettings } from 'redux/actions/strategyProfilesActions';
import PairedBtnBlock from 'components/buttons/pairedBtnBlock/PairedBtnBlock';
import ProfilesSettings from 'components/strategyProfileComponents/profilesSettings/ProfilesSettings';
import KeyParameters from 'components/strategyProfileComponents/keyParameters/KeyParameters';
import OrderManagement from 'components/strategyProfileComponents/orderManagement/OrderManagement';
import Modal from 'components/modal/MyModal';
import SummaryDataContent from './SummaryDataContent';
import StrategyTitleBlock from '../strategyTitleBlock/StrategyTitleBlock';
import {
  PriceDirectionsValues,
  STRATEGY_SCHEMA_TYPE,
  STRATEGY_STEPS_LABELS,
  StrategySettingsFormTypes,
  TemplateContext,
  TOTAL_STEPS
} from 'constants/strategyProfilesAddConstants';
import { getErrorMessageHelper, getModalContentIndent } from 'helpers/randomHelpers';
import { EditTypes } from './summaryConstants';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import {
  KeyParametersShape,
  OrderManagementSettings,
  PriceDirectionsTypes,
  ProfilesSettingsValues,
  StrategyProfileStatuses,
  StrategySettings,
  UpdateStrategyData
} from 'interfaces/strategyProfilesShapes';
import {
  getCreateProfileReqData,
  strategyPageInitial,
  strategyPageReducer,
  strategyTypes
} from 'helpers/strategyProfilesAddHelpers';
import s from './Summary.module.scss';
import { FormTarget } from '../../../constants/strategyProfilesConstants';
import { usePrivileges } from '../../../hooks/usePrivileges';
import { isSectionReadOnly } from '../../../helpers/privilegesHelpers';

interface SummaryProps {
  onCancel?: () => void;
  onSubmitSummarySuccess?: () => void;
  submitProfileStatus?: StrategyProfileStatuses;
  hideButtons?: boolean;
  hideTitle?: boolean;
  classes?: {
    wrapperClass?: string;
    contentClass?: string;
  };
}

const CUSTOM_SUMMARY_ERR = {
  response: { data: { custom_error: ['Include all necessary fields'] } }
};

const Summary = ({
  onCancel,
  onSubmitSummarySuccess,
  submitProfileStatus,
  hideButtons,
  hideTitle,
  classes
}: SummaryProps): JSX.Element => {
  const dispatch = useDispatch();
  const { profile } = useSelector(strategyRootSelector);
  const { profile_settings } = usePrivileges();

  const [{ errors }, tinyDispatch] = useReducer(strategyPageReducer, strategyPageInitial);

  const onClearRequestErrors = () => tinyDispatch({ type: strategyTypes.CLEAR_ERRORS });
  const onSetError = useCallback((err: ErrorShape, type: string) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) tinyDispatch({ type, payload: errorMsg });
  }, []);

  const onGetProfile = useCallback(
    (profileId: number, onError: (err) => void, onSuccess: (data?) => void) =>
      dispatch(getProfile(profileId, false, onError, onSuccess)),
    [dispatch]
  );

  const onSubmitProfiles = (data: ProfilesSettingsValues) => {
    const reqData = getCreateProfileReqData(data);
    const onError = (err) => onSetError(err, strategyTypes.SET_PROFILE_ERR);

    dispatch(updateProfile(profile?.id, reqData, onEditClose, onError));
  };

  const [returnToPrevStep, setReturnToPrevStep] = useState<EditTypes.PARAMETERS | null>(null);

  const priceDirectionHandler = (signalSettings) => {
    return (
      isEdit &&
      currentEditing === EditTypes.PARAMETERS &&
      ((profile.profile_direction === PriceDirectionsValues.LONG &&
        'price_direction' in signalSettings &&
        signalSettings?.price_direction === PriceDirectionsTypes.GROW) ||
        (profile.profile_direction === PriceDirectionsValues.SHORT &&
          'price_direction' in signalSettings &&
          signalSettings?.price_direction === PriceDirectionsTypes.FALL))
    );
  };

  const onUpdateStrategySettings = (
    settings: KeyParametersShape | OrderManagementSettings,
    type: StrategySettingsFormTypes
  ) => {
    const isSignalSettings = type === StrategySettingsFormTypes.S_SETTINGS;
    const signalSettings = isSignalSettings ? settings : profile?.settings?.signal_settings;
    const orderSettings = !isSignalSettings ? settings : profile?.settings?.order_management_settings;

    const reqData: Partial<UpdateStrategyData> = {
      schema_type: STRATEGY_SCHEMA_TYPE.V1,
      settings: {
        signal_settings: signalSettings,
        order_management_settings: orderSettings
      } as Partial<StrategySettings>
    };

    const onError = (err) => {
      onSetError(err, strategyTypes.SET_SETTINGS_ERR);
    };
    const onSuccess = () => {
      if (priceDirectionHandler(signalSettings)) {
        setReturnToPrevStep(EditTypes.PARAMETERS);
        onGetProfile(profile?.id, onError, () => handleEditOpen(EditTypes.ORDER_MANAGEMENT));
      } else {
        onGetProfile(profile?.id, onError, onEditClose);
      }
    };
    dispatch(updateStrategySettings(profile?.id, reqData, onSuccess, onError));
  };

  const [currentEditing, setCurrentEditing] = useState<EditTypes | null>(null);
  const [isEdit, setEdit] = useState(false);

  const onEditClose = () => {
    setReturnToPrevStep(null);
    setEdit(false);
  };

  const handleEditOpen = (type: EditTypes) => {
    setCurrentEditing(type);
    setEdit(true);
  };

  const handleSummarySubmit = () => {
    const notValidReqData = !profile?.title || !profile?.mm_profile?.title || !profile?.tickers;
    if (notValidReqData) return onSetError(CUSTOM_SUMMARY_ERR, strategyTypes.SET_SUBMIT_SUMMARY_ERR);
    const reqData = {
      title: profile?.title ?? null,
      mm_profile: profile?.mm_profile?.id ?? null,
      tickers: profile?.tickers ?? null,
      status: submitProfileStatus
    };

    dispatch(
      updateProfile(profile?.id, reqData, onSubmitSummarySuccess, (err) =>
        onSetError(err, strategyTypes.SET_SUBMIT_SUMMARY_ERR)
      )
    );
  };

  const { isAsideOpen } = useSelector(layoutRootSelector);
  const modalContentIndent = getModalContentIndent(isAsideOpen);

  return (
    <div className={cn(s.wrapper, { [classes?.wrapperClass]: classes?.wrapperClass })}>
      {!hideTitle && (
        <StrategyTitleBlock step={STRATEGY_STEPS_LABELS.SUMMARY_STEP} totalSteps={TOTAL_STEPS} title="Summary" />
      )}

      {profile && (
        <SummaryDataContent
          onEditOpen={handleEditOpen}
          profile={profile}
          contentClass={classes?.contentClass}
          isReadOnly={isSectionReadOnly(profile_settings)}
        />
      )}

      {!hideButtons && (
        <PairedBtnBlock
          disableCancel={hideButtons}
          disableSubmit={hideButtons}
          onCancel={onCancel}
          onSubmit={handleSummarySubmit}
          labels={{ cancelLabel: 'Back', submitLabel: 'Finish' }}
          classes={{ btnBlockWrapper: s.btnBlockWrapper }}
        />
      )}
      {errors?.submitSummaryErr && <span className={cn(s.error, 'errorText')}>{errors?.submitSummaryErr}</span>}
      <Modal
        disableBackdropClick={true}
        isOpen={isEdit}
        onToggle={onEditClose}
        paperCustomClass={s.modalContent}
        stylesProps={{
          paper: {
            top: currentEditing === EditTypes.PROFILE ? '48%' : '50%',
            left: `calc(50% + ${modalContentIndent}px)`,
            padding: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }
        }}
      >
        <TemplateContext.Provider value={{ showMessage: false }}>
          {(() => {
            switch (currentEditing) {
              case EditTypes.PARAMETERS:
                return (
                  <KeyParameters
                    target={FormTarget.EDITING}
                    profile={profile}
                    onUpdateKeyParameters={onUpdateStrategySettings}
                    onClearRequestErrors={onClearRequestErrors}
                    onCancelParameters={onEditClose}
                    errors={errors}
                    isStepsHidden
                    classes={{ contentWrapperClass: s.modalContentInner, inputClass: s.customInputClass }}
                  />
                );

              case EditTypes.ORDER_MANAGEMENT:
                return (
                  <OrderManagement
                    returnToPrevStep={returnToPrevStep}
                    target={FormTarget.EDITING}
                    profile={profile}
                    onUpdateOrderManagement={onUpdateStrategySettings}
                    onClearRequestErrors={onClearRequestErrors}
                    onCancel={onEditClose}
                    errors={errors}
                    isStepsHidden
                    classes={{ contentWrapperClass: s.modalContentInner, inputClass: s.customInputClass }}
                  />
                );

              default:
                return (
                  <ProfilesSettings
                    target={FormTarget.EDITING}
                    profile={profile}
                    onSubmitProfiles={onSubmitProfiles}
                    onCancelProfileSetting={onEditClose}
                    onClearRequestErrors={onClearRequestErrors}
                    onSetError={onSetError}
                    errors={errors}
                    isStepsHidden
                    contentWrapperClass={s.modalContentInner}
                  />
                );
            }
          })()}
        </TemplateContext.Provider>
      </Modal>
    </div>
  );
};

export default Summary;
