import { call, put, takeLeading, takeLatest, ForkEffect, select } from 'redux-saga/effects';
import * as Shapes from 'interfaces/strategyProfilesShapes';
import { callbackResolver } from 'helpers/callbackHelpers';
import { strategyPairSelector, strategyRootSelector } from '../selectors';
import { strategyProfilesTypes } from '../types';
import * as apiHelpers from './apiHelpers/strategyProfilesApi';
import * as actions from '../actions/strategyProfilesActions';

function* createProfileWorker({ payload }: Shapes.CreateProfile) {
  try {
    const { data } = yield call(apiHelpers.createProfileHelper, payload.reqData);
    yield put(actions.setProfile(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('createProfileWorker', error);
  }
}

function* updateProfileWorker({ payload }: Shapes.UpdateProfile) {
  try {
    const { data } = yield call(apiHelpers.updateProfileHelper, payload.id, payload.reqData);
    yield put(actions.setProfile(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('createProfileWorker', error);
  }
}

function* getProfileWorker({ payload }: Shapes.GetProfile) {
  try {
    const { data } = yield call(apiHelpers.getProfileHelper, payload.id);
    if (payload.isTemplateRequest) {
      yield put(actions.setProfileTemplate(data));
    } else {
      yield put(actions.setProfile(data));
    }
    payload.onSuccess(data);
  } catch (error) {
    payload.onError(error);
    console.error('getProfileWorker', error);
  }
}

function* getStrategyProfilesWorker({ payload }: Shapes.GetStrategyProfiles) {
  try {
    const { data } = yield call(apiHelpers.getStrategyProfilesHelper, payload.reqParams);
    yield put(actions.putStrategyProfiles(data));
    callbackResolver(payload.onFinally);
  } catch (error) {
    payload.onError(error);
    console.error('getStrategyProfilesWorker', error);
  }
}

function* getSystemStatusWorker({ payload }: Shapes.GetSystemStatus) {
  try {
    const { data } = yield call(apiHelpers.getSystemStatusHelper);
    yield put(actions.putSystemStatus(data.value));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('getSystemStatusWorker', error);
  }
}

function* setSystemStatusWorker({ payload }: Shapes.SetSystemStatus) {
  try {
    yield call(apiHelpers.setSystemStatusHelper, payload.status);
    const { data } = yield call(apiHelpers.getSystemStatusHelper);
    yield put(actions.putSystemStatus(data.value));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('getSystemStatusWorker', error);
  }
}

function* updateStrategyDetailsWorker({ payload }: Shapes.UpdateStrategyDetails) {
  try {
    const { data } = yield call(apiHelpers.updateStrategyDetailsHelper, payload.id, payload.profileData);
    yield put(actions.setStrategySettings(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('updateStrategyDetailsWorker', error);
  }
}

function* getStrategySettingsWorker({ payload }: Shapes.GetStrategySettings) {
  try {
    const { data } = yield call(apiHelpers.getStrategySettingsHelper, payload.id);
    yield put(actions.setStrategySettings(data));
  } catch (error) {
    payload.onError(error);
    console.error('getStrategySettingsWorker', error);
  }
}

function* startStrategyWorker({ payload }: Shapes.StartStopStrategy) {
  try {
    const { data } = yield call(apiHelpers.startStrategyHelper, payload.id);
    yield put(actions.putUpdatedStrategyInList(data));
    const { profile } = yield select(strategyRootSelector);
    if (profile) yield put(actions.setProfile(data));
  } catch (error) {
    payload.onError(error);
    console.error('startStrategyWorker', error);
  }
}

function* stopStrategyWorker({ payload }: Shapes.StartStopStrategy) {
  try {
    const { data } = yield call(apiHelpers.stopStrategyHelper, payload.id);
    yield put(actions.putUpdatedStrategyInList(data));
    const { profile } = yield select(strategyRootSelector);
    if (profile) yield put(actions.setProfile(data));
  } catch (error) {
    payload.onError(error);
    console.error('stopStrategyWorker', error);
  }
}

function* updateStrategyVisibilityWorker({ payload }: Shapes.UpdateStrategyVisibility) {
  try {
    yield call(apiHelpers.updateStrategyProfileVisibilityHelper, payload.strategyId, payload.reqData);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    console.error('updateStrategyVisibilityWorker', error);
  }
}

function* getStrategyTickersWorker({ payload }: Shapes.GetStrategiesTickers) {
  try {
    const { data } = yield call(apiHelpers.getStrategyTickers);
    yield put(actions.setStrategiesTickers(data));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    payload?.onError(error);
    console.error('getStrategyTickersWorker', error);
  }
}

function* saveStrategyTickersWorker({ payload }: Shapes.SaveRemoveStrategyTickers) {
  try {
    const strategyTicker = yield select(strategyPairSelector(payload.id));
    yield call(apiHelpers.saveStrategyTickersHelper, payload.id, strategyTicker);
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('saveStrategyTickersWorker', error);
  }
}

function* deleteStrategyTickersWorker({ payload }: Shapes.SaveRemoveStrategyTickers) {
  try {
    yield call(apiHelpers.deleteStrategyTickersHelper, payload.id);
    yield put(actions.deleteStrategiesTickersSuccess(payload.id));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('deleteStrategyTickersWorker', error);
  }
}

function* addStrategyTickersWorker({ payload }: Shapes.AddRemoveStrategyTickers) {
  try {
    const { data } = yield call(apiHelpers.addStrategyTickersHelper, payload.values);
    yield put(actions.addStrategiesTickersSuccess(data));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    console.error('addStrategyTickersWorker', error);
  }
}

function* getProfileTemplatesWorker() {
  try {
    const { data } = yield call(apiHelpers.getProfilesHelper);
    yield put(actions.setProfileTemplates(data.profiles));
  } catch (error) {
    console.error('getProfileTemplatesWorker', error);
  }
}

export function* strategyProfilesWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLeading(strategyProfilesTypes.GET_SYSTEM_STATUS, getSystemStatusWorker);
  yield takeLeading(strategyProfilesTypes.SET_SYSTEM_STATUS, setSystemStatusWorker);
  yield takeLeading(strategyProfilesTypes.CREATE_PROFILE, createProfileWorker);
  yield takeLeading(strategyProfilesTypes.UPDATE_PROFILE, updateProfileWorker);
  yield takeLeading(strategyProfilesTypes.UPDATE_STRATEGY_DETAILS, updateStrategyDetailsWorker);
  yield takeLatest(strategyProfilesTypes.GET_STRATEGY_PROFILES, getStrategyProfilesWorker);
  yield takeLeading(strategyProfilesTypes.GET_PROFILE, getProfileWorker);
  yield takeLeading(strategyProfilesTypes.GET_STRATEGY_SETTINGS, getStrategySettingsWorker);
  yield takeLeading(strategyProfilesTypes.START_STRATEGY, startStrategyWorker);
  yield takeLeading(strategyProfilesTypes.STOP_STRATEGY, stopStrategyWorker);
  yield takeLeading(strategyProfilesTypes.UPDATE_STRATEGY_VISIBILITY, updateStrategyVisibilityWorker);
  yield takeLeading(strategyProfilesTypes.GET_STRATEGY_TICKERS, getStrategyTickersWorker);
  yield takeLeading(strategyProfilesTypes.SAVE_STRATEGY_TICKERS, saveStrategyTickersWorker);
  yield takeLeading(strategyProfilesTypes.DELETE_STRATEGY_TICKERS, deleteStrategyTickersWorker);
  yield takeLeading(strategyProfilesTypes.ADD_STRATEGY_TICKERS, addStrategyTickersWorker);
  yield takeLeading(strategyProfilesTypes.GET_PROFILE_TEMPLATES, getProfileTemplatesWorker);
}
