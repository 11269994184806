import React, { useCallback, useMemo } from 'react';
import s from './StrategyStatsTable.module.scss';
import {
  initialStrategyStatsValues,
  InitialStrategyStatsValuesType,
  STRATEGY_STAT_DATE,
  STRATEGY_STAT_FILTERS,
  StrategyStatsFilterIDs
} from 'constants/strategyStatsConstants';
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { ButtonColorTypes } from 'constants/randomConstants';
import { GetStrategyStatsQuery, Profiles } from 'interfaces/strategyStatsShapes';
import { customizeFilter } from 'helpers/signalsStatsHelpers';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 300
    }
  }
};

interface SignalsStatsFiltersProps {
  disabledFilter: boolean;
  filterParams: typeof initialStrategyStatsValues;
  reqParams: GetStrategyStatsQuery;
  profiles: Profiles[];
  setReqParams: (params: GetStrategyStatsQuery) => void;
  setFilterParams: (params: InitialStrategyStatsValuesType) => void;
  setDisabledFilter: (shouldDisable: boolean) => void;
}

const StrategyStatsFilters: React.FC<SignalsStatsFiltersProps> = ({
  filterParams,
  setReqParams,
  setFilterParams,
  disabledFilter,
  setDisabledFilter,
  reqParams,
  profiles
}) => {
  const filters = useMemo(() => {
    return customizeFilter(profiles, STRATEGY_STAT_FILTERS);
  }, [profiles]);
  const handleFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof filterParams;

    const updatedFilterParams = {
      ...filterParams,
      page: 1,
      [name]: event.target.value
    };
    setFilterParams(updatedFilterParams);
    setDisabledFilter(JSON.stringify(updatedFilterParams) === JSON.stringify(reqParams));
  };

  const handleIsChecked = (fieldName: string) => {
    return filterParams.profile_id__in === '' ? false : filterParams.profile_id__in.split(',').indexOf(fieldName) >= 0;
  };

  const onProfileChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value, name } = event.target;
    const isAll = (value as string[]).some((profile) => profile === '');
    const updatedFilterParams = {
      ...filterParams,
      page: 1,
      [name]: isAll ? '' : (value as string[]).join(',')
    };
    setFilterParams(updatedFilterParams);
    setDisabledFilter(JSON.stringify(updatedFilterParams) === JSON.stringify(reqParams));
  };
  const selectedMap = useMemo(() => {
    return Object.entries(filters[0].options).reduce((acc, [_, option]) => {
      return { ...acc, [option.value]: option.label };
    }, {});
  }, [filters]);

  const onRenderValues = useCallback(
    (selected: string[]) => {
      return selected.length > 0 ? selected.map((profile) => selectedMap[profile]).join(', ') : 'All';
    },
    [selectedMap]
  );

  return (
    <div className={s.filterWrapper}>
      {filters.map((filter) => {
        const selectValue = filterParams[filter.id] === '' ? [] : filterParams[filter.id].split(',');
        return (
          <FormControl key={filter.id} variant="outlined" className={s.filterFormControl}>
            <InputLabel shrink htmlFor={filter.id} className={s.filterLabel}>
              {filter.label}
            </InputLabel>
            <Select
              name={filter.id}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              displayEmpty
              value={selectValue}
              onChange={onProfileChange}
              renderValue={(selected) => onRenderValues(selected as string[])}
              MenuProps={MenuProps}
              multiple
            >
              <MenuItem value="">
                <Checkbox checked={filterParams.profile_id__in === ''} />
                <ListItemText>All</ListItemText>
              </MenuItem>
              {filter.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={handleIsChecked(option.value)} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      })}
      {STRATEGY_STAT_DATE.map((filter) => (
        <FormControl key={filter.id} variant="outlined" className={s.filterFormControl}>
          <InputLabel shrink htmlFor={StrategyStatsFilterIDs.AFTER_CREATING} className={s.filterLabel}>
            {filter.label}
          </InputLabel>
          <TextField
            onChange={handleFilterChange}
            value={filterParams[filter.value]}
            id="date"
            type="date"
            name={filter.fieldName}
          />
        </FormControl>
      ))}

      <Button
        fullWidth
        variant="outlined"
        size="medium"
        type="button"
        color={ButtonColorTypes.DEFAULT}
        classes={{ root: s.filterButton }}
        onClick={() => {
          setReqParams(filterParams);
          setDisabledFilter(true);
        }}
        disabled={disabledFilter}
      >
        Apply
      </Button>
    </div>
  );
};

export default StrategyStatsFilters;
