import React, { useMemo } from 'react';
import cn from 'classnames';
import PairedBtnBlock from 'components/buttons/pairedBtnBlock/PairedBtnBlock';
import { Form, Formik, FormikHelpers } from 'formik';
import CustomizedTextField from 'components/fields/customizedTextField/CustomizedTextField';
import OutlinedSelect from 'components/selects/outlinedSelect/OutlinedSelect';
import StrategyTitleBlock from '../strategyTitleBlock/StrategyTitleBlock';
import { getOptionsArray, useProfilesSettingsData } from 'helpers/strategyProfilesAddHelpers';
import {
  profileSettingsSchema,
  STRATEGY_STEPS_LABELS,
  StrategyProfilesFields,
  TOTAL_STEPS
} from 'constants/strategyProfilesAddConstants';
import { AddSPErrors, ProfilesSettingsValues, StrategyProfileSettingsShape } from 'interfaces/strategyProfilesShapes';
import s from './ProfilesSettings.module.scss';
import { FormTarget } from '../../../constants/strategyProfilesConstants';

interface ProfilesSettingsProps {
  target?: FormTarget;
  onSubmitProfiles: (values: ProfilesSettingsValues) => void;
  onClearRequestErrors: () => void;
  onSetError: (err, type: string) => void;
  onCancelProfileSetting: () => void;
  profile: StrategyProfileSettingsShape | null;
  errors: AddSPErrors;
  isStepsHidden?: boolean;
  contentWrapperClass?: string;
}

interface FormValues {
  template: string;
  title: string;
  mmProfile: string;
  tickers: string;
}

const ProfilesSettings = ({
  target,
  onSubmitProfiles,
  onClearRequestErrors,
  onCancelProfileSetting,
  onSetError,
  profile,
  errors: { profileErr, mmProfilesErr },
  isStepsHidden
}: ProfilesSettingsProps): JSX.Element => {
  const { mmProfiles, tickers, profiles } = useProfilesSettingsData(onSetError);

  const tradingPairsOptions = useMemo(() => getOptionsArray(tickers), [tickers]);
  const profilesOptions = useMemo(() => getOptionsArray(profiles), [profiles]);
  const mmProfileOptions = useMemo(
    () => mmProfiles.map(({ id, title }) => ({ value: id, label: title })),
    [mmProfiles]
  );

  return (
    <div className={s.wrapper}>
      <StrategyTitleBlock
        step={STRATEGY_STEPS_LABELS.PROFILE_SETTINGS_STEP}
        totalSteps={TOTAL_STEPS}
        title="Strategy profile settings"
        isStepsHidden={isStepsHidden}
      />
      <Formik
        enableReinitialize
        initialValues={{
          [StrategyProfilesFields.TEMPLATE]: profile?.template ?? '',
          [StrategyProfilesFields.TITLE]: profile?.title ?? '',
          [StrategyProfilesFields.MM_PROFILE]: profile?.mm_profile?.id.toString() ?? '',
          [StrategyProfilesFields.TICKERS]: profile?.tickers?.toString() ?? ''
        }}
        validationSchema={profileSettingsSchema}
        onSubmit={(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
          onSubmitProfiles(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          const isError = (value: string) => touched[value] && !!errors[value];
          const getErrorMsg = (value: string) => touched[value] && errors[value];
          const isValid = (value: string) => !!touched[value] && !errors[value];
          return (
            <Form className={s.form}>
              <OutlinedSelect
                id={StrategyProfilesFields.TEMPLATE}
                options={profilesOptions}
                value={values[StrategyProfilesFields.TEMPLATE]}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Pick template"
                isError={isError(StrategyProfilesFields.TEMPLATE)}
                errorMsg={getErrorMsg(StrategyProfilesFields.TEMPLATE)}
                formControlClass={s.selectRoot}
                onClearReqError={onClearRequestErrors}
              />
              <CustomizedTextField
                id={StrategyProfilesFields.TITLE}
                label="Strategy profile name"
                placeholder="Strategy profile name"
                value={values.title}
                isError={isError(StrategyProfilesFields.TITLE) || !!profileErr}
                isValid={isValid(StrategyProfilesFields.TITLE) && !profileErr}
                helperText={getErrorMsg(StrategyProfilesFields.TITLE)}
                onBlur={handleBlur}
                onChange={handleChange}
                onClearRequestError={onClearRequestErrors}
                inputClass={s.input}
              />
              <OutlinedSelect
                id={StrategyProfilesFields.MM_PROFILE}
                options={mmProfileOptions}
                value={values.mmProfile}
                onChange={handleChange}
                onBlur={handleBlur}
                label="MM Profile"
                isError={isError(StrategyProfilesFields.MM_PROFILE) || !!mmProfilesErr}
                errorMsg={getErrorMsg(StrategyProfilesFields.MM_PROFILE)}
                formControlClass={s.selectRoot}
                onClearReqError={onClearRequestErrors}
              />
              <OutlinedSelect
                id={StrategyProfilesFields.TICKERS}
                options={tradingPairsOptions}
                value={values[StrategyProfilesFields.TICKERS]}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Tickers lists"
                isError={isError(StrategyProfilesFields.TICKERS)}
                errorMsg={getErrorMsg(StrategyProfilesFields.TICKERS)}
                formControlClass={s.selectRoot}
                onClearReqError={onClearRequestErrors}
              />
              <PairedBtnBlock
                onCancel={onCancelProfileSetting}
                onSubmit={handleSubmit}
                labels={{ cancelLabel: 'Cancel', submitLabel: target === FormTarget.EDITING ? 'Save' : 'Next' }}
                disableSubmit={isSubmitting}
                disableCancel={isSubmitting}
              />
              {profileErr && <span className={cn(s.error, 'errorText')}>{profileErr}</span>}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProfilesSettings;
