import { signalsOrdersTypes } from '../types';
import * as Shapes from 'interfaces/signalsOrdersShapes';
import * as AdminShapes from 'interfaces/administrationShapes';
import { ReqValues } from '../../helpers/signalOrdersHelpers';
import { LostPositionLoseDTO } from 'interfaces/signalsOrdersShapes';

export const getSignals = (
  queryObj: Shapes.GetSignalsQuery,
  onFinally: () => void,
  onError: (err) => void
): Shapes.GetSignalsShape => ({
  type: signalsOrdersTypes.GET_SIGNALS,
  payload: { queryObj, onFinally, onError }
});

export const putSignals = (signals: Array<Shapes.SignalShape>): Shapes.PutSignalsShape => ({
  type: signalsOrdersTypes.PUT_SIGNALS,
  payload: signals
});

export const cancelSignal = (id: number, onSuccess: () => void, onError: (err) => void): Shapes.CancelSignal => ({
  type: signalsOrdersTypes.CANCEL_SIGNAL,
  payload: { id, onSuccess, onError }
});

export const closeSignalMarket = (id: number, onSuccess: () => void, onError: (err) => void): Shapes.CancelSignal => ({
  type: signalsOrdersTypes.CLOSE_SIGNAL_MARKET,
  payload: { id, onSuccess, onError }
});

export const closeSignalMarketBulk = (
  ids: string[],
  onSuccess: () => void,
  onError: (err) => void
): Shapes.CancelSignalBulk => ({
  type: signalsOrdersTypes.CLOSE_SIGNAL_MARKET_BULK,
  payload: { ids, onSuccess, onError }
});

export const closeSignalLostMarketBulk = (
  lostPositions: LostPositionLoseDTO[],
  onSuccess: () => void,
  onError: (err) => void
): Shapes.CancelSignalLostBulk => ({
  type: signalsOrdersTypes.CLOSE_SIGNAL_MARKET_LOST_BULK,
  payload: { lostPositions, onSuccess, onError }
});

export const closeSignalLimit = (
  id: number,
  coefficient: Shapes.LimitCoefficient,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.CancelSignalLimit => ({
  type: signalsOrdersTypes.CLOSE_SIGNAL_LIMIT,
  payload: { id, coefficient, onSuccess, onError }
});

export const getOrders = (
  queryObj: Shapes.GetOrdersQuery,
  onFinally: () => void,
  onError: (err) => void
): Shapes.GetOrdersShape => ({
  type: signalsOrdersTypes.GET_ORDERS,
  payload: { queryObj, onFinally, onError }
});

export const putOrders = (orders: Array<Shapes.OrderShape>): Shapes.PutOrdersShape => ({
  type: signalsOrdersTypes.PUT_ORDERS,
  payload: orders
});

export const cancelOrder = (id: number, onSuccess: () => void, onError: (err) => void): Shapes.CancelOrder => ({
  type: signalsOrdersTypes.CANCEL_ORDER,
  payload: { id, onSuccess, onError }
});

export const exportSignals = (onSuccess: () => void, onError: (err) => void): Shapes.ExportSignals => ({
  type: signalsOrdersTypes.EXPORT_SIGNALS,
  payload: { onSuccess, onError }
});

export const getFilters = (onSuccess: () => void, onError: (err) => void): Shapes.GetSignalFilters => ({
  type: signalsOrdersTypes.GET_SIGNAL_FILTERS,
  payload: { onSuccess, onError }
});

export const putFilters = (filters: Shapes.GetSignalsFilters): Shapes.PutSignalFilters => ({
  type: signalsOrdersTypes.PUT_SIGNAL_FILTERS,
  payload: filters
});

export const getAdminInfo = (): Shapes.GetAdminShape => ({
  type: signalsOrdersTypes.GET_ADMIN
});

export const updateAdminList = (id: number, reqData: AdminShapes.DepositFromValue): Shapes.UpdateAdminShape => ({
  type: signalsOrdersTypes.UPDATE_ADMIN_LIST,
  payload: { id, reqData }
});

export const putAdminList = ({ reqData, id }: Shapes.UpdateAdminPropsShape): Shapes.UpdateAdminSuccessShape => ({
  type: signalsOrdersTypes.PUT_ADMIN_LIST,
  payload: { id, reqData }
});

export const putAdminInfo = (payload: Shapes.AdminShape[]): Shapes.PutAndinShape => ({
  type: signalsOrdersTypes.PUT_ADMIN,
  payload
});

export const getAdminTableData = (
  reqParams: AdminShapes.AdministrationFilters,
  onFinally: () => void,
  onError: (err) => void
): Shapes.GetAdminTableShape => ({
  type: signalsOrdersTypes.GET_ADMIN_TABLE,
  payload: { reqParams, onFinally, onError }
});

export const getAdminLostData = (onFinally: () => void): Shapes.GetAdminLostTableShape => ({
  type: signalsOrdersTypes.GET_ADMIN_LOST_POSITIONS,
  payload: { onFinally }
});

export const putAdminLostData = (payload: Shapes.AdminTableRow[]): Shapes.PutAdminLostShape => ({
  type: signalsOrdersTypes.PUT_ADMIN_LOST_POSITIONS,
  payload
});

export const putAdminTableData = (payload: Shapes.AdminTableData): Shapes.PutAdminTableShape => ({
  type: signalsOrdersTypes.PUT_ADMIN_TABLE,
  payload
});

export const startRecoveryKExit = (id: number, onSuccess: () => void): Shapes.StartRecoveryExitShape => ({
  type: signalsOrdersTypes.START_RECOVERY_K_EXIT,
  payload: { id, onSuccess }
});

export const reducePosition = (id: number, percent: number, onSuccess: () => void): Shapes.ReducePositionShape => ({
  type: signalsOrdersTypes.REDUCE_POSITION,
  payload: { id, percent, onSuccess }
});

export const reducePositionBulk = (
  ids: string[],
  percent: number,
  onSuccess: () => void
): Shapes.ReducePositionBulkShape => ({
  type: signalsOrdersTypes.REDUCE_POSITION_BULK,
  payload: { ids, percent, onSuccess }
});

export const getRiskControl = (
  reqParams: { show_hide: boolean },
  onFinally: () => void,
  onError: (err) => void
): Shapes.GetRiskControl => ({
  type: signalsOrdersTypes.GET_RISK_CONTROL,
  payload: { reqParams, onFinally, onError }
});

export const putRiskControl = (payload: Shapes.RiskControlShape[]): Shapes.PutRiskControl => ({
  type: signalsOrdersTypes.PUT_RISK_CONTROL,
  payload
});

export const updateRiskControl = (id: number, onSuccess: () => void): Shapes.UpdateRiskControl => ({
  type: signalsOrdersTypes.UPDATE_RISK_CONTROL,
  payload: { id, onSuccess }
});

export const setRiskProperty = (id: number, value: unknown, property: string): Shapes.SetRiskProperty => ({
  type: signalsOrdersTypes.SET_RISK_PROPERTY,
  payload: { id, value, property }
});

export const createRiskControl = (reqParams: ReqValues, onFinally: () => void): Shapes.CreateRiskProperty => ({
  type: signalsOrdersTypes.CREATE_RISK_CONTROL,
  payload: { reqParams, onFinally }
});

export const setRiskControl = (payload: Shapes.RiskControlShape): Shapes.SetRiskControl => ({
  type: signalsOrdersTypes.SET_RISK_CONTROL,
  payload
});
