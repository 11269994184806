import { BinanceAccountShape } from 'interfaces/systemSettingsShapes';

export const binanceAccountsTypes = {
  CREATE_INIT: 'CREATE_INIT',
  CLOSE_MODAL: 'CLOSE_MODAL',
  STOP_LOADING: 'STOP_LOADING',
  EDIT_INIT: 'EDIT_INIT',
  DELETE_INIT: 'DELETE_INIT',
  DELETE_ERROR: 'DELETE_ERROR'
};

export const binanceAccountsInitialState = {
  isLoading: true,
  isOpen: false,
  isEdit: false,
  isDelete: false,
  deleteError: null,
  currentAccount: null
};

interface BinanceAccountsReducer {
  isLoading: boolean;
  isOpen: boolean;
  isEdit: boolean;
  isDelete: boolean;
  deleteError: null | string;
  currentAccount: null | BinanceAccountShape;
}

export const binanceAccountsReducer = (
  state: BinanceAccountsReducer,
  { type, payload }: { type: string; payload? }
): BinanceAccountsReducer => {
  switch (type) {
    case binanceAccountsTypes.CREATE_INIT:
      return { ...state, isOpen: true, isEdit: false, isDelete: false };
    case binanceAccountsTypes.CLOSE_MODAL:
      return { ...state, isOpen: false, currentAccount: null, deleteError: null };
    case binanceAccountsTypes.STOP_LOADING:
      return { ...state, isLoading: false };
    case binanceAccountsTypes.EDIT_INIT:
      return { ...state, isOpen: true, isEdit: true, isDelete: false, currentAccount: payload };
    case binanceAccountsTypes.DELETE_INIT:
      return { ...state, isOpen: true, isEdit: false, isDelete: true, currentAccount: payload };
    case binanceAccountsTypes.DELETE_ERROR:
      return { ...state, deleteError: payload };
    default:
      return state;
  }
};
