import { systemSettingsTypes } from '../types';
import * as Shapes from 'interfaces/systemSettingsShapes';

export const getBinanceAccounts = (onFinally?: () => void, onError?: (err) => void): Shapes.GetBinanceAccounts => ({
  type: systemSettingsTypes.GET_BINANCE_ACCOUNTS,
  payload: { onFinally, onError }
});

export const putBinanceAccounts = (accountsList: Array<Shapes.BinanceAccountShape>): Shapes.PutBinanceAccounts => ({
  type: systemSettingsTypes.PUT_BINANCE_ACCOUNTS,
  payload: accountsList
});

export const addBinanceAccountRequest = (
  reqData: Shapes.BinanceAccountFormFields,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.AddBinanceAccShape => ({
  type: systemSettingsTypes.ADD_BINANCE_ACCOUNT_REQUEST,
  payload: { reqData, onSuccess, onError }
});

export const editBinanceAccountRequest = (
  reqData: Shapes.BinanceAccountFormFields,
  accountId: number,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.EditBinanceAccShape => ({
  type: systemSettingsTypes.EDIT_BINANCE_ACCOUNT_REQUEST,
  payload: { reqData, accountId, onSuccess, onError }
});

export const deleteBinanceAccountRequest = (
  accountId: number,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.DeleteBinanceAccShape => ({
  type: systemSettingsTypes.DELETE_BINANCE_ACCOUNT_REQUEST,
  payload: { accountId, onSuccess, onError }
});

export const getTGSettings = (onFinally?: () => void, onError?: (err) => void): Shapes.GetBinanceAccounts => ({
  type: systemSettingsTypes.GET_TG_SETTINGS,
  payload: { onFinally, onError }
});

export const putTGSettings = (tg_settings: Shapes.TG_NotificationValues): Shapes.PutTGSettings => ({
  type: systemSettingsTypes.PUT_TG_SETTINGS,
  payload: tg_settings
});

export const createTGSettings = (
  reqData: Shapes.TG_NotificationValues,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.CreateTGSettings => ({
  type: systemSettingsTypes.CREATE_TG_SETTINGS_REQUEST,
  payload: { reqData, onSuccess, onError }
});

export const editTGSettings = (
  reqData: Shapes.TG_NotificationValues,
  onSuccess: () => void,
  onError: (err) => void
): Shapes.EditTGSettings => ({
  type: systemSettingsTypes.EDIT_TG_SETTINGS_REQUEST,
  payload: { reqData, onSuccess, onError }
});

export const getTradingPairs = (onSuccess?: () => void, onError?: (err) => void): Shapes.GetTradingPairs => ({
  type: systemSettingsTypes.GET_TRADING_PAIRS,
  payload: { onSuccess, onError }
});

export const syncTradingPairs = (onSuccess: () => void, onError: (err) => void): Shapes.GetTradingPairs => ({
  type: systemSettingsTypes.SYNC_TRADING_PAIRS,
  payload: { onSuccess, onError }
});

export const putTradingPairs = (tradingPairs: Array<Shapes.TradingPairShape>): Shapes.PutTradingPairs => ({
  type: systemSettingsTypes.PUT_TRADING_PAIRS,
  payload: tradingPairs
});

export const changeTradingPair = (
  reqData: { ticker: string; is_active: boolean },
  pairId: number,
  onError: (err) => void
): Shapes.ChangeTradingPair => ({
  type: systemSettingsTypes.CHANGE_TRADING_PAIR,
  payload: { reqData, pairId, onError }
});

export const changeTradingPairSuccess = (tradingPair: Shapes.TradingPairShape): Shapes.ChangeTradingPairSuccess => ({
  type: systemSettingsTypes.CHANGE_TRADING_PAIR_SUCCESS,
  payload: tradingPair
});
