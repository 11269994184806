import { format, parseISO } from 'date-fns';
import { ErrorShape } from 'interfaces/reduxRandomShapes';
import { DRAWER_WIDTH, DRAWER_WIDTH_CLOSED, DEFAULT_DATE_FORMAT } from 'constants/randomConstants';

export const getErrorMessageHelper = ({ response: { data } }: ErrorShape): string => {
  if (typeof data === 'string') return data;
  return Object.keys(data)?.reduce((acc, key) => `${acc} ${data[key]}`, ``);
};

export const getErrorCutMessageHelper = (error: string): string => {
  return error.length > 350 ? 'Some error occurred, please report it to development team' : error;
};

export const getModalContentIndent = (isAsideOpen: boolean): string => {
  return isAsideOpen ? (DRAWER_WIDTH / 2).toFixed() : (DRAWER_WIDTH_CLOSED / 2).toFixed();
};

export const formatISODate = (isoDate: string, dateFormat?: string): string => {
  if (!isoDate) return '-';
  return format(parseISO(isoDate), dateFormat ?? DEFAULT_DATE_FORMAT);
};

export const firstLetterUppercase = (value: string | number): string => {
  if (typeof value === 'number') return value.toString();
  return value.charAt(0).toLocaleUpperCase() + value.slice(1).toLowerCase();
};

export const createRandomId = (): string => Math.random().toString(16).slice(2);
